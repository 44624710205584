import React from "react";
import "./landingslider.scss";

interface LandingSliderProps {
  children: any;
}

export const LandingSlider = ({ children }: LandingSliderProps) => {
  const [state, setState] = React.useState({
    index: 0,
    toggle: false,
  });
  const handleSlide = (ind: any) => {
    setState((preS) => ({
      ...preS,
      index: ind,
      toggle: !preS,
    }));
  };
  return (
    <div className="slider">
      {children[state.index]}

      <div className="button-group">
        {Array(React.Children.count(children))
          .fill(0)
          .map((blt, ind) => {
            return (
              <div
                className={`bullet ${state.index === ind ? "filled" : ""}`}
                onClick={() => handleSlide(ind)}
                key={ind}
              ></div>
            );
          })}
      </div>
    </div>
  );
};
