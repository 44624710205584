import { useNavigate } from "react-router-dom";
import { Typography } from "../Typography/Typography";
import "./popup.scss";

export const Popup1 = () => {
  const navigate = useNavigate();

  return (
    <div className="popup popup-1">
      <Typography type="xl" text="Unlimited" weight="bold" />
      <div className="group">
        <Typography type="h3" text="$120 /" weight="bold" />
        <Typography type="h3" text="Month" />
      </div>
      <ul>
        <li>
          <Typography type="p3" text="Unlimited Access to Projects" />
        </li>
        <li>
          <Typography type="p3" text="Unlimited Number of Offers" />
        </li>
      </ul>
      <button onClick={() => navigate("/subscribe/payment")}>
        <Typography type="p1" text="Subscribe Now!" />
      </button>
    </div>
  );
};
