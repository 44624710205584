import "./account.scss";

interface AccountDropdownProps {
  hide?: boolean;
  children: any;
}
export const AccountDropdown = ({
  hide = false,
  children,
}: AccountDropdownProps) => {
  return (
    <ul className={`account-dropdown-list ${hide ? "hide" : ""}`}>
      {children}
    </ul>
  );
};
