import React from "react";
import { Button } from "components";
import { socialRegister, socialLogin } from "state/actions";
import { IResolveParams, LoginSocialFacebook } from "reactjs-social-login";
import { FacebookLoginButton } from "react-social-login-buttons";
import { useAppDispatch } from "state/reduxHooks";

export const FacebookButton = ({ action }: { action: string }) => {
  const dispatch = useAppDispatch();

  const responseFacebook = (response: { accessToken: any }) => {
    if (action === "login") {
      if (response?.accessToken) {
        dispatch(
          socialLogin({ token: response?.accessToken, type: "facebook" })
        );
      }
    } else if (action === "register") {
      if (response?.accessToken) {
        dispatch(
          socialRegister({
            token: response?.accessToken,
            type: "facebook",
            user_type: window.location.href.includes("ent-signup")
              ? "customer"
              : "developer",
          })
        );
      }
    }
  };

  return (
    <LoginSocialFacebook
      appId={process.env.REACT_APP_FB_APP_ID || ""}
      fieldsProfile={
        "id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender"
      }
      // onLoginStart={onLoginStart}
      // onLogoutSuccess={onLogoutSuccess}
      // redirect_uri={REDIRECT_URI}
      onResolve={({ provider, data }: IResolveParams) => {
        responseFacebook({ accessToken: data?.access_token });
      }}
      onReject={(err) => {
        // console.log(err);
      }}
    >
      <FacebookLoginButton />
    </LoginSocialFacebook>
  );
};

// return (
//   <FacebookLogin
//     appId="273069611644935"
//     autoLoad={false}
//     fields="name,email,picture"
//     scope="public_profile,email,user_friends"
//     callback={responseFacebook}
//     icon="fa-facebook"
//     render={(renderProps) => (
//       <Button
//         type="initial"
//         label="Facebook"
//         leftIcon="facebook"
//         size="3"
//         onClick={renderProps.onClick}
//       />
//     )}
//   />
// );
