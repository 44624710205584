import { motion } from "framer-motion";
import "./styles.scss";
import spinner from "../../images/spinner.png";

function Spinner() {
  return (
    <div id="container">
      <div id="html-spinner"></div>
      <svg
        id="svg-spinner"
        xmlns="http://www.w3.org/2000/svg"
        width="64"
        height="64"
        viewBox="0 0 48 48"
      >
        <circle cx="24" cy="5" r="5" fill="#000" />
        <circle cx="12.19" cy="7.86" r="4.6" fill="#151413" />
        <circle cx="5.02" cy="17.68" r="4.2" fill="#2C2827" />
        <circle cx="5.02" cy="30.32" r="3.8" fill="#564E4D" />
        <circle cx="12.19" cy="40.14" r="3.4" fill="#827674" />
        <circle cx="24" cy="44" r="3" fill="#A69996" />
        <circle cx="35.81" cy="40.14" r="2.6" fill="#C3B6B3" />
        <circle cx="42.98" cy="30.32" r="2.2" fill="#D8CCCA" />
      </svg>
    </div>
  );
}

export default Spinner;
