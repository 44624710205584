import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { isInteger } from "lodash";
import {
  getProjects,
  getProjectById,
  getOffers,
  getUserInfo,
  loadMoreProjects,
} from "state/actions";

import {
  ProjectCard,
  ProjectsContainer,
  LinkedTab,
  Button,
  Typography,
} from "components";

export const Projects = () => {
  const navigate = useNavigate();

  const userState = useSelector((state: any) => state.user);
  const { user } = userState;
  const projectsState = useSelector((state: any) => state.projects);
  const offersState = useSelector((state: any) => state.offers);
  const { offers } = offersState;
  const { projects, isFetching } = projectsState;
  const dispatch = useDispatch();
  const doProjects = getProjects as any;
  const doProjectById = getProjectById as any;
  const doOffers = getOffers as any;
  const doLoadMoreProjects = loadMoreProjects as any;
  const doUserInfo = getUserInfo as any;
  const NUMBER_OF_PROJECTS_TO_LOAD = 10;

  const addMore = () => {
    const startingFromIndex =
      Math.floor(projects.length / NUMBER_OF_PROJECTS_TO_LOAD) *
        NUMBER_OF_PROJECTS_TO_LOAD -
      1;
    const fromProjectId = projects[startingFromIndex].id;

    dispatch(doLoadMoreProjects(fromProjectId));
  };
  const isOffered = offers.map((offer: any) => offer.project_id);

  const seeProject = (project_id: any) => {
    localStorage.setItem("projectById", project_id);
    dispatch(doProjectById(project_id));
    localStorage.getItem("token") && localStorage.getItem("userId")
      ? navigate(
          `/developers/${localStorage.getItem(
            "userId"
          )}/feed/${project_id}/projects`
        )
      : navigate("/ent-signup");
  };

  useEffect(() => {
    dispatch(doUserInfo());
    dispatch(doProjects());
    dispatch(doOffers());
  }, []);

  return (
    <>
      <LinkedTab tabs={[{ name: "Feed" }]} />
      <ProjectsContainer>
        {projects?.slice(0, 3).map((project: any, index: any) => {
          return (
            <ProjectCard
              title={project.application_name}
              description={project.description}
              isBlurred={false}
              onClick={() => seeProject(project.id)}
              projectId={project.id}
              isApplied={isOffered.includes(project.id)}
              key={index}
            />
          );
        })}
        {user?.subscription?.plan?.name.toLowerCase().includes("free")
          ? Array(6)
              .fill(0)
              .map((el, ind) => (
                <ProjectCard
                  title="Dating App"
                  description="Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus."
                  isBlurred={user?.subscription?.plan?.name
                    .toLowerCase()
                    .includes("free")}
                  onClick={seeProject}
                  projectId={`blurreds-${ind}`}
                  key={ind}
                />
              ))
          : projects
              ?.slice(
                3,
                projects.length <= 10 ? projects.length - 1 : projects.length
              )
              .map((project: any, ind: number) => {
                return (
                  <ProjectCard
                    title={project.application_name}
                    description={project.description}
                    isBlurred={false}
                    onClick={() => seeProject(project.id)}
                    projectId={project.id}
                    isApplied={isOffered.includes(project.id)}
                    key={ind}
                  />
                );
              })}
      </ProjectsContainer>
      <div className="load-btn">
        {isInteger(projects.length / NUMBER_OF_PROJECTS_TO_LOAD) ? (
          <Button
            onClick={projects.length === 0 ? () => null : addMore}
            label={`${
              projects.length === 0 ? "Loading projects..." : "Load more..."
            }`}
          />
        ) : (
          <Typography
            type="p3"
            text={`${
              projects.length === 0
                ? "There are no projects!"
                : "You've seen all the projects!"
            }`}
          />
        )}
      </div>
    </>
  );
};
