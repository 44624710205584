import "./designslider.scss";
import { Typography, Button } from "..";

interface DesignSlideProp {
  title: string;
  src: string;
  description: string;
  handleAddDesign: Function;
  isCheckedDesign: boolean;
}

export const DesignSlide = ({
  src,
  title,
  description,
  handleAddDesign,
  isCheckedDesign,
}: DesignSlideProp) => {
  return (
    <div className="slide">
      <Typography type="h2" text={title} weight="bold" />
      <div className="description">
        <Typography type="p4" text={description} weight="normal" />
      </div>
      <div
        className={
          isCheckedDesign
            ? `checkedDesign designImageWrapper`
            : `designImageWrapper`
        }
      >
        <img
          src={src}
          alt={`${title} design`}
          className="designImage"
          onClick={() => handleAddDesign(title, isCheckedDesign)}
          loading="lazy"
        />
      </div>
      <div
        className={
          isCheckedDesign
            ? `checkedDesignButton selectDesignButton`
            : `selectDesignButton`
        }
      >
        <Button
          onClick={() => handleAddDesign(title, isCheckedDesign)}
          label="Select"
          type="primary"
          isDisabled={isCheckedDesign}
        />
      </div>
    </div>
  );
};
