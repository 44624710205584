import axios from "axios";
import API_PATHS from "./apiPaths";

export const getUserById = async () =>
  (await axios.get(API_PATHS.user.get_user_info)).data;

export const getProject = async (id: string) =>
  (await axios.get(API_PATHS.project.get_project(id))).data;

export const getQuestions = async (id: string | undefined) =>
  (await axios.get(API_PATHS.question.get_questions(id))).data;

export const askQuestion = async (featureID: string, question: string) =>
  (
    await axios.post(API_PATHS.question.post_create(featureID), {
      question: question,
    })
  ).data;

export const replyToQuestion = async (questionID: string, reply: string) =>
  (
    await axios.post(API_PATHS.question.post_reply(questionID), {
      reply: reply,
    })
  ).data;

export const getOffer = async (id?: string) =>
  (await axios.get(API_PATHS.offers.get_view_offer(id))).data;

export const getOffers = async () =>
  (await axios.get(API_PATHS.offers.get_offers)).data;

export const acceptOffer = async (id: string) =>
  (await axios.post(API_PATHS.offers.post_accept_offer(id))).data;

export const getContracts = async () =>
  (await axios.get(API_PATHS.user.get_contracts)).data;

export const getAppDesigns = async () =>
  (await axios.get(API_PATHS.app_design.list_designs)).data;