import moment from "moment";
import { Link } from "react-router-dom";
import { Typography, Button } from "components";
import congrats from "images/congrats.png";
import "../Proposals/proposal.scss";

import { acceptOffer } from "services";

export const ProposalDetails = ({ proposalsPage, item }: any) => {
  const startDate = moment(item.start_date);
  const endDate = moment(item.estimated_end_date);
  const duration = endDate.to(startDate, true);

  return (
    <Link
      key={item.id}
      to={`/entrepreneur/${localStorage.getItem("userId")}/proposals/${
        item.id
      }`}
    >
      <div className="proposal">
        <div className="img-container">
          {item.user.image ? (
            <img src={item.user.image} width="68" height="68" />
          ) : (
            <img
              src={"/images/account.png"}
              width="68"
              height="68"
              className="congrats"
            />
          )}
        </div>

        <div className="developer">
          <Typography type="p2" text={item.user.name} />
          {/* <Typography type="p3" text={item.developer.location} /> */}
        </div>
        <div className="buttons">
          {proposalsPage && (
            <Button type="outlined" label={`Questions`} size="2" />
          )}
          {item.status === "GIVEN" ? (
            <Button
              type="primary"
              label="Hire"
              size="2"
              onClick={() => {
                acceptOffer(item.id);
              }}
            />
          ) : (
            <img src={congrats} width="100" height="100" />
          )}
        </div>
        <div className="details">
          <Typography
            type="p1"
            text={
              JSON.stringify(item.hourly_rate * item.total_working_hours) + "$"
            }
          />
          <div className="line" />
          <Typography type="p1" text={duration} />
        </div>
        <p className="cover-letter">{proposalsPage ? item.cover_letter : ""}</p>
      </div>
    </Link>
  );
};
