import React from "react";
import { Typography } from "..";
import { Icon } from "../Icon/Icon"; // Assuming Icon is used somewhere in the code
import "./select.scss";

interface SelectOption {
  value: string;
  label: string;
}

interface SelectProps {
  label?: string;
  value?: string;
  name?: string;
  options: SelectOption[]; // New options prop
  onChange?: (event: React.FormEvent<HTMLSelectElement>) => void;
}

export const Select = ({
  label,
  value,
  name,
  options,
  onChange,
  ...rest
}: SelectProps) => {
  return (
    <label className="generic-label">
      {label ? <Typography type="p4" text={label} /> : null}

      <select
        onChange={onChange}
        value={value}
        className="generic-select"
        name={name}
        {...rest}
      >
        {options.map((option) => (
          <option className="generic-option" value={option.value} key={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </label>
  );
};
