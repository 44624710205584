import { createSlice } from "@reduxjs/toolkit";
import { getOffers, saveOffer } from "../actions";

interface OffersState {
  status: string;
  message: string;
  success: boolean | null;
  offers: [];
  savedOffer: {};
  errors: string[];
  isError: boolean;
  isSuccess: boolean;
  isFetching: boolean;
  isOfferSavedSuccess: string | boolean;
  isOfferSavedSuccessMsg: boolean;
  isOfferSavedError: boolean;
  isOfferSavedFetching: boolean;
}

const initialState: OffersState = {
  status: "",
  message: "",
  success: null,
  offers: [],
  savedOffer: {},
  errors: [],
  isError: false,
  isSuccess: false,
  isFetching: false,
  isOfferSavedSuccess: "",
  isOfferSavedSuccessMsg: false,
  isOfferSavedError: false,
  isOfferSavedFetching: false,
};

export const offersSlice = createSlice({
  name: "offers",
  initialState,
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.isOfferSavedSuccess = "";
      state.errors = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOffers.fulfilled, (state, action) => {
        state.message = action.payload.message;
        state.status = action.payload.status;
        state.offers = action.payload.offers;
        state.isSuccess = true;
      })
      .addCase(getOffers.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getOffers.rejected, (state) => {
        state.isError = true;
      })
      .addCase(saveOffer.fulfilled, (state, action) => {
        const { message, offer, status } = action.payload;
        state.isOfferSavedSuccessMsg = message;
        state.savedOffer = offer;
        state.status = status;
        state.isOfferSavedSuccess = true;
        state.isOfferSavedError = false;
      })
      .addCase(saveOffer.pending, (state) => {
        state.isOfferSavedFetching = true;
      })
      .addCase(saveOffer.rejected, (state, action) => {
        const { errors, message } = action.payload as { errors: any, message: string };
        state.errors = errors;
        state.message = message;
        state.isOfferSavedError = true;
        state.isOfferSavedSuccess = false;
      });
  },
});

export const { clearState } = offersSlice.actions;

export default offersSlice.reducer;

// import { createSlice } from "@reduxjs/toolkit";
// import { getOffers, saveOffer } from "../actions";

// const initialState = {
//   status: "",
//   message: "",
//   success: null,
//   offers: [],
//   savedOffer: {},
//   errors: [],
//   isError: false,
//   isSuccess: false,
//   isFetching: false,
//   isOfferSavedSuccess: "",
//   isOfferSavedSuccessMsg: false,
//   isOfferSavedError: false,
//   isOfferSavedFetching: false,
// };

// export const offersSlice = createSlice({
//   name: "offers",
//   initialState,
//   reducers: {
//     clearState: (state) => {
//       state.isError = false;
//       state.isSuccess = false;
//       state.isFetching = false;
//       state.isOfferSavedSuccess = false;
//       state.errors = [];
//       return state;
//     },
//   },
//   extraReducers: {
//     [getOffers.fulfilled]: (state, action) => {
//       state.message = action.payload.message;
//       state.status = action.payload.status;
//       state.offers = action.payload.offers;
//       state.isSuccess = true;
//     },
//     [getOffers.pending]: (state) => {
//       state.isFetching = true;
//     },
//     [getOffers.rejected]: (state) => {
//       state.isError = true;
//     },
//     [saveOffer.fulfilled]: (state, { payload }) => {
//       state.isOfferSavedSuccessMsg = payload.message;
//       state.savedOffer = payload.offer;
//       state.status = payload.status;
//       state.isOfferSavedSuccess = true;
//       state.isOfferSavedError = false;
//     },
//     [saveOffer.pending]: (state, { payload }) => {
//       state.isOfferSavedFetching = true;
//     },
//     [saveOffer.rejected]: (state, { payload }) => {
//       state.errors = payload.errors;
//       state.message = payload.message;
//       state.isOfferSavedError = true;
//       state.isOfferSavedSuccess = false;
//     },
//   },
// });

// export const { clearState } = offersSlice.actions;
