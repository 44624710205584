import React from "react";
import { Icon } from "../index";
import "./button.scss";

interface ButtonProps {
  type?: "outlined" | "primary" | "initial" | "secondary";
  size?: "1" | "2" | "3" | "4";
  leftIcon?: string;
  rightIcon?: string;
  label: string;
  submit?: boolean;
  isDisabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const Button = ({
  type = "outlined",
  leftIcon,
  rightIcon,
  size = "2",
  label,
  submit,
  onClick,
  isDisabled,
}: ButtonProps) => {
  return (
    <button
      type={submit ? "submit" : "button"}
      className={`generic-button ${type}-button button-size-${size} ${
        isDisabled ? "disableButton" : null
      }`}
      onClick={onClick}
      disabled={isDisabled ? true : false}
    >
      {leftIcon ? <Icon icon={`${leftIcon}`} /> : null}
      <span>{label}</span>
      {rightIcon ? <Icon icon={`${rightIcon}`} /> : null}
    </button>
  );
};
