import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button,
  Header,
  Icon,
  ProgressBar,
  Typography,
  Loader,
} from "components";
import { sendAIQuery } from "state/actions";
import { useAppDispatch } from "state/reduxHooks";

export const TellUs = (props: any) => {
  const homeUrl = process.env.REACT_APP_DOMAIN;
  const { data, handleChange, next, text } = props;
  const [error, setError] = useState(false);
  const dispatch = useAppDispatch();
  
  const nextStep = () => {
    if (16 >= data.description.length || data.description.length >= 300) {
      setError(true);
    } else {
      if (!error) {
        dispatch(sendAIQuery(data.description));
        next();
      }
    }
  };

  useEffect(() => {
    if (data.description.length > 0) {
      setError(false);
    }
    if (data.description.length >= 300) {
      setError(true);
    }

    const listener = (event: any) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        nextStep();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [data.description]);

  const userNameState = useSelector((state: any) => state.auth.name);

  const isUserExist =
    userNameState?.length || localStorage.getItem("userId")?.length;

  const entrepreneurLink = `/entrepreneur/${localStorage.getItem(
    "userId"
  )}/feed`;

  return (
    <div className="tell-us">
      <Header
        shadow
        logolink={isUserExist ? entrepreneurLink : homeUrl}
      >
        <div className="contentHeaderWrapper">
          <ProgressBar value={18} />
          {isUserExist ? (
            <Link to={entrepreneurLink}>
              <Icon icon="close" />
            </Link>
          ) : (
            <a href={homeUrl}>
              <Icon icon="close" />
            </a>
          )}
        </div>
      </Header>
      <div className="content-container">
        <Typography text="1/5" type="p2" weight="bold" />
        <Typography text={text.header} type="h2" weight="bold" />
        <form className="content-form">
          <label>
            <input
              maxLength={300}
              type="text"
              placeholder="Describe your mobile app here*"
              name="description"
              value={data.description}
              onChange={(e) => handleChange(e)}
              required
              className={error ? "error" : ""}
            />
            <div className="second-line">
              {error ? (
                <span className={`error`}>
                  <Typography
                    type="p4"
                    text="This field requires minimum length of 16, and maximum length of 300"
                  />
                </span>
              ) : (
                <span className={`error`}> </span>
              )}
              <Typography
                text={`${data.description.length}/300`}
                type="p4"
                weight="bold"
              />
            </div>
          </label>
        </form>
        <div className="content-footer">
          <p></p>
          <Typography
            type="p4"
            // text={text.subheader}
            text="Explain in general. We will get to the details next!"
          />
          <Button
            label="Next"
            rightIcon="chevron-right"
            type="primary"
            onClick={nextStep}
          />
        </div>
      </div>
    </div>
  );
};

export default TellUs;
