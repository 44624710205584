import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { getUserInfo } from "state/actions";
import { useAppDispatch } from "state/reduxHooks";

export const PrivateRoute = ({ children }: { children: any }) => {
  const user_id = useSelector((state: any) => state.user.user.id);
  const doGetUserInfo = getUserInfo as any;

  const dispatch = useAppDispatch();
  if (!user_id) {
    const localToken = localStorage.getItem("token");
    if (localToken) {
      dispatch(doGetUserInfo());
    } else {
      return <Navigate to="/ent-login" replace />;
    }
  }
  return children;
};
