import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import API_PATHS from "services/apiPaths";
import STORAGE_KEYS from "services/storageKeys";

const getHeaders = () => ({
  Authorization: `Bearer ${localStorage.getItem("token")}`,
});

export const getUserProjects = createAsyncThunk(
  "ent/getUserProjects",
  async (_, thunkAPI) => {
    const headers = getHeaders();
    try {
      const response = await axios.get(API_PATHS.user.my_projects, { headers });
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const getProjectOffers = createAsyncThunk(
  "ent/getProjectOffers",
  async (projectId: string, thunkAPI) => {
    const headers = getHeaders();
    try {
      const response = await axios.get(API_PATHS.offers.project_offers(projectId), { headers });
      return {
        data: response.data,
      };
    } catch (e) {
      const error = e as AxiosError;
      return thunkAPI.rejectWithValue(error.response?.data);
    }
  }
);

export const getUser = createAsyncThunk("ent/getUser", async (userId: string, thunkAPI) => {
  const headers = getHeaders();
  try {
    const response = await axios.get(API_PATHS.user.get_user_info, { headers });
    return {
      data: response.data,
      userId,
    };
  } catch (e) {
    const error = e as AxiosError;
    return thunkAPI.rejectWithValue(error.response?.data);
  }
});
