import "./navigation.scss";

interface NavigationProps {
  children: any;
  direction: "vertical" | "horizontal";
}
export const Navigation = ({ children, direction }: NavigationProps) => {
  return (
    <nav className={`navigation ${direction}`}>
      <ul>{children}</ul>
    </nav>
  );
};
