import { useEffect } from "react";

import { clearState } from "state/reducers/auth";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Icon } from "components";
import "./headerEnt.scss";
import { getUserInfo } from "state/actions";
import { NavLink, useNavigate } from "react-router-dom";
import { Navbar, Container, Nav } from "react-bootstrap";

export const HeaderEnt = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logoutState = useSelector((state: any) => state.auth);
  const doGetUserInfo = getUserInfo as any;

  useEffect(() => {
    if (logoutState?.message === "User is logged out.") {
      localStorage.clear();
      setTimeout(() => navigate('/'), 1000);
    }
    return () => {
      dispatch(clearState());
    };
  }, [logoutState?.message]);

  useEffect(() => {
    dispatch(doGetUserInfo());
    return () => {
      dispatch(clearState());
    };
  }, []);

  return (
    <>
      <div className="header">
        <div className={`page-header`}>
          <Navbar bg="light" expand="md" className="navbar-bootstrap-custom">
            <Container fluid>
              <Navbar.Brand href="#">
                <a
                  href={`/entrepreneur/${localStorage.getItem("userId")}/feed`}
                >
                  <Icon icon="logo" />
                </a>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="navbarScroll" />
              <Navbar.Collapse id="navbarScroll">
                <Nav
                  className="me-auto my-2 my-lg-0 navbar-bootstrap-for-flex"
                  style={{ maxHeight: "100px" }}
                  navbarScroll
                >
                  <NavLink
                    to={`/entrepreneur/${localStorage.getItem("userId")}/feed`}
                    className={
                      window.location.pathname.includes("feed")
                        ? "navlink-custom navbar-selected"
                        : "navlink-custom"
                    }
                  >
                    <Icon icon="list-alt" />
                    <Typography type="p3" text="My Products" />
                  </NavLink>
                  <NavLink
                    to={`/entrepreneur/${localStorage.getItem(
                      "userId"
                    )}/contracts`}
                    className={
                      window.location.pathname.includes("contracts")
                        ? "navlink-custom navbar-selected"
                        : "navlink-custom"
                    }
                  >
                    <Icon icon="dashboard" />
                    <Typography type="p3" text="Contracts" />
                  </NavLink>
                  <NavLink
                    to={`/entrepreneur/${localStorage.getItem(
                      "userId"
                    )}/settings`}
                    className={
                      window.location.pathname.includes("settings")
                        ? "navlink-custom navbar-selected"
                        : "navlink-custom"
                    }
                  >
                    <Icon icon="setting" />
                    <Typography type="p3" text="Settings" />
                  </NavLink>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      </div>
    </>
  );
};
