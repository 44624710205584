import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Button,
  Header,
  Icon,
  ProgressBar,
  Typography,
  DesignSlider,
  DesignSlide,
} from "components";
import { getAppDesigns } from "services";
import { useSelector } from "react-redux";
import { all } from "axios";

export const SelectDesign = (props: any) => {
  const [isCheckedDesign, setIsCheckedDesign] = useState(false);
  const [allDesigns, setAllDesigns] = useState([]);
  const { data, next, back, text } = props;

  const getAllDesignsAPI = async () => {
    const result = await getAppDesigns();
    setAllDesigns(result.designs);
  };
  const allImages: any[] = [];
  allDesigns?.map((design: any) => allImages.push(design));
  for (const image of allImages) {
    const imageElement = new Image()
    imageElement.src = image?.image
  }
  useEffect(() => {
    getAllDesignsAPI();
  }, []);

  const handleAddDesign = (title: string, isCheckedDesign: boolean) => {
    setIsCheckedDesign((prevState) => !prevState);
    if (isCheckedDesign) {
      data.design = title;
    }
  };

  function detectDesign(designIndex: number) {
    if (designIndex) {
      setIsCheckedDesign(false);
    } else {
      setIsCheckedDesign(false);
    }
  }

  const userNameState = useSelector((state: any) => state.auth.name);

  const isUserExist =
    userNameState?.length || localStorage.getItem("userId")?.length;

  const entrepreneurLink = `/entrepreneur/${localStorage.getItem(
    "userId"
  )}/feed`;
  const homeUrl = process.env.REACT_APP_DOMAIN;

  return (
    <div className="select-design">
      <Header
        shadow
        logolink={isUserExist ? entrepreneurLink : homeUrl}
      >
        <div className="contentHeaderWrapper">
          <ProgressBar value={72} />
          {isUserExist ? (
            <Link to={entrepreneurLink}>
              <Icon icon="close" />
            </Link>
          ) : (
            <a href={homeUrl}>
              <Icon icon="close" />
            </a>
          )}
        </div>
      </Header>

      <div className="content-container">
        <Typography text="4/5" type="p2" weight="bold" />
        <Typography type="h2" weight="bold" text={`${text.header}*`} />
        <Typography
          type="p5"
          weight="bold"
          text="Please click the design that you want to pick or you can upload it!"
        />

        <DesignSlider detectDesign={detectDesign}>
          {allImages?.map(
            (
              design: {
                name: string;
                image: string;
                description: string;
              },
              index
            ) => {
              return (
                <DesignSlide
                  key={`${index}${design?.name}`}
                  title={design?.name}
                  src={design?.image}
                  description={design?.description}
                  isCheckedDesign={isCheckedDesign}
                  handleAddDesign={handleAddDesign}
                />
              );
            }
          )}
        </DesignSlider>
        <Typography type="p2" text="Or" weight="bold" />
        <button className="additional-btn">
          <Typography type="h5" text="+" weight="bold" />
          <p>Upload Design Files (Optional)</p>
        </button>
        <div className="content-footer">
          <Button label="Back" type="outlined" onClick={back} />
          <Button
            label="Next"
            rightIcon="chevron-right"
            type="primary"
            onClick={next}
            isDisabled={isCheckedDesign ? false : true}
          />
        </div>
      </div>
    </div>
  );
};

export default SelectDesign;
