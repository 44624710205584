import React from "react";
import { Icon } from "../Icon/Icon";
import "./header.scss";

interface HeaderProps {
  children: any;
  shadow?: boolean;
  logolink?: string;
}

export const Header = ({
  children,
  shadow = false,
  logolink = "/",
}: HeaderProps) => {
  return (
    <div className={`page-header ${shadow ? "shadow" : ""}`}>
      <a href={logolink}>
        <Icon icon="logo" />
      </a>
      {children}
    </div>
  );
};
