import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Header, Icon, ProgressBar, Typography } from "components";
import { useDispatch, useSelector } from "react-redux";
import { getFeatures } from "state/actions";

export const SelectFeatures = (props: any) => {
  const featuresState = useSelector((state: any) => state.features || {});
  const { isFetching, isSuccess, isError, message } = featuresState;
  const getFeat = getFeatures as any;
  const homeUrl = process.env.REACT_APP_DOMAIN;
  const dispatch = useDispatch();

  type Feature = {
    id: number;
    name: string;
    recommended_features: string;
  };

  interface Features {
    store: [Feature] | [];
    mostPreferred: [Feature] | [];
    selected: [Feature] | [] | any;
  }

  const features = useSelector((state: any) => state.features);
  const [state, setState] = useState<Features>(features);
  const { data, next, back, text } = props;
  const [loading, setLoading] = useState(true);
  const aiFeatures = useSelector((state: any) => state.ai.data);

  function sortArrayByOrderArray(arr: any[], orderArray: any[]) {
    const arrCopy = arr.slice();
    return arrCopy.sort(function (e1: any, e2: any) {
      return orderArray.indexOf(e1.id) - orderArray.indexOf(e2.id);
    });
  }

  const handleAddFeature = (event: any) => {
    event.preventDefault();

    if (isSuccess === true) {
      const selectedItem = state.store.filter(
        (el) => el.id == event.currentTarget.id
      );

      const mostPreferredItem = state.mostPreferred.filter(
        (el) => el.id == event.currentTarget.id
      );

      const storeArray = state.store.filter(
        (el: any) => el.id != event.currentTarget.id
      );

      if (selectedItem.length > 0) {
        const orderArr1 = selectedItem[0].recommended_features
          .split(",")
          .map(Number);
        sortArrayByOrderArray(storeArray, orderArr1);
        const mostPreferredArray = state.mostPreferred;
        const item = mostPreferredArray[0] as any;
        storeArray.push(item);
        const item2 = storeArray[0] as never;
        mostPreferredArray.push(item2);
        mostPreferredArray.shift();
        storeArray.shift();
      } else if (mostPreferredItem.length > 0) {
        const orderArr1 = mostPreferredItem[0].recommended_features
          .split(",")
          .map(Number);
        const mostPreferredArray = state.mostPreferred;
        const item2 = storeArray[0] as never;
        mostPreferredArray.push(item2);
        mostPreferredArray.shift();
        storeArray.shift();
        sortArrayByOrderArray(storeArray, orderArr1);
      }

      setState((preS: any) => ({
        ...preS,
        store: storeArray,
        selected: preS.selected.concat(selectedItem, mostPreferredItem),
      }));
    }
  };

  useEffect(() => {
    if (state.selected) {
      data.SelectedFeatures = state.selected.map((item: any) => {
        return { ...item, subfeatures: [] };
      });
    }
  });

  const handleRemoveFeature = (event: any) => {
    // if (
    //   event.currentTarget.id === "2" ||
    //   event.currentTarget.id === "3" ||
    //   event.currentTarget.id === "6"
    // ) {
    //   return;
    // }
    const selectedItem = state.selected.filter(
      (el: any) => el.id == event.currentTarget.id
    );

    const selectedArray = state.selected.filter(
      (el: any) => el.id != event.currentTarget.id
    );

    if (state.mostPreferred[0] === undefined && state.store.length === 0) {
      setState((preS: any) => ({
        ...preS,
        mostPreferred: preS.store.concat(selectedItem),
        selected: selectedArray,
      }));

      state.mostPreferred.shift();
    } else {
      setState((preS: any) => ({
        ...preS,
        store: preS.store.concat(selectedItem),
        selected: selectedArray,
      }));
    }
  };

  useEffect(() => {
    dispatch(getFeat());
  }, []);

  useEffect(() => {
    setState({
      store: features.features,
      mostPreferred: [
        {
          id: -1,
          name: "",
          recommended_features: "",
        },
      ],
      // mostPreferred: [features.features[0]],
      selected: [
        // features.features[0],
        // features.features[1],
        // features.features[4],
        ...aiFeatures,
      ],
    });
  }, [features]);

  useEffect(() => {
    if (state.store && state.store.length > 0) {
      setLoading(false);
      if (state.mostPreferred[0]?.recommended_features !== undefined) {
        const orderArr = state?.mostPreferred[0]?.recommended_features
          .split(",")
          .map(Number);
        sortArrayByOrderArray(state.store, orderArr);
      }
    }

    if (state.selected && state.selected.length > 0) {
      setError(false);
    }
  }, [state]);

  const [error, setError] = useState(false);

  const nextStep = () => {
    if (state.selected.length === 0) {
      setError(true);
    } else {
      setError(false);
      next();
    }
  };

  const userNameState = useSelector((state: any) => state.auth.name);

  const isUserExist =
    userNameState?.length || localStorage.getItem("userId")?.length;

  const entrepreneurLink = `/entrepreneur/${localStorage.getItem(
    "userId"
  )}/feed`;
  return (
    <div className="select-features">
      <Header
        shadow
        logolink={isUserExist ? entrepreneurLink : homeUrl}
      >
        <div className="contentHeaderWrapper">
          <ProgressBar value={36} />
          {isUserExist ? (
            <Link to={entrepreneurLink}>
              <Icon icon="close" />
            </Link>
          ) : (
            <a href={homeUrl}>
              <Icon icon="close" />
            </a>
          )}
        </div>
      </Header>

      <div className="content-container">
        <Typography text="2/5" type="p2" weight="bold" />
        <Typography text={text.header} type="h2" weight="bold" />

        <Typography
          type="p4"
          text="The most common features are already selected"
        />

        <div className="features-container">
          <div className="select">
            <div className="store">
              {!loading &&
                state.store.map(({ name, id }) => {
                  // if (id === 2 || id === 3 || id === 6) {
                  //   return;
                  // } else {
                  if (id === -1) {
                    return;
                  } else {
                    return (
                      <span onClick={handleAddFeature} id={`${id}`} key={id}>
                        <Button
                          type="initial"
                          label={name}
                          leftIcon="plus"
                          size="1"
                        />
                      </span>
                    );
                  }

                  // }
                })}
            </div>
            {/* <div className="most-preferred">
              <Typography type="p4" text="Most preferred" weight="bold" />

              {state.mostPreferred && state.mostPreferred[0] !== undefined ? (
                state.mostPreferred.map(({ name, id }) => (
                  <span onClick={handleAddFeature} id={`${id}`} key={id}>
                    <Button
                      type="initial"
                      label={name}
                      leftIcon="plus"
                      size="1"
                    />
                  </span>
                ))
              ) : (
                <p></p>
              )}
            </div> */}
          </div>

          <div className="selected">
            {!loading &&
              state.selected.map(
                ({ name, id }: { name: string; id: number }) => (
                  <span onClick={handleRemoveFeature} id={`${id}`} key={id}>
                    <Button
                      type="secondary"
                      label={name}
                      leftIcon="tick"
                      size="1"
                      //isDisabled={id === 2 || id === 3 || id === 6}
                    />
                  </span>
                )
              )}
          </div>
        </div>
        {error && (
          <span className={`error`}>
            <Typography type="p4" text="You should at least pick one feature" />
          </span>
        )}
        <div className="content-footer">
          <Button label="Back" type="outlined" onClick={back} />
          <div></div>
          <p className="count-text">
            You have selected <b>{!loading ? state.selected.length : 0} </b>
            features for your mobile application
          </p>
          <Button
            label="Next"
            rightIcon="chevron-right"
            type="primary"
            onClick={nextStep}
          />
        </div>
      </div>
    </div>
  );
};

export default SelectFeatures;
