import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Typography, Loader } from "components";
import "./proposal.scss";
import { HeaderEnt } from "../HeaderEnt/HeaderEnt";
import { ProposalDetails } from "../ProposalDetails/ProposalDetails";
import { getProjectOffers } from "state/actions";

export const Proposals = () => {
  // this state is used for the dropdown menu.
  const dispatch = useDispatch();

  // This is the method used to get the offers we are defining as getOffers.
  const getOffers = getProjectOffers as any;
  const proposalState = useSelector((state: any) => state.entData);
  const proposalsList = useSelector((state: any) => state.entData.offers);
  const { isFetching, isSuccess, isError, message } = proposalState;

  useEffect(() => {
    dispatch(getOffers(localStorage.getItem("entProjectId")));
  }, []);

  return (
    <>
      {isFetching && <Loader />}
      <div className="proposals-page">
        <HeaderEnt />

        <div className="main">
          <div className="titles">
            <Link to={`/entrepreneur/${localStorage.getItem("userId")}/feed`}>
              <Typography type="p1" text="My Products" />
            </Link>
            <div className="selected">
              <Typography type="p1" text="Proposals" />
            </div>
          </div>

          {proposalsList &&
            proposalsList.map((prop: any) => (
              <div
                onClick={() => localStorage.setItem("entProposalId", prop.id)}
                key={prop.id}
              >
                <ProposalDetails proposalsPage={true} item={prop} />
              </div>
            ))}
          {proposalsList && proposalsList?.length === 0 ? (
            <div className="no-proposal">
              <Typography type="p3" text="There are no proposals yet" />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};
