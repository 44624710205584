import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearState } from "state/reducers/auth";
import { loginUser, socialLogin } from "state/actions";
import { useNavigate } from "react-router-dom";
import { Button, Icon, Input, Typography } from "components";
// import { GoogleButton } from "./GoogleButton";
// import { FacebookButton } from "./FacebookButton";
import "./block.scss";

export const DevLogin = () => {
  const [inputs, setInputs] = React.useState({ email: "", password: "" });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authState = useSelector((state: any) => state.auth);
  const { isFetching, isSuccess, isError, message, user } = authState;
  const doLogin = loginUser as any;

  const domain = process.env.REACT_APP_DOMAIN;

  const handleInputs = (e: any) => {
    setInputs((preS) => ({
      ...preS,
      [e.target.name]: e.target.value,
    }));
  };
  const handleSubmit = (event: any) => {
    event.preventDefault();
    const data = new FormData(event.target);
    const password = data.get("password");
    const email = data.get("email");
    dispatch(
      doLogin({
        email,
        password,
        type: "developer",
      })
    );
  };

  React.useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, []);

  React.useEffect(() => {
    localStorage.getItem("token")
      ? //  && JSON.parse(localStorage.getItem("user") as any).type === "developer"
        navigate(
          `/developers/${localStorage.getItem("userId")}/feed/projects`
        )
      : "/dev-login";
    const doPush = () =>
      setTimeout(
        () =>
          navigate(
            `/developers/${localStorage.getItem("userId")}/feed/projects`
          ),
        1000
      );
    if (isError) {
      dispatch(clearState());
    }

    if (isSuccess) {
      dispatch(clearState());
      doPush();
    }
    return () => clearTimeout(doPush());
  }, [isError, isSuccess]);

  return (
    <div className="box">
      <div className="block">
        <a href={domain}>
          <Icon icon="logo" />
        </a>
        <Typography type="h3" text="Developer" weight="bold" />
        <form className="form" onSubmit={handleSubmit}>
          <div className="top-group login-active">
            <span className="signup-link">
              <Link to="/dev-signup">
                <Typography text="Sign up" type="p2" weight="bold" />
              </Link>
            </span>
            <span className="login-link">
              <Link to="/dev-login">
                <Typography text="Log in" type="p2" weight="bold" />
              </Link>
            </span>
          </div>
          <Typography type="p4" text="Login with social account" />
          <div className="button-group">
            {/* <GoogleButton action="login" /> */}
            {/* <FacebookButton action="login" /> */}
          </div>
          <Typography type="p4" text="Login with e-mail address" />
          <Input
            placeholder="E-mail"
            type="email"
            name="email"
            onChange={handleInputs}
          />
          <Input
            placeholder="Password"
            type="password"
            name="password"
            onChange={handleInputs}
          />
          <span className={`error pass`}>
            {/* {message === "Unauthorised." ? ( */}
            <Typography type="p3" text="Forgot Password?" />
            {/* ) : null} */}
          </span>
          <button
            type="submit"
            className={
              inputs.email && inputs.password.length > 0
                ? "btn-success-login"
                : ""
            }
          >
            <Typography text="Log in" type="h5" weight="bold" />
          </button>
          <span
            className={`error ${
              message === "Unauthorised." || message.includes("out")
                ? ""
                : "authorised"
            }`}
          >
            <Typography type="p3" text={message} />
          </span>
        </form>
      </div>
    </div>
  );
};
