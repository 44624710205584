import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Header,
  Icon,
  ProgressBar,
  Typography,
} from "components";
import { useAxios } from "services/useAxios";
import { getUserInfo } from "state/actions";
import { useDispatch, useSelector } from "react-redux";
import { clearState } from "state/reducers/auth";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

export const MoreInfo = (props: any) => {
  // const { v4: uuidv4 } = require('uuid');
  const doGetUserInfo = getUserInfo as any;
  const dispatch = useDispatch();
  const userState = useSelector((state: any) => state.user);
  const { data, next, back, text } = props;

  const { error, isLoading, createProject, isError, isSuccess, response } =
    useAxios();

  const [phone, setPhone] = useState("");

  const [form, setForm] = useState({
    name: "",
    email: "",
    phone: phone,
    sample1: "",
    sample2: "",
    platform: "",
    application_name: "",
    notes: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    platform: "",
    application_name: "",
  });

  const [selected, setSelected] = useState("");

  const handleChange = (event: any) => {
    const value = event.target.value;
    setForm({ ...form, [event.target.name]: value });
    setSelected(`${[event.target.name]}`);
  };

  useEffect(() => {
    if (userState.user.email) {
      setForm({ ...form, email: userState.user.email });
    }
  }, [userState]);

  useEffect(() => {
    data.info = form;
  }, [form]);

  const checkError = () => {
    let errorsObject = {
      name: "",
      email: "",
      platform: "",
      application_name: "",
    };
    let formIsValid = true;

    if (!form.name) {
      errorsObject["name"] = "This filed is required";
      formIsValid = false;
    }
    if (!form.email) {
      errorsObject["email"] = "This filed is required";
      formIsValid = false;
    } else if (form.email && !/\S+@\S+\.\S+/.test(form.email)) {
      errorsObject["email"] = "This should be a valid email";
      formIsValid = false;
    }
    if (!form.platform) {
      errorsObject["platform"] = "This filed is required";
      formIsValid = false;
    }
    if (!form.application_name) {
      errorsObject["application_name"] = "This filed is required";
      formIsValid = false;
    }

    setErrors({ ...errorsObject });
    return formIsValid;
  };

  const nextStep = () => {
    if (checkError()) {
      createProject(data);
    }
  };

  useEffect(() => {
    setErrors((prevState) => {
      return {
        ...prevState,
        [selected]: false,
      };
    });
  }, [selected]);

  useEffect(() => {
    if (isSuccess) {
      setTimeout(function () {
        next();
      }, 2000);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      dispatch(doGetUserInfo());
      const value = localStorage.getItem("userName") as any;
      setForm({ ...form, email: userState.user.email, name: value });
      return () => {
        dispatch(clearState());
      };
    }
  }, []);

  const userNameState = useSelector((state: any) => state.auth.name);

  const isUserExist =
    userNameState?.length || localStorage.getItem("userId")?.length;

  const entrepreneurLink = `/entrepreneur/${localStorage.getItem(
    "userId"
  )}/feed`;

  const homeUrl = process.env.REACT_APP_DOMAIN;

  return (
    <div className="select-design">
      <Header
        shadow
        logolink={isUserExist ? entrepreneurLink : homeUrl}
      >
        <div className="contentHeaderWrapper">
          <ProgressBar value={100} />
          {isUserExist ? (
            <Link to={entrepreneurLink}>
              <Icon icon="close" />
            </Link>
          ) : (
            <a href={homeUrl}>
              <Icon icon="close" />
            </a>
          )}
        </div>
      </Header>

      <div className="content-container">
        <Typography text="5/5" type="p2" weight="bold" />
        <Typography type="h2" weight="bold" text={text.header} />
        <div className="more-info">
          <div className="first-column">
            <label className="applicationNameLabel">
              Application name* <br />
              <input
                type="text"
                placeholder="Application name"
                name="application_name"
                value={form.application_name}
                id="application_name"
                onChange={handleChange}
                className={errors.application_name ? "error" : ""}
              />
              {errors.application_name && (
                <span className={`error`}>
                  <Typography type="p4" text={errors.application_name} />
                </span>
              )}
            </label>
            <div className="sample">
              <label>
                Any Similar Apps? (Optional)
                <div>
                  <input
                    type="url"
                    placeholder="https://"
                    name="sample1"
                    value={form.sample1}
                    id="url1"
                    onChange={handleChange}
                  />
                </div>
              </label>
            </div>
            <div className="operating-system">
              Operating system*
              <br />
              <label>
                <input
                  type="radio"
                  value="1"
                  id="both"
                  name="platform"
                  onChange={handleChange}
                />
                Android and IOS <br />
              </label>
              <label>
                <input
                  type="radio"
                  value="2"
                  id="ios"
                  name="platform"
                  onChange={handleChange}
                />{" "}
                IOS
                <br />
              </label>
              <label>
                <input
                  type="radio"
                  value="3"
                  id="android"
                  name="platform"
                  onChange={handleChange}
                />{" "}
                Android
                <br />
              </label>
              {errors.platform && (
                <span className={`error`}>
                  <Typography type="p4" text={errors.platform} />
                </span>
              )}
            </div>
          </div>

          <div className="second-column">
            <label className="notes-ent">
              Would you like to add any more notes: (Optional)
              <textarea
                placeholder="Anything more to add"
                name="notes"
                value={form.notes}
                id="notes"
                onChange={handleChange}
              />
            </label>
            {/* <button>+ Any Document (Optional)</button> */}

            {localStorage.getItem("userId") && localStorage.getItem("token") ? (
              <div className="general-info-not-visible">
                <label>
                  Name*
                  <br />
                  {localStorage.getItem("userId") ? (
                    <h4>{localStorage.getItem("userName")}</h4>
                  ) : (
                    <div>
                      <input
                        type="text"
                        placeholder="Name"
                        name="name"
                        id="name"
                        value={form.name}
                        onChange={(e) => handleChange(e)}
                        className={errors.name ? "error" : ""}
                        autoFocus
                      />
                      {errors.name && (
                        <span className={`error`}>
                          <Typography type="p4" text={errors.name} />
                        </span>
                      )}
                    </div>
                  )}
                </label>
                <label>
                  Email*
                  <br />
                  {localStorage.getItem("userName") && userState.user.email ? (
                    <h4>{userState.user.email}</h4>
                  ) : (
                    <div>
                      <input
                        type="email"
                        placeholder="Email"
                        name="email"
                        value={form.email}
                        id="email"
                        onChange={(e) => handleChange(e)}
                        className={errors.email ? "error" : ""}
                      />
                      {errors.email && (
                        <span className={`error`}>
                          <Typography type="p4" text={errors.email} />
                        </span>
                      )}
                    </div>
                  )}
                </label>
                <label className="telephone settings-2-sections">
                  <span className="settings-2-sections-span">
                    Phone: (Optional)
                  </span>
                  <div className="customPhoneWrapperInStep">
                    <PhoneInput
                      country={"us"}
                      enableSearch={true}
                      value={phone}
                      onChange={(phone) => setPhone(phone)}
                    />
                  </div>
                </label>
              </div>
            ) : (
              <div className="general-info">
                <label>
                  Name:
                  <br />
                  {localStorage.getItem("userId") ? (
                    <h4>{localStorage.getItem("userName")}</h4>
                  ) : (
                    <div>
                      <input
                        type="text"
                        placeholder="Name"
                        name="name"
                        id="name"
                        value={form.name}
                        onChange={(e) => handleChange(e)}
                        className={errors.name ? "error" : ""}
                        autoFocus
                      />
                      {errors.name && (
                        <span className={`error`}>
                          <Typography type="p4" text={errors.name} />
                        </span>
                      )}
                    </div>
                  )}
                </label>
                <label>
                  Email:
                  <br />
                  {localStorage.getItem("userName") && userState.user.email ? (
                    <h4>{userState.user.email}</h4>
                  ) : (
                    <div>
                      <input
                        type="email"
                        placeholder="Email"
                        name="email"
                        value={form.email}
                        id="email"
                        onChange={(e) => handleChange(e)}
                        className={errors.email ? "error" : ""}
                      />
                      {errors.email && (
                        <span className={`error`}>
                          <Typography type="p4" text={errors.email} />
                        </span>
                      )}
                    </div>
                  )}
                </label>
                <label className="telephone settings-2-sections">
                  <span className="settings-2-sections-span">
                    Phone: (Optional)
                  </span>
                  <div className="customPhoneWrapperInStep">
                    <PhoneInput
                      country={"us"}
                      enableSearch={true}
                      value={phone}
                      onChange={(phone) => setPhone(phone)}
                    />
                  </div>
                </label>
              </div>
            )}
          </div>
        </div>
        <div className="messages-api">
          {response && (
            <span className="success">
              <Typography type="p3" text={response.message} />{" "}
            </span>
          )}
          {error && !response && (
            <span className="error">
              <Typography type="p4" text={error.message.platform[1]} />
            </span>
          )}
          {isError && !error && (
            <span className="error">
              <Typography
                type="p4"
                text="Something went wrong please try again"
              />
            </span>
          )}
        </div>

        <div className="content-footer">
          <Button label="Back" type="outlined" onClick={back} />
          <Button
            label="Finish"
            rightIcon="chevron-right"
            type="primary"
            onClick={() => nextStep()}
          />
        </div>
      </div>
    </div>
  );
};

export default MoreInfo;
