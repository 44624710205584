import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Button, Icon, Loader } from "components";
import "./projects.scss";
import { getUserProjects } from "state/actions";
import { HeaderEnt } from "../HeaderEnt/HeaderEnt";

export const Projects = () => {
  const dispatch = useDispatch();
  // This is the method used to get the projects we are defining as getProjects.
  const getProjects = getUserProjects as any;

  // this following selectors are used to get information form the store.
  const projectState = useSelector((state: any) => state.entData);
  const projectList = useSelector((state: any) => state.entData.projects);

  const { isFetching, isSuccess, isError, message } = projectState;

  useEffect(() => {
    dispatch(getProjects());
  }, []);

  // projects is initialized
  let projects: any = [];
  // projects is defined
  if (projectList instanceof Array) {
    projects = projectList.map((project) => {
      return (
        <div
          className="project"
          key={project.id}
          onClick={() => {
            localStorage.setItem("entProjectId", project.id);
            localStorage.setItem("projectName", project.name);
          }}
        >
          <div className="info">
            <Typography type="p1" text={project.application_name} />
            <Typography type="p3" text={project.description} />
          </div>
          <Link
            to={`/entrepreneur/${localStorage.getItem("userId")}/${
              project.id
            }/proposals`}
          >
            <Button type="primary" label="Proposals" size="2" />
          </Link>
        </div>
      );
    });
  }

  return (
    <>
      {isFetching && <Loader />}
      <div className="entrepreneur" id="entrepreneur">
        <HeaderEnt />
        <div id="main" className="main">
          <div className="titleWrapper">
            <div className="title">
              <Typography type="p1" text="My Products" />
              <Link
                to={`/entrepreneur/${localStorage.getItem("userId")}/proposals`}
              >
                <Typography type="p1" text="Proposals" />
              </Link>
            </div>

            <Link to="/project-request">
              <Button type="primary" label="New Product" size="2" />
            </Link>
          </div>
          <div className="projects">{projectList ? projects : ""}</div>
          {/* <div className="projects">{projectList ? projects : <Loader />}</div> */}
          <div className="no-project">
            {projectList && projectList.length === 0 ? (
              <Typography type="p2" text="Start a new project" />
            ) : (
              ""
            )}
          </div>
          {/* <div className="add-project">
            <Link to="/project-request" className="new-project-btn">
              <Icon icon="plus" />
            </Link>
            <Typography type="p2" text="New Product" />
          </div> */}
        </div>
      </div>
    </>
  );
};
