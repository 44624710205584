import React, { useEffect } from "react";
import { Icon } from "..";
import "./designslider.scss";
interface DesignSliderProp {
  children: any;
  detectDesign: any;
}

export const DesignSlider = ({ children, detectDesign }: DesignSliderProp) => {
  const [index, setIndex] = React.useState(0);

  const handleSlideBack = (ind: any) => {
    if (ind === 0) {
      setIndex(children.length - 1);
    } else {
      setIndex(ind - 1);
    }
  };

  const handleSlideForward = (ind: any) => {
    if (ind === children.length - 1) {
      setIndex(0);
    } else {
      setIndex(ind + 1);
    }
  };

  useEffect(() => {
    detectDesign(index);
  }, [index]);

  return (
    <div className="design-slider">
      <div onClick={() => handleSlideBack(index)} className="slide-back">
        <Icon icon="chevron-right" />
      </div>
      <div className="slide-container">
        <div className="slide-box">{children[index]}</div>
      </div>
      <div onClick={() => handleSlideForward(index)} className="slide-forward">
        <Icon icon="chevron-right" />
      </div>
    </div>
  );
};
