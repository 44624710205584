import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getFeatures } from "../actions";

interface FeaturesState {
  features: any[];
  message: string;
  isFetching: boolean;
  isSuccess: boolean;
  isError: boolean;
}

const initialState: FeaturesState = {
  features: [],
  message: "",
  isFetching: false,
  isSuccess: false,
  isError: false,
};

export const featuresSlice = createSlice({
  name: "features",
  initialState,
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFeatures.fulfilled, (state, action: PayloadAction<{ features: any[] }>) => {
        state.features = action.payload.features;
        state.isFetching = false;
        state.isSuccess = true;
      })
      .addCase(getFeatures.pending, (state) => {
        state.isFetching = true;
      })
      .addMatcher(
        (action) => action.type === getFeatures.rejected.type,
        (state, action: PayloadAction<{ message: string }>) => {
          state.message = action.payload.message;
          state.isFetching = false;
          state.isError = true;
        }
      );
  },
});

export const { clearState } = featuresSlice.actions;

export default featuresSlice.reducer;




// import { createSlice } from "@reduxjs/toolkit";
// import { getFeatures } from "../actions";

// const initialState = {
//   // store:[],
//   // mostPreferred:[],
//   // selected:[],
//   features: [],
//   message: "",
//   isFetching: false,
//   isSuccess: false,
//   isError: false,
// };

// export const featuresSlice = createSlice({
//   name: "features",
//   initialState,
//   reducers: {
//     clearState: (state) => {
//       state.isError = false;
//       state.isSuccess = false;
//       state.isFetching = false;
//       return state;
//     },
//   },
//   extraReducers: {
//     [getFeatures.fulfilled]: (state, { payload }) => {
//       // console.log("payload", payload);
//       state.features = payload.features;
//       // state.store = payload.features.slice(1)
//       // state.mostPreferred = payload.features[0]
//       // state.selected = payload.message
//       state.isFetching = false;
//       state.isSuccess = true;
//     },
//     [getFeatures.pending]: (state) => {
//       state.isFetching = true;
//     },
//     [getFeatures.rejected]: (state, { payload }) => {
//       // console.log("payload", payload);
//       state.errors = payload.message;
//       state.isFetching = false;
//       state.isError = true;
//     },
//   },
// });

// export const { clearState } = featuresSlice.actions;
