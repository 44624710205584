import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearState } from "state/reducers/auth";
import { loginUser, socialLogin } from "state/actions";
import { useNavigate } from "react-router-dom";
import { Button, Icon, Input, Typography } from "components";
import { GoogleButton } from "./GoogleButton";
import { FacebookButton } from "./FacebookButton";
import "./block.scss";
import { useAxios } from "services/useAxios";

export const EntLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authState = useSelector((state: any) => state.auth);
  const { isFetching, isSuccess, isError, message, user } = authState;

  const doLogin = loginUser as any;

  const domain = process.env.REACT_APP_DOMAIN;

  const { error, assignProject, response } = useAxios();

  const [data, setData] = useState({
    email: "",
    password: "",
    type: "customer",
  });

  const handleChange = (e: any) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    await dispatch(doLogin({ ...data })).then(
      (res: any) =>
        res.error.message === "Rejected" &&
        alert(
          `${res.payload.data.message.slice(
            0,
            -1
          )}: Email or password is invalid!`
        )
    );
  };

  // useEffect(() => {
  //   return () => {
  //     dispatch(clearState());
  //   };
  // }, []);

  useEffect(() => {
    localStorage.getItem("token")
      ? navigate(
          authState?.type === "customer"
            ? `/entrepreneur/${localStorage.getItem("userId")}/feed`
            : authState?.type === "developer"
            ? `/developers/${localStorage.getItem("userId")}/feed/projects`
            : "/ent-login"
        )
      : "/ent-login";
    const doPush = () =>
      setTimeout(
        () =>
          navigate(
            authState?.type === "customer"
              ? `/entrepreneur/${localStorage.getItem("userId")}/feed`
              : authState?.type === "developer"
              ? `/developers/${localStorage.getItem("userId")}/feed/projects`
              : "/ent-login"
          ),
        1000
      );

    if (isError) {
      dispatch(clearState());
    }
    if (!localStorage.getItem("uuid") && isSuccess) {
      dispatch(clearState());
      doPush();
    } else if (localStorage.getItem("uuid") && isSuccess) {
      assignProject(localStorage.getItem("uuid"));
    }
    return () => clearTimeout(doPush());
  }, [isError, isSuccess, localStorage.getItem("token")]);

  return (
    <div className="box">
      <div className="block">
        <a href={domain}>
          <Icon icon="logo" />
        </a>
        <form className="form" onSubmit={handleSubmit} noValidate>
          <div className="top-group login-active">
            <span className="signup-link">
              <Link to="/ent-signup">
                <Typography text="Sign up" type="p2" weight="bold" />
              </Link>
            </span>
            <span className="login-link">
              <Link to="/ent-login">
                <Typography text="Log in" type="p2" weight="bold" />
              </Link>
            </span>
          </div>
          {/* <Typography type="p4" text="Login with social account" />
          <div className="button-group">
            <GoogleButton action="login" />
            <FacebookButton action="login" />
          </div> */}
          <Input
            placeholder="E-mail"
            type="email"
            name="email"
            onChange={handleChange}
          />
          <Input
            placeholder="Password"
            type="password"
            name="password"
            onChange={handleChange}
          />
          <span className={`error pass`}>
            {/* {message === "Unauthorised." ? ( */}
            <Typography type="p3" text="Forgot Password?" />
            {/* ) : null} */}
          </span>
          <button type="submit" style={{ backgroundColor: "#0779e4" }}>
            <Typography text="Log in" type="h5" weight="bold" />
          </button>

          {response && (
            <span className="success">
              <Typography type="p3" text={response.message} />{" "}
            </span>
          )}
          {error && !response && (
            <span className="error">
              <Typography type="p4" text={error.message[0]} />
            </span>
          )}
          <span
            className={`error ${
              message === "Unauthorised." ? "" : "authorised"
            }`}
          >
            <Typography
              type="p3"
              text={
                message === "Unauthorised."
                  ? "Wrong e-mail password combination"
                  : message
              }
            />
          </span>
        </form>
      </div>
    </div>
  );
};
