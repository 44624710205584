import { Link } from "react-router-dom";
import { Typography, Icon } from "../index";
import "./linkedtab.scss";

interface LinkedTabProps {
  tabs: any;
  underline?: boolean;
}
export const LinkedTab = ({ tabs, underline = false }: LinkedTabProps) => {
  return (
    <div className={`linked-tab ${underline ? "underline" : ""}`}>
      {tabs.map((tab: any, ind: number) => (
        <div key={ind}>
          {" "}
          {tabs.length > 1 && tabs[1].name.length > 2 ? (
            <Icon icon="chevrons-right" />
          ) : null}{" "}
          <Link
            to={tab.link}
            style={{ pointerEvents: tab.link ? "auto" : "none" }}
          >
            <Typography type="p1" text={tab.name} weight="bold" />{" "}
          </Link>
        </div>
      ))}
    </div>
  );
};
