import { Route, Routes } from "react-router-dom";
import { Projects } from "./Projects/Projects";
import { EntSettings } from "./EntSettings/EntSettings";
import { Proposals } from "./Proposals/Proposals";
import { SingleProposal } from "./SingleProposal/SingleProposal";
import { Contracts } from "./Contracts/Contracts";
import { AllProposals } from "./AllProposals/AllProposals";
import { useSelector } from "react-redux";

export const Entrepreneur = () => {
  const user_id = useSelector((state: any) => state.user.user.id);

  return (
    <Routes>
      <Route path={`${user_id}/feed`} element={<Projects />} />
      <Route path={`${user_id}/settings`} element={<EntSettings />} />
      <Route path={`${user_id}/:id/proposals`} element={<Proposals />} />
      <Route path={`${user_id}/proposals/:id`} element={<SingleProposal />} />
      <Route path={`${user_id}/contracts`} element={<Contracts />} />
      <Route path={`${user_id}/proposals`} element={<AllProposals />} />
    </Routes>
  );
};
