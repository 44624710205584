import { configureStore } from "@reduxjs/toolkit";
import {
  authSlice,
  projectsSlice,
  offersSlice,
  entSlice,
  featuresSlice,
  questionsSlice,
  plansSlice,
  userSlice,
  aiSlice
} from "./reducers";

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    projects: projectsSlice.reducer,
    offers: offersSlice.reducer,
    entData: entSlice.reducer,
    features: featuresSlice.reducer,
    questions: questionsSlice.reducer,
    plans: plansSlice.reducer,
    user: userSlice.reducer,
    ai: aiSlice.reducer
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

