import { useState } from "react";
import axios from "axios";

interface data {
  [x: string]: any;
  info?: any;
  SelectedFeatures?: any;
  description?: string;
}

export const useAxios = () => {
  const [response, setResponse] = useState<any>();
  const [error, setError] = useState<any>();
  const [isError, setIsError] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const createProject = async (data: data) => {
    const baseURL = "https://v2-services.app-cart.io/api/v2/projects/create";

    let config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    Object.keys(data).forEach(
      (k) => data[k] == null || (data[k] == "" && delete data[k])
    );
    Object.keys(data.info).forEach(
      (k) => data.info[k] == null || (data.info[k] == "" && delete data.info[k])
    );

    const bodyParameters = {
      features: data.SelectedFeatures,
      description: data.description,
      ...data.info,
      platform: parseInt(data.info.platform),
    };

    try {
      if (localStorage.getItem("token")) {
        setIsLoading(true);
        let response = await axios.post(baseURL, bodyParameters, config);
        setResponse(response.data);
        setIsSuccess(true);
        setIsError(false);
        setIsLoading(false);

        return response.data;
      } else {
        setIsLoading(true);
        let response = await axios.post(baseURL, bodyParameters);
        setResponse(response.data);
        setIsSuccess(true);
        setIsError(false);
        setIsLoading(false);

        localStorage.setItem("uuid", response.data.project.uuid);
        return response.data;
      }
    } catch (error: any) {
      setError(error.response.data);
      setIsSuccess(false);
      setIsError(true);
    }
  };

  const getPageText = async (slug: String) => {
    const baseURL = `https://v2-services.app-cart.io/api/v2/pages/${slug}`;

    try {
      setIsLoading(true);
      let response = await axios.get(baseURL);
      setResponse(response.data);
      setIsSuccess(true);
      setIsError(false);
      setIsLoading(false);
    } catch (e: any) {
      setError(e.response.data);
      setIsSuccess(false);
      setIsLoading(false);
      setIsError(true);
    }
  };

  const assignProject = async (key: string | null) => {
    const baseURL = `https://v2-services.app-cart.io/api/v2/projects/assign/${key}`;

    let config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    try {
      let response = await axios.get(baseURL, config);
      setResponse(response.data);
      setIsSuccess(true);
      setIsError(false);
      setIsLoading(false);
      localStorage.removeItem("uuid");
    } catch (e: any) {
      setError(e.response.data);
      setIsSuccess(false);
      setIsLoading(false);
      setIsError(true);
    }
  };

  return {
    error,
    isLoading,
    createProject,
    isError,
    isSuccess,
    response,
    getPageText,
    assignProject,
  };
};
