import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { saveOffer } from "state/actions";
import { clearState } from "state/reducers/offers";

import { Button, Input, Typography } from "components";
import "./requirements.scss";

export const Requirements = () => {
  const navigate = useNavigate();

  const offersState = useSelector((state: any) => state.offers);
  const projectsState = useSelector((state: any) => state.projects);

  const { projectById } = projectsState;

  const offerData = JSON.parse(`${localStorage.getItem("offer")}`);

  const offeredProject = JSON.parse(
    `${localStorage.getItem("offeredProject")}`
  );

  const {
    errors,
    isOfferSavedSuccess,
    isOfferSavedError,
    isOfferSavedSuccessMsg,
  } = offersState;

  const {
    cover_letter,
    hourly_rate,
    id,
    total_working_hours,
    working_hours_per_week,
  } = offerData;
  const [calculatingState, setCalculatingState] = useState({ initial: 0 });
  const [workingHoursPerWeek, setWorkingHoursPerWeek] = useState(
    working_hours_per_week || 0
  );
  const [hourlyRate, setHourlyRate] = useState(hourly_rate || 0);

  const handleChangeHourlyRate = (e: any) => {
    e.preventDefault();
    setHourlyRate(e.target.value);
  };
  const [coverLetter, setCoverLetter] = useState(cover_letter);
  const [inputToFocus, setInputToFocus] = useState(0);

  const formRef = useRef() as any;

  const dispatch = useDispatch();
  const doSaveOffer = saveOffer as any;
  const handleOnInputOnlyNumber = (e: any) => {
    e.preventDefault();
    e.target.value = e.target.value.replace(/[^\dA-Z]/g, "").trim();
  };

  const handleChangeHourPerFeature = (e: any) => {
    setCalculatingState((preS: any) => ({
      ...preS,
      [e.target.name]: e.target.value,
    }));
  };
  const handleChangeWorkingHoursPerWeek = (e: any) => {
    setWorkingHoursPerWeek(e.target.value);
  };
  const handleChangeCoverLetter = (e: any) => {
    e.preventDefault();
    setCoverLetter(e.target.value);
  };
  const countTotalTime = Object.values(calculatingState).reduce(
    (pre: any, curr: any) => parseInt(pre) + parseInt(curr)
  );
  const countBid = (countTotalTime || total_working_hours) * hourlyRate;

  const countHowLongProject = countTotalTime / workingHoursPerWeek;
  const checkedcountHowLongProject =
    countHowLongProject >= 0 && !(countHowLongProject === Infinity)
      ? Math.ceil(countHowLongProject)
      : total_working_hours;

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const project_id = localStorage.getItem("projectById");
    const data = new FormData(formRef.current);
    const working_hours_per_week = parseInt(
      data.get("working_hours_per_week") as any
    );
    const total_working_hours = parseInt(
      data.get("total_working_hours") as any
    );
    const hourly_rate = parseInt(data.get("hourly_rate") as any);
    const cover_letter = data.get("cover_letter");
    dispatch(
      doSaveOffer({
        project_id,
        working_hours_per_week,
        total_working_hours,
        hourly_rate: hourly_rate,
        cover_letter,
      })
    );
  };

  const handleEnter = (e: any, ind: any) => {
    if (e.key.toLowerCase() === "enter") {
      setInputToFocus(ind + 1);
    }
  };

  useEffect(() => {
    const el = document.getElementById(
      `offer-hour-per-feature-${inputToFocus}`
    );
    el?.focus();
  }, [setInputToFocus, inputToFocus]);

  useEffect(() => {
    isOfferSavedSuccess
      ? navigate(
          `/developers/${localStorage.getItem(
            "userId"
          )}/offers/${id}-/congratulations`
        )
      : null;
    return () => {
      dispatch(clearState());
    };
  }, [isOfferSavedSuccess, isOfferSavedError]);
  return (
    <>
      <div className="requirements-list-offer">
        <Typography type="p5" text={offeredProject?.technical_summary} />
        <table className="requirements-table">
          <thead>
            <tr>
              <td>
                <Typography type="p4" text="Feature" />
              </td>
              <td>
                <Typography type="p4" text="Task" />
              </td>
              <td>
                <Typography type="p4" text="Details" />
              </td>
              <td>
                <Typography type="p4" text="Effort / Hours" />
              </td>
            </tr>
          </thead>
          <tbody>
            {projectById.tasks?.map((task: any, ind: any) => {
              return (
                <tr key={task.id}>
                  <td>
                    <Typography type="p5" text={task?.feature} />
                  </td>
                  <td className="tasks">
                    <Typography type="p5" text={task?.task} />
                  </td>
                  <td className="details">
                    <Typography type="p5" text={`${task?.sentence || ""}`} />
                  </td>
                  <td className="effort-hour">
                    <input
                      onInput={handleOnInputOnlyNumber}
                      pattern="[0-9\s]{13,19}"
                      onChange={handleChangeHourPerFeature}
                      name={`hour-per-feature-${task?.feature}`}
                      maxLength={5}
                      id={`offer-hour-per-feature-${ind}`}
                      onKeyDown={(e) => handleEnter(e, ind)}
                    />
                    <Typography type="p5" text="Hr" />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <form ref={formRef}>
        <section className="inputs section-1">
          <Input
            label="Working hours per week"
            placeholder="0"
            name="working_hours_per_week"
            value={`${workingHoursPerWeek}`}
            onInput={handleOnInputOnlyNumber}
            onChange={handleChangeWorkingHoursPerWeek}
          />
          <span className={`error`}>
            <Typography
              type="p3"
              text={errors && errors.working_hours_per_week}
            />
          </span>
          <Input
            label={`Hourly Rate ($/hr)`}
            placeholder="0"
            value={`${hourlyRate || ""}`}
            name="hourly_rate"
            {...{ autoComplete: "off" }}
            onInput={handleOnInputOnlyNumber}
            onChange={handleChangeHourlyRate}
          />
          <span className={`error`}>
            <Typography type="p3" text={errors && errors.hourly_rate} />
          </span>
        </section>
        <section className="section-2">
          <div className="inputs">
            <Input
              label="Bid"
              placeholder=""
              value={`${countBid}`}
              name="bid"
              {...{ readOnly: true }}
              iconRight="$"
            />
            <span className={`error`}>
              <Typography type="p3" text={errors && errors.hourly_rate} />
            </span>
            <Input
              label="App cart Fee"
              placeholder="0"
              value=""
              {...{ readOnly: true }}
              iconRight="$"
            />
            <Input
              label="You will receive "
              placeholder=""
              value={`${countBid}`}
              {...{ readOnly: true }}
              iconRight="$"
            />
          </div>
          <div className="inputs">
            <Input
              label="Total Time "
              placeholder=""
              name="total_working_hours"
              value={`${countTotalTime || total_working_hours} Hr`}
              {...{ readOnly: true }}
            />
            <span className={`error`}>
              <Typography type="p3" text={errors?.total_working_hours} />
            </span>
            <Input
              label="How Long this Project take"
              placeholder=""
              value={`${
                checkedcountHowLongProject ||
                Math.ceil(total_working_hours / working_hours_per_week)
              } Weeks`}
            />
          </div>
        </section>
        <section className="section-3">
          <Typography type="p2" text="Cover Letter" weight="bold" />
          <textarea
            value={coverLetter}
            onChange={handleChangeCoverLetter}
            rows={14}
            cols={10}
            wrap="soft"
            name="cover_letter"
          />
          <span className={`error`}>
            <Typography type="p3" text={errors && errors.cover_letter} />
          </span>
        </section>
        <div
          className={`edit-feedback-msg edit-${
            isOfferSavedSuccess ? "successfull" : "unsuccessful"
          }`}
        >
          <Typography type="p3" text={isOfferSavedSuccessMsg} />
        </div>
        <Button
          type="primary"
          label="Edit Offer"
          onClick={handleSubmit}
          size="2"
        />
      </form>
    </>
  );
};
