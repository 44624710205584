import { createSlice } from "@reduxjs/toolkit";
import { getPlans, subscribePlan } from "../actions";

interface PlansState {
  status: string;
  message: string;
  plans: [];
  isError: boolean;
  isSuccess: boolean;
  isFetching: boolean;
}

const initialState: PlansState = {
  status: "",
  message: "",
  plans: [],
  isError: false,
  isSuccess: false,
  isFetching: false,
};

export const plansSlice = createSlice({
  name: "plans",
  initialState,
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPlans.fulfilled, (state, { payload }) => {
        state.message = payload.message;
        state.status = payload.status;
        state.plans = payload.plans;
        state.isSuccess = true;
      })
      .addCase(getPlans.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getPlans.rejected, (state) => {
        state.isError = true;
      })
      .addCase(subscribePlan.fulfilled, (state, { payload }) => {
        state.message = payload.message;
        state.status = payload.status;
        state.isSuccess = false;
        state.isSuccess = true;
      })
      .addCase(subscribePlan.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(subscribePlan.rejected, (state) => {
        state.isError = true;
      });
  },
});

export const { clearState } = plansSlice.actions;

export default plansSlice.reducer;


// import { createSlice } from "@reduxjs/toolkit";
// import { getPlans, subscribePlan } from "../actions";

// const initialState = {
//   status: "",
//   message: "",
//   plans: [],
//   isError: false,
//   isSuccess: false,
//   isFetching: false,
// };

// export const plansSlice = createSlice({
//   name: "plans",
//   initialState,
//   reducers: {
//     clearState: (state) => {
//       state.isError = false;
//       state.isSuccess = false;
//       state.isFetching = false;
//       return state;
//     },
//   },
//   extraReducers: {
//     [getPlans.fulfilled]: (state, { payload }) => {
//       state.message = payload.message;
//       state.status = payload.status;
//       state.plans = payload.plans;
//       state.isSuccess = true;
//     },
//     [getPlans.pending]: (state, { payload }) => {
//       state.isFetching = true;
//     },
//     [getPlans.rejected]: (state, { payload }) => {
//       state.isError = true;
//     },
//     [subscribePlan.fulfilled]: (state, { payload }) => {
//       state.message = payload.message;
//       state.status = payload.status;
//       state.isSuccess = false;
//       state.isSuccess = true;
//     },
//     [subscribePlan.pending]: (state, { payload }) => {
//       state.isFetching = true;
//     },
//     [subscribePlan.rejected]: (state, { payload }) => {
//       state.isError = true;
//     },
//   },
// });

// export const { clearState } = plansSlice.actions;
