import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { GoogleButton } from "./GoogleButton";
import { FacebookButton } from "./FacebookButton";
import { clearState } from "state/reducers/auth";
import { signupUser, socialRegister } from "state/actions";
import { useNavigate } from "react-router-dom";
import { Button, Icon, Input, Typography } from "components";
import "./block.scss";

export const DevSignup = () => {
  const [inputs, setInputs] = React.useState({
    email: "",
    password: "",
    c_password: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authState = useSelector((state: any) => state.auth);
  const { isFetching, isSuccess, isError, message, user, errors } = authState;
  const doSignup = signupUser as any;

  const domain = process.env.REACT_APP_DOMAIN;

  const handleInputs = (e: any) => {
    setInputs((preS) => ({
      ...preS,
      [e.target.name]: e.target.value,
    }));
  };
  const handleSubmit = (event: any) => {
    event.preventDefault();
    const data = new FormData(event.target);
    const name = data.get("name");
    const surname = data.get("surname");
    const password = data.get("password");
    const c_password = data.get("c_password");
    const email = data.get("email");
    dispatch(
      doSignup({
        name,
        surname,
        password,
        c_password,
        email,
        type: "developer",
      })
    );
  };

  React.useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, []);

  const doPush = () =>
    setTimeout(
      () => navigate(`/developers/${user.id}/feed/projects`),
      1000
    );
  React.useEffect(() => {
    if (isError) {
      dispatch(clearState());
    }

    if (isSuccess) {
      dispatch(clearState());
      doPush();
    }

    return () => clearTimeout(doPush());
  }, [isError, isSuccess]);

  return (
    <div className="box">
      <div className="block">
        <a href={domain}>
          <Icon icon="logo" />
        </a>
        <Typography type="h3" text="Developer" weight="bold" />
        <form className="form" onSubmit={handleSubmit}>
          <div className="top-group signup-active">
            <span className="signup-link">
              <Link to="/dev-signup">
                <Typography text="Sign up" type="p2" weight="bold" />
              </Link>
            </span>
            <span className="login-link">
              <Link to="/dev-login">
                <Typography text="Log in" type="p2" weight="bold" />
              </Link>
            </span>
          </div>
          <Typography type="p4" text="Sign up with social account" />
          <div className="button-group">
            <GoogleButton action="register" />
            <FacebookButton action="register" />
          </div>
          <div className="input-group">
            <Input placeholder="Name" label="Sign up with e-mail" name="name" />
            <Input placeholder="Lastname" name="surname" />
          </div>
          <span className={`error`}>
            <Typography type="p3" text={errors.name} />
          </span>
          <Input
            placeholder="E-mail"
            type="email"
            name="email"
            onChange={handleInputs}
          />
          <span className={`error`}>
            <Typography type="p3" text={errors.email} />
          </span>
          <Input
            placeholder="Password"
            type="password"
            name="password"
            onChange={handleInputs}
          />
          <span className={`error`}>
            <Typography type="p3" text={errors.password} />
          </span>
          <Input
            placeholder="Confirm Password"
            type="password"
            name="c_password"
            onChange={handleInputs}
          />
          <span className={`error`}>
            <Typography type="p3" text={errors.c_password} />
          </span>
          <div className="radio">
            <label>
              <input required type="checkbox" />
            </label>
            <Typography
              type="p4"
              text="Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor."
            />
          </div>
          <button
            type="submit"
            className={
              inputs.email &&
              inputs.c_password.length > 0 &&
              inputs.password.length > 0
                ? "btn-success-login"
                : ""
            }
          >
            <Typography text="Sign up" type="h5" weight="bold" />
          </button>
          <span
            className={`error ${
              message === "Validation Error." ? "" : "authorised"
            }`}
          >
            <Typography type="p3" text={message} />
          </span>
        </form>
      </div>
    </div>
  );
};
