import { createSlice } from "@reduxjs/toolkit";
import { act } from "react-dom/test-utils";
import { sendAIQuery } from "state/actions/ai";

interface AIState {
  loading: boolean;
  data: [];
  errors: string[];
}

const initialState: AIState = {
  loading: false,
  data: [],
  errors: [],
};

export const aiSlice = createSlice({
  name: "ai",
  initialState,
  reducers: {
    clearState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendAIQuery.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendAIQuery.fulfilled, (state, action) => {
        state.loading = false;
        const updatedFeatures = action.payload.features.map((obj: { title: string, description: string }, index: number) => {
          return { id: index + 22, name: obj.title, description: obj.description, recommended_features: '' };
        });
        state.data = updatedFeatures;
      })
      .addCase(sendAIQuery.rejected, (state, action) => {
        const { errors } = action.payload as { errors: any };
        state.loading = false;
        state.errors = errors;
      });
  },
});

export const { clearState } = aiSlice.actions;
export default aiSlice.reducer;
