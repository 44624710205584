import { Typography } from "components";
import { useDispatch, useSelector } from "react-redux";
// import "./notes.scss"

export const Notes = () => {
  const projectsState = useSelector((state: any) => state.projects);
  const { projects, isSuccess, isError } = projectsState;
  const findProjectById =
    projects &&
    projects.find((p: any) => p.id == localStorage.getItem("projectById"));
  return (
    <div className="notes">
      <Typography
        type="p3"
        text={`${findProjectById && findProjectById.notes}`}
      />
    </div>
  );
};
