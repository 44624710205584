import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Header,
  Icon,
  ProgressBar,
  Typography,
  Input,
} from "components";
import congrats from "images/congrats.png";
import { useDispatch, useSelector } from "react-redux";
import { clearState } from "state/reducers/auth";
import { signupUser } from "state/actions";
import { useNavigate } from "react-router-dom";

export const ThankYou = (props: any) => {
  const { data, text } = props;

  const dispatch = useDispatch();
  const authState = useSelector((state: any) => state.auth);
  const { isFetching, isSuccess, isError, message, user, errors } = authState;
  const navigate = useNavigate();
  const doSignup = signupUser as any;

  const [signUpData, setSignUpData] = useState({
    name: data.info.name,
    email: data.info.email,
    password: "",
    c_password: "",
    type: "customer",
    project_key: localStorage.getItem("uuid"),
  });

  const [isEditing, setIsEditing] = useState(false);

  const [passwordError, setPasswordError] = useState("");

  const handleChange = (e: any) => {
    setPasswordError("");
    setSignUpData({
      ...signUpData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e: any) => {
    if (signUpData.password !== signUpData.c_password) {
      setPasswordError("Passwords don't match");
    } else {
      e.preventDefault();
      dispatch(doSignup({ ...signUpData }));
    }
  };

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, []);

  useEffect(() => {
    const doPush = () =>
      setTimeout(
        () =>
          navigate(`/entrepreneur/${localStorage.getItem("userId")}/feed`),
        1000
      );
    if (isError) {
      dispatch(clearState());
    }

    if (isSuccess) {
      dispatch(clearState());
      localStorage.removeItem("uuid");
      doPush();
    }

    return () => clearTimeout(doPush());
  }, [isError, isSuccess]);

  const userNameState = useSelector((state: any) => state.auth.name);

  const isUserExist =
    userNameState?.length || localStorage.getItem("userId")?.length;

  const entrepreneurLink = `/entrepreneur/${localStorage.getItem(
    "userId"
  )}/feed`;
  const homeUrl = process.env.REACT_APP_DOMAIN;

  return (
    <div className="thank-you">
      <Header
        shadow
        logolink={isUserExist ? entrepreneurLink : homeUrl}
      >
        <div className="contentHeaderWrapper">
          <ProgressBar value={100} />
          {isUserExist ? (
            <Link to={entrepreneurLink}>
              <Icon icon="close" />
            </Link>
          ) : (
            <a href={homeUrl}>
              <Icon icon="close" />
            </a>
          )}
        </div>
      </Header>

      {localStorage.getItem("userId") ? (
        <div className="content-container">
          <Typography text="5/5" type="p2" weight="bold" />
          <Typography
            type="h2"
            weight="bold"
            text={`${text.header} ${localStorage.getItem("userName")}`}
          />
          <img src={congrats} />
        </div>
      ) : (
        <div className="content-container">
          <Typography text="5/5" type="p2" weight="bold" />
          <Typography type="h2" weight="bold" text={text.header} />
          <img src={congrats} />

          <div className="content-footer">
            <Typography
              type="h4"
              weight="bold"
              text="Now it is time to receive offers!"
            />

            <Typography
              type="h4"
              weight="bold"
              text="Would like to create a profile so you can track your offers?"
            />
          </div>

          <div className="sign-up-info">
            <div className="info-container">
              <div className="info">
                <input
                  value={data.info.name}
                  type="text"
                  disabled
                  className="disabledInputsStep6"
                />

                {!errors.email &&
                errors.email !== "The email has already been taken." ? (
                  <input
                    value={data.info.email}
                    type="text"
                    disabled
                    className="disabledInputsStep6"
                  />
                ) : (
                  ""
                )}

                {!isEditing &&
                  errors.email == "The email has already been taken." && (
                    <div className="email-input">
                      <p>Email: {data.info.email} </p>
                      <div onClick={() => setIsEditing(true)}>
                        <Icon icon="edit" />
                      </div>
                    </div>
                  )}

                {isEditing &&
                  errors.email == "The email has already been taken." && (
                    <input
                      placeholder="Email"
                      type="email"
                      name="email"
                      value={signUpData.email || ""}
                      onChange={(e) => handleChange(e)}
                    />
                  )}
              </div>
              <div>
                <span className={`error`}>
                  <Typography type="p3" text={errors.name} />
                </span>
                <span className={`error email`}>
                  <Typography type="p3" text={errors.email} />
                </span>
              </div>
            </div>
            <div className="password-container">
              <div className="passwords">
                <input
                  placeholder="Password"
                  type="password"
                  name="password"
                  onChange={handleChange}
                />
                <input
                  placeholder="Confirm password"
                  type="password"
                  name="c_password"
                  onChange={handleChange}
                />
              </div>
              <span className={`error`}>
                <Typography type="p3" text={errors.password} />
                {passwordError.length > 0 && (
                  <Typography type="p3" text={passwordError} />
                )}
              </span>
            </div>
          </div>
          <Button
            label="Create Account"
            type="primary"
            onClick={handleSubmit}
          />
          {errors.email == "The email has already been taken." && (
            <div className="sign-in">
              <Typography type="p3" text="Already have an account?" />
              <Link to="/ent-login">Sign in</Link>
            </div>
          )}

          <span
            className={`error ${
              message === "Validation Error." ? "" : "authorised"
            }`}
          >
            <Typography type="p3" text={message} />
          </span>
        </div>
      )}
    </div>
  );
};

export default ThankYou;
