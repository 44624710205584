import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route } from "react-router-dom";

import {
  Navigation,
  NavigationItem,
  Typography,
} from "components";
import {
  createQuestionByFeatureId,
  getProjectById,
  getQuestionsByFeatureId,
  getProjectTasksById,
} from "state/actions";
import { ContentSection } from "./ContentSection";
import { clearState } from "state/reducers/questions";

import "./projects.scss";
export const Projects = () => {
  const [text, setText] = React.useState("");
  const [featureID, setFeautureID] = React.useState(null);

  const dispatch = useDispatch();
  const questionsState = useSelector((state: any) => state.questions);
  const projectsState = useSelector((state: any) => state.projects);

  const { projectById, projectTasksById } = projectsState;
  const {
    message,
    askedQuestion,
    questionsPerFeature,
    isSuccess,
    isError,
    isQuestionCreatedSuccess,
    isQuestionCreatedError,
  } = questionsState;

  const doGetQuestionsByFeatureId = getQuestionsByFeatureId as any;
  const doCreateQuestionByFeatureId = createQuestionByFeatureId as any;
  const doGetProjectById = getProjectById as any;
  const doGetProjectTasksById = getProjectTasksById as any;

  const getQuestionOnChange = (e: any) => {
    e.preventDefault();
    setText(e.target.value);
  };

  const getFeatureQuestions = (e: any, feature_id: any) => {
    e.preventDefault();
    dispatch(doGetQuestionsByFeatureId(feature_id));
    setFeautureID(feature_id);
  };

  const createQuestion = (e: any, feature_id: any) => {
    e.preventDefault();
    dispatch(
      doCreateQuestionByFeatureId({ feature_id: feature_id, question: text })
    );
    setText("");
  };

  const questionBody =
    questionsPerFeature &&
    questionsPerFeature.map((e: any) => (
      <>
        {" "}
        <span>{e.question}</span>
      </>
    ));
  React.useEffect(() => {
    dispatch(doGetProjectById(localStorage.getItem("projectById")));
    dispatch(doGetProjectTasksById(localStorage.getItem("projectById")));
  }, [isSuccess, isError]);

  React.useEffect(() => {
    if (isQuestionCreatedSuccess) {
      dispatch(doGetQuestionsByFeatureId(featureID));
    }
    if (isQuestionCreatedError) {
      dispatch(clearState());
    }
  }, [isQuestionCreatedSuccess, isQuestionCreatedError, askedQuestion]);

  React.useEffect(() => {
    const firstFeatureID = projectById?.features?.[0].id;

    dispatch(doGetQuestionsByFeatureId(firstFeatureID));
    return () => {
      dispatch(clearState());
    };
  }, []);

  return (
    <>
      <div className="account-projects">
        <div className="sidebar">
          {/* <Typography type="p3" text="General Information" /> */}
          <Navigation direction="vertical">
            {projectById?.features?.map((feature: any) => (
              <NavigationItem
                label={feature.name}
                to={`/developers/${localStorage.getItem(
                  "userId"
                )}/feed/${localStorage.getItem("projectById")}/projects/${
                  feature.name
                }`}
                id={feature.id}
                onClick={(e: any) => getFeatureQuestions(e, feature.id)}
              />
            ))}
          </Navigation>
        </div>
        <>
          <Route 
            path={`/developers/${localStorage.getItem("userId")}/feed/${localStorage.getItem("projectById")}/projects`} 
            element={<Navigate replace to={`/developers/${localStorage.getItem("userId")}/feed/${localStorage.getItem("projectById")}/projects/General%20Information`} />}
          />
          {/* <Redirect
            from={`/developers/${localStorage.getItem("userId")}/feed/${localStorage.getItem("projectById")}/projects`}
            to={`/developers/${localStorage.getItem("userId")}/feed/${localStorage.getItem("projectById")}/projects/General%20Information`}
          /> */}
          {projectById?.features?.map((feature: any) => (
            <ContentSection
              path={`/developers/${localStorage.getItem(
                "userId"
              )}/feed/${localStorage.getItem("projectById")}/projects/${
                feature.name
              }`}
              questionTitle={feature.name}
              questionBody={questionBody}
              description={feature.description}
              id={feature.id}
              onChange={getQuestionOnChange}
              onClick={(e: any) => createQuestion(e, feature.id)}
              message={message}
              questionText={text}
              subfeatures={feature.subfeatures}
            />
          ))}
        </>
      </div>
    </>
  );
};
