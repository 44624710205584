import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Header,
  LandingSlider,
  LandingSlide,
  Tabs,
  Typography,
  Loader,
} from "components";
import company1 from "images/companies/company1.png";
import company2 from "images/companies/company2.png";
import company3 from "images/companies/company3.png";
import company4 from "images/companies/company4.png";
import company5 from "images/companies/company5.png";
import { useAxios } from "services/useAxios";
import "./home.scss";

export const Home = () => {
  const { error, isLoading, getPageText, isError, isSuccess, response } =
    useAxios();

  const subDomain = process.env.REACT_APP_SUBDOMAIN;
  
  // const projectHref =
  //   localStorage.getItem("token") &&
  //   localStorage.getItem("user") &&
  //   JSON.parse(localStorage.getItem("user") as any).type === "developer"
  //     ? `/developers/${localStorage.getItem("userId")}/feed/projects`
  //     : "/projects";

  useEffect(() => {
    getPageText("home");
  }, []);

  return (
    <div>
      <Header>
        <Tabs />
      </Header>
      {isLoading && <Loader />}
      {isError && <p>{error}</p>}
      {isSuccess && (
        <div>
          <div className="landing-page">
            <div className="text">
              <Typography type="h1" text={response.page.blocks[0].header} />
              <Typography type="p2" text={response.page.blocks[0].content} />

              <div className="buttons">
                <Link to={`${subDomain}project-request`}>
                  <Button
                    type="primary"
                    label="Let's start"
                    size="4"
                    rightIcon="chevron-right"
                  />
                </Link>
                {/* <Link to={projectHref}>
                  <Button type="outlined" label="Find projects" size="4" />
                </Link> */}
              </div>
            </div>
            <img src={response.page.blocks[0].image_url} className="mainImg" />
          </div>
          <div className="companies">
            <img src={company1} className="mainImg" />
            <img src={company2} className="mainImg" />
            <img src={company3} className="mainImg" />
            <img src={company4} className="mainImg" />
            <img src={company5} className="mainImg" />
          </div>
          <div className="images">
            <LandingSlider>
              <LandingSlide
                title={response.page.blocks[1].header}
                description={response.page.blocks[1].content}
                img={response.page.blocks[1].image_url}
              />
              <LandingSlide
                title={response.page.blocks[2].header}
                description={response.page.blocks[2].content}
                img={response.page.blocks[2].image_url}
              />
              <LandingSlide
                title={response.page.blocks[3].header}
                description={response.page.blocks[3].content}
                img={response.page.blocks[3].image_url}
              />
            </LandingSlider>
          </div>
        </div>
      )}
    </div>
  );
};
