import React from "react";
import { Button, Input } from "..";
import { Typography } from "components";

export const AddItem = (props: any) => {
  const [expand, setExpand] = React.useState(false);
  const handleExpand = (event: any) => {
    event.preventDefault();
    setExpand((preS) => !preS);
  };
  const { data, addNewFeature, featId } = props;

  const [newFeature, setNewFeature] = React.useState({});
  const [featureName, setFeatureName] = React.useState("");
  const [error, setError] = React.useState(false);

  const handleChange = (event: any) => {
    const value = event.target.value;
    setFeatureName(value);
    setNewFeature({
      id: featId,
      name: value,
      description: "",
      image_url: "",
      placeholder: "",
      recommended_features: "11,5,4,3,6,12,20,14,2,17,7,9,19,18,13,16,15,10,8",
      subfeatures: [],
      additional_infos: [],
    });
  };

  const handleSubmit = (newFeature: any) => {
    if (featureName.length > 0) {
      addNewFeature(newFeature);
      setFeatureName("");
      setExpand(false);
    } else if (featureName.length === 0) {
      setError(true);
    }
  };

  React.useEffect(() => {
    if (featureName.length > 0) {
      setError(false);
    }
  }, [featureName.length]);

  return (
    <div className={`details-item-add ${expand ? "expand-on" : "expand-off"}`}>
      <Button
        type="outlined"
        label="+ Add More"
        size="1"
        onClick={handleExpand}
      />
      <div className="add-text">
        <Input
          onChange={(e) => handleChange(e)}
          placeholder="Feature name"
          value={featureName}
        />
        <Button
          type="initial"
          label="Add"
          size="1"
          onClick={() => handleSubmit(newFeature)}
        />
      </div>
      {error && <p className="feature-error">This filed is required</p>}
    </div>
  );
};
