import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import API_PATHS from "services/apiPaths";
import STORAGE_KEYS from "services/storageKeys";

export const signupUser = createAsyncThunk(
  "auth/signupUser",
  async (data, thunkAPI) => {
    try {
      const userData = (await axios.post(API_PATHS.auth.register, data)).data;
      
      //TODO create a util localStorage function for multiple objects
      localStorage.setItem(STORAGE_KEYS.token, userData.token);
      localStorage.setItem(STORAGE_KEYS.userId, userData.user.id);
      localStorage.setItem(STORAGE_KEYS.userName, userData.user.name);
      localStorage.setItem(
        STORAGE_KEYS.user,
        JSON.stringify(userData.user)
      );

      return userData;
    } catch (error) {
      //TODO: regulate error messages
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async (data, thunkApi) => {
    try {
      let userData = await axios.post(API_PATHS.auth.login, data);
      localStorage.setItem(STORAGE_KEYS.token, userData.data.token);
      localStorage.setItem(STORAGE_KEYS.userId, userData.data.user.id);
      localStorage.setItem(STORAGE_KEYS.userName, userData.data.user.name);
      localStorage.setItem(
        STORAGE_KEYS.user,
        JSON.stringify(userData.data.user)
      );

      return userData.data;
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.response);
    }
  }
);

export const logoutUser = createAsyncThunk(
  "auth/logoutUser",
  async (d, thunkAPI) => {
    try {

      const response = await axios.get(API_PATHS.auth.logout);

      localStorage.clear();
      return response.data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

interface socialLoginData {
  token: string;
  type: string;
}

export const socialLogin = createAsyncThunk(
  "auth/socialLogin",
  async (data: socialLoginData, thunkAPI) => {
    try {
      let body = {
        token: data.token,
        type: data.type,
      };
      let response = await axios.post(API_PATHS.auth.post_social_login, body);
      localStorage.setItem(STORAGE_KEYS.token, response.data.token);
      localStorage.setItem(STORAGE_KEYS.userId, response.data.user.id);
      localStorage.setItem(STORAGE_KEYS.userName, response.data.user.name);
      localStorage.setItem(
        STORAGE_KEYS.user,
        JSON.stringify(response.data.user)
      );
      return response.data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

interface socialRegisterData {
  token: string;
  type: string;
  user_type: string;
}

export const socialRegister = createAsyncThunk(
  "auth/socialRegister",
  async (data: socialRegisterData, thunkAPI) => {
    try {
      let body = {
        token: data.token,
        type: data.type,
        user_type: data.user_type,
      };

      const response = await axios.post(
        API_PATHS.auth.post_social_register,
        body
      );

      localStorage.setItem(STORAGE_KEYS.token, response.data.token);
      localStorage.setItem(STORAGE_KEYS.userId, response.data.user.id);
      localStorage.setItem(STORAGE_KEYS.userName, response.data.user.name);
      localStorage.setItem(
        STORAGE_KEYS.user,
        JSON.stringify(response.data.user)
      );

      return response.data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
