import { Typography } from "..";
import "./landingslider.scss";

interface LandingSlideProps {
  title: string;
  description: string;
  img: string;
}
export const LandingSlide = ({
  title,
  description,
  img,
}: LandingSlideProps) => {
  return (
    <div className="slide-div">
      <div className="text">
        <Typography type="h1" text={title} weight="bold" />
        <Typography type="p2" text={description} />
      </div>
      <div className="image">
        <img src={img} />
      </div>
    </div>
  );
};
