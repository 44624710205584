import { useEffect, useState } from "react";
import { Form } from "./Form/Form";
import "./questions.scss";

export const Questions = (props: any) => {
  const [featureId, setFeatureId] = useState();

  useEffect(() => {
    if (props.features && props.features.length > 0) {
      setFeatureId(props.features[0].id);
    }
  }, [props]);

  return (
    <div className="questions">
      <div className="features-border">
        <div className="features">
          {props.features &&
            props.features.map((feature: any) => {
              return (
                <p
                  className={`${featureId === feature.id ? "selected" : ""}`}
                  onClick={() => {
                    setFeatureId(feature.id);
                  }}
                  key={feature.id}
                >
                  {feature.name}
                </p>
              );
            })}
        </div>
      </div>

      <div className="questions-form">
        <Form featureId={featureId} />
      </div>
    </div>
  );
};
