import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { GoogleButton } from "./GoogleButton";
// import { FacebookButton } from "./FacebookButton";
import { clearState } from "state/reducers/auth";
import { signupUser, socialLogin, socialRegister } from "state/actions";
import { useNavigate } from "react-router-dom";
import { Button, Icon, Input, Typography } from "components";
import "./block.scss";
import { Select } from "components/Input/select";
import { warn } from "console";

export const EntSignup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authState = useSelector((state: any) => state.auth);
  const { isFetching, isSuccess, isError, message, user, errors } = authState;
  
  const doSignup = signupUser as any;

  const domain = process.env.REACT_APP_DOMAIN;

  const [data, setData] = useState({
    name: "",
    email: "",
    password: "",
    c_password: "",
    type: "customer",
    // uuid: uuidv4()
  });

  const handleChange = (e: any) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    // handleRegister(data);
    dispatch(doSignup({ ...data }));
  };

  useEffect(() => {
    if (authState.errors.includes("The email has already been taken.")) {
      // setTimeout(() => history.replace(`/ent-login`), 1000);
      setTimeout(() => navigate(`/ent-login`), 1000);
    }
  }, []);

  // useEffect(() => {
  //   return () => {
  //     dispatch(clearState());
  //   };
  // }, []);

  useEffect(() => {
    const doPush = () =>
      setTimeout(
        () =>
          navigate(
            data?.type === "customer"
              ? `/entrepreneur/${
                  user.id ? user.id : localStorage.getItem("userId")
                }/feed`
              : data?.type === "developer"
              ? `/developers/${
                  user.id ? user.id : localStorage.getItem("userId")
                }/feed/projects`
              : "/"
          ),
        1000
      );
    
    if (isError) {
      if (authState?.errors?.includes("The email has already been taken.")) {
        alert(`The email has already been taken. Please log in.`);
        setTimeout(() => navigate(`/ent-login`), 1000);
      } else {
        let warningText = "";
        warningText = authState?.errors.reduce((acc: any, error: any) => acc + error + "\n", warningText);
        alert(warningText);
      }

      dispatch(clearState())
    } 

    if (isSuccess || authState.message === "User is registered") {
      dispatch(clearState());
      doPush();
    }
    return () => clearTimeout(doPush());
  }, [isError, isSuccess, localStorage.getItem("token")]);
  
  const options = [
    { value: "customer", label: "Entrepreneur" },
    { value: "developer", label: "Developer" },
  ];

  return (
    <div className="box">
      <div className="block">
        <a href={domain}>
          <Icon icon="logo" />
        </a>
        <form
          className="form"
          // onSubmit={e => handleSubmit(e)}
          noValidate
        >
          <div className="top-group signup-active">
            <span className="signup-link">
              <Link to="/ent-signup">
                <Typography text="Sign up" type="p2" weight="bold" />
              </Link>
            </span>
            <span className="login-link">
              <Link to="/ent-login">
                <Typography text="Log in" type="p2" weight="bold" />
              </Link>
            </span>
          </div>
          {/* <Typography type="p4" text="Sign up with social account" />
          <div className="button-group">
            <GoogleButton action="register" />
            <FacebookButton action="register" />
          </div> */}

          <Input placeholder="Name" name="name" onChange={handleChange} />
          <span className={`error`}>
            <Typography type="p3" text={errors?.name} />
          </span>
          <Input
            placeholder="E-mail address"
            type="email"
            name="email"
            onChange={handleChange}
          />
          <Select options={options} onChange={handleChange} name="type" />
          <span className={`error`}>
            <Typography type="p3" text={errors?.email} />
          </span>
          <Input
            placeholder="Password"
            type="password"
            name="password"
            onChange={handleChange}
          />
          <Input
            placeholder="Confirm password"
            type="password"
            name="c_password"
            onChange={handleChange}
          />
          <span className={`error`}>
            <Typography type="p3" text={errors?.password} />
          </span>
          <button
            type="submit"
            onClick={handleSubmit}
            style={{ backgroundColor: "#0779e4" }}
          >
            <Typography text="Sign up" type="h5" weight="bold" />
          </button>
          <span
            className={`error ${
              message === "Validation Error." ? "" : "authorised"
            }`}
          >
            <Typography type="p3" text={message} />
          </span>
        </form>
      </div>
    </div>
  );
};
