import { createSlice } from "@reduxjs/toolkit";
import {
  signupUser,
  loginUser,
  logoutUser,
  socialLogin,
  socialRegister,
} from "../actions";

interface User {
  name: string;
  type: string;
  id: number | null;
  image?: string;
}

interface AuthState {
  name: string;
  type: string;
  token: string;
  id: number | null;
  message: string | undefined;
  status: string;
  errors: string[] | undefined;
  isFetching: boolean;
  isSuccess: boolean;
  isError: boolean;
  user: User;
}

const initialState: AuthState = {
  name: "",
  type: "",
  token: "",
  id: null,
  message: "",
  status: "",
  errors: [],
  isFetching: false,
  isSuccess: false,
  isError: false,
  user: {
    name: "",
    type: "",
    id: null,
  },
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.message = "";
      state.errors = [];
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signupUser.fulfilled, (state, { payload }) => {
        switch (payload.status) {
          case "ok":
            state.token = payload.token;
            state.message = payload.message;
            state.status = payload.status;
            state.name = payload.user.name;
            state.type = payload.user.type;
            state.id = payload.user.id;
            state.user = payload.user;
            state.isFetching = false;
            state.isSuccess = true;
            break;
          default:
            state.message = payload.message;
            state.errors = payload.errors;
            state.isSuccess = false;
            break;
        }
      })
      .addCase(signupUser.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(signupUser.rejected, (state, action: any) => {
        const errs = action?.payload?.response?.data?.errors;
        const errMessages = []
        for(let i in errs) {
          errMessages.push(errs[i][0])
        }
        if (errs) {
          state.errors = errMessages
        }
        // if (error) {
        //   state.errors = [error.message];
        // }
        state.isFetching = false;
        state.isError = true;
      })
      .addCase(loginUser.fulfilled, (state, { payload }) => {
        switch (payload.status) {
          case "ok":
            state.token = payload.token;
            state.message = payload.message;
            state.status = payload.status;
            state.name = payload.user.name;
            state.type = payload.user.type;
            state.id = payload.user.id;
            state.user = payload.user;
            state.isFetching = false;
            state.isSuccess = true;
            break;
          default:
            state.message = payload.message;
            state.isSuccess = false;
            break;
        }
      })
      .addCase(loginUser.rejected, (state, action: any) => {
        state.message = action.payload.data.message;
        state.isFetching = false;
        state.isError = true;
      })
      .addCase(loginUser.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(logoutUser.fulfilled, (state, { payload }) => {
        state.message = payload.message;
        state.status = payload.status;
        state.name = "";
        state.token = "";
        state.id = null;
        state.isFetching = true;
      })
      .addCase(logoutUser.rejected, (state, { error }) => {
        if (error) {
          state.message = error.message;
        }
        state.isFetching = false;
        state.isError = true;
      })
      .addCase(logoutUser.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(socialLogin.fulfilled, (state, { payload }) => {
        state.message = payload.message;
        state.status = payload.status;
      })
      .addCase(socialLogin.rejected, (state, { error }) => {
        if (error) {
          state.message = error.message;
        }
        state.isFetching = false;
        state.isError = true;
      })
      .addCase(socialLogin.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(socialRegister.fulfilled, (state, { payload }) => {
        state.message = payload.message;
        state.status = payload.status;
      })
      .addCase(socialRegister.rejected, (state, { payload }: any) => {
        if (payload) {
          state.message = payload.message;
          state.errors = payload.errors;
        }
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(socialRegister.pending, (state) => {
        state.isFetching = true;
      });
  },
});

export const { clearState } = authSlice.actions;

export default authSlice.reducer;

// import { createSlice } from "@reduxjs/toolkit";
// import {
//   signupUser,
//   loginUser,
//   logoutUser,
//   socialLogin,
//   socialRegister,
// } from "../actions";

// const initialState = {
//   name: "",
//   type: "",
//   token: "",
//   id: null,
//   message: "",
//   status: "",
//   errors: [],
//   isFetching: false,
//   isSuccess: false,
//   isError: false,
//   user: {},
// };

// export const authSlice = createSlice({
//   name: "auth",
//   initialState,
//   reducers: {
//     clearState: (state) => {
//       state.isError = false;
//       state.isSuccess = false;
//       state.isFetching = false;
//       state.message = "";
//       state.errors = [];
//       return state;
//     },
//   },
//   extraReducers: {
//     [signupUser.fulfilled]: (state, { payload }) => {
//       switch (payload.status) {
//         case "ok":
//           state.token = payload.token;
//           state.message = payload.message;
//           state.status = payload.status;
//           state.name = payload.user.name;
//           state.type = payload.user.type;
//           state.id = payload.user.id;
//           state.image = payload.user.image;
//           state.user = payload.user;
//           state.isFetching = false;
//           state.isSuccess = true;
//           break;
//         default:
//           state.message = payload.message;
//           state.errors = payload.data.errors;
//           state.isSuccess = false;
//           break;
//       }
//     },
//     [signupUser.pending]: (state) => {
//       state.isFetching = true;
//     },
//     [signupUser.rejected]: (state, { payload }) => {
//       state.errors = payload.data.message;
//       state.isFetching = false;
//       state.isError = true;
//     },
//     [loginUser.fulfilled]: (state, { payload }) => {
//       switch (payload.status) {
//         case "ok":
//           state.token = payload.token;
//           state.message = payload.message;
//           state.status = payload.status;
//           state.name = payload.user.name;
//           state.type = payload.user.type;
//           state.id = payload.user.id;
//           state.image = payload.user.image;
//           state.user = payload.user;
//           state.isFetching = false;
//           state.isSuccess = true;
//           break;
//         default:
//           state.message = payload.message;
//           state.isSuccess = false;
//           break;
//       }
//     },
//     [loginUser.rejected]: (state, { payload }) => {
//       state.isFetching = false;
//       state.isError = true;
//       state.message = payload.message;
//     },
//     [loginUser.pending]: (state) => {
//       state.isFetching = true;
//     },
//     [logoutUser.fulfilled]: (state, { payload }) => {
//       state.message = payload.message;
//       state.status = payload.status;
//       state.name = "";
//       state.token = "";
//       state.id = null;
//       localStorage.clear();
//     },
//     [logoutUser.rejected]: (state, { payload }) => {
//       state.isFetching = false;
//       state.isError = true;
//     },
//     [logoutUser.pending]: (state) => {
//       state.isFetching = true;
//     },
//     [socialLogin.fulfilled]: (state, { payload }) => {
//       state.message = payload.message;
//       state.status = payload.status;
//     },
//     [socialLogin.rejected]: (state, { payload }) => {
//       state.isFetching = false;
//       state.isError = true;
//     },
//     [socialLogin.pending]: (state) => {
//       state.isFetching = true;
//     },
//     [socialRegister.fulfilled]: (state, { payload }) => {
//       state.message = payload.message;
//       state.status = payload.status;
//     },
//     [socialRegister.rejected]: (state, { payload }) => {
//       state.message = payload.message;
//       state.errors = payload.errors;
//       state.isFetching = false;
//       state.isError = true;
//       state.isSuccess = false;
//     },
//     [socialRegister.pending]: (state) => {
//       state.isFetching = true;
//     },
//   },
// });

// export const { clearState } = authSlice.actions;
