import React from "react";
import { Route } from "react-router-dom";

import { Button, Typography } from "components";
import "./projects.scss";
interface ContentSectionProps {
  description: string;
  questionTitle: string;
  path: string;
  message?: string;
  id?: any;
  onChange?: any;
  onClick?: any;
  questionBody?: any;
  questionText?: any;
  subfeatures?: [];
}

export const ContentSection = ({
  description,
  questionTitle,
  path,
  id,
  onChange,
  onClick,
  questionBody,
  message,
  questionText,
  subfeatures,
}: ContentSectionProps) => {
  return (
    <Route path={`${path}`} key={id}>
      <div className="content" key={id}>
        <div className="description">
          <Typography
            type="p3"
            text={`📝 ${description || "No description about this feature"}`}
          />
          {!!subfeatures?.length ? (
            <details className="subfeatures">
              <summary className="typography p4">Subfeatures</summary>
              <ul>
                {subfeatures?.map((sf: any) => {
                  return (
                    <li>
                      <details>
                        <summary className="typography p4">{sf.name}</summary>
                        <p className="typography p5">
                          📝{" "}
                          {sf.description ||
                            "No description about this subfeature"}
                        </p>
                      </details>
                    </li>
                  );
                })}
              </ul>
            </details>
          ) : null}
        </div>
        <div className="questions">
          <div className="questions-header">
            <Typography
              type="p3"
              text={`Questions - ${questionTitle}`}
              weight="bold"
            />
          </div>
          <div className="questions-body">{questionBody}</div>
          <div className="questions-footer">
            <img src="https://via.placeholder.com/30" />
            <label>
              <textarea
                rows={3}
                value={questionText}
                onChange={onChange}
                placeholder="Ask Questions"
              />
              <span className="created-message">{message}</span>
              <Button
                type="primary"
                label="Send"
                rightIcon="tick"
                onClick={onClick}
              />
            </label>
          </div>
        </div>
      </div>
    </Route>
  );
};
