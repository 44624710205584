import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getProjects,
  getProjectById,
  getProjectTasksById,
  loadMoreProjects,
} from "../actions";
import { uniqBy } from "lodash";

interface Project {
  id: number;
}

interface RootState {
  status: string;
  message: string;
  projects: Project[];
  projectToApplyId: number | null;
  projectById: Project[];
  isSuccess: boolean;
  isError: boolean;
  isFetching: boolean;
  moreProjects: Project[];
  moreProjectsSuccess: boolean;
  moreProjectsError: boolean;
  moreProjectsFetching: boolean;
}

const initialState: RootState = {
  status: "",
  message: "",
  projects: [],
  projectToApplyId: null,
  projectById: [],
  isSuccess: false,
  isError: false,
  isFetching: false,
  moreProjects: [],
  moreProjectsSuccess: false,
  moreProjectsError: false,
  moreProjectsFetching: false,
};

export const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    getProjectToApply: (state, action: PayloadAction<{ id: number }>) => {
      state.projectToApplyId = action.payload.id;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProjects.fulfilled, (state, action) => {
        state.message = action.payload.message;
        state.status = action.payload.status;
        state.projects = action.payload.project;
        state.isSuccess = true;
        state.isError = false;
        state.isFetching = false;
      })
      .addCase(getProjects.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getProjects.rejected, (state) => {
        state.isFetching = false;
        state.isError = true;
      })
      .addCase(getProjectById.fulfilled, (state, action) => {
        state.message = action.payload.message;
        state.status = action.payload.status;
        state.projectById = action.payload.project;
        state.isFetching = false;
        state.isError = false;
        state.isFetching = true;
      })
      .addCase(getProjectTasksById.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getProjectTasksById.rejected, (state) => {
        state.isFetching = false;
        state.isError = true;
      })
      .addCase(getProjectTasksById.fulfilled, (state, action) => {
        state.message = action.payload.message;
        state.status = action.payload.status;
        state.projectById = action.payload.tasks;
        state.isFetching = false;
        state.isError = false;
        state.isFetching = true;
      })
      .addCase(loadMoreProjects.fulfilled, (state, action) => {
        state.moreProjectsSuccess = true;
        state.moreProjectsError = false;
        state.projects = uniqBy([...state.projects, ...action.payload.project], "id");
      })
      .addCase(loadMoreProjects.pending, (state) => {
        state.moreProjectsFetching = true;
      })
      .addCase(loadMoreProjects.rejected, (state) => {
        state.moreProjectsError = true;
        state.moreProjectsFetching = false;
        state.moreProjectsSuccess = false;
      });
  },
});

export const { getProjectToApply } = projectsSlice.actions;

export default projectsSlice.reducer;


// import { createSlice } from "@reduxjs/toolkit";
// import {
//   getProjects,
//   getProjectById,
//   getProjectTasksById,
//   loadMoreProjects,
// } from "../actions";
// import { uniqBy } from "lodash";

// const initialState = {
//   status: "",
//   message: "",
//   projects: [],
//   projectToApplyId: null,
//   projectById: [],
//   isSuccess: false,
//   isError: false,
//   isFetching: false,
//   moreProjects: [],
//   moreProjectsSuccess: false,
//   moreProjectsError: false,
//   moreProjectsFetching: false,
// };

// export const projectsSlice = createSlice({
//   name: "projects",
//   initialState,
//   reducers: {
//     getProjectToAplly: (state, { payload }) => {
//       state.projectToApplyId = payload.id;
//     },
//   },
//   extraReducers: {
//     [getProjects.fulfilled]: (state, { payload }) => {
//       state.message = payload.message;
//       state.status = payload.status;
//       state.projects = payload.project;
//       state.isSuccess = true;
//       state.isError = false;
//       state.isFetching = false;
//     },
//     [getProjects.pending]: (state) => {
//       state.isFetching = true;
//     },
//     [getProjects.rejected]: (state) => {
//       state.isFetching = false;
//       state.isError = true;
//     },
//     [getProjectById.fulfilled]: (state, { payload }) => {
//       state.message = payload.message;
//       state.status = payload.status;
//       state.projectById = payload.project;
//       state.isFetching = false;
//       state.isError = false;
//       state.isFetching = true;
//     },
//     [getProjectTasksById.pending]: (state) => {
//       state.isFetching = true;
//     },
//     [getProjectTasksById.rejected]: (state) => {
//       state.isFetching = false;
//       state.isError = true;
//     },
//     [getProjectTasksById.fulfilled]: (state, { payload }) => {
//       state.message = payload.message;
//       state.status = payload.status;
//       state.projectTasksById = payload.tasks;
//       state.isFetching = false;
//       state.isError = false;
//       state.isFetching = true;
//     },
//     [loadMoreProjects.fulfilled]: (state, { payload }) => {
//       state.moreProjectsSuccess = true;
//       state.moreProjectsError = false;
//       state.projects = uniqBy([...state.projects, ...payload.project], "id");
//     },
//     [loadMoreProjects.pending]: (state, { payload }) => {
//       state.moreProjectsFetching = true;
//     },
//     [loadMoreProjects.rejected]: (state, { payload }) => {
//       state.moreProjectsError = true;
//       state.moreProjectsFetching = false;
//       state.moreProjectsSuccess = false;
//     },
//   },
// });

// export const { getProjectToAplly } = projectsSlice.actions;
