import { useEffect, useState } from "react";
import { getQuestions, askQuestion } from "services";
import { Typography, Loader } from "components";
import { SingleQuestions } from "./SingleQuestions";

export const Form = ({ featureId }: any) => {
  const [questions, setQuestions] = useState([]);
  const [question, setQuestion] = useState("");
  const [updateQuestion, setUpdateQuestion] = useState(false);
  const [updateReply, setUpdateReply] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [didMount, setDidMount] = useState(false);

  const getAllQuestions = async (id: any) => {
    const result = await getQuestions(id);
    await setQuestions(result.questions);
    await setUpdateReply(false);
    await setUpdateQuestion(false);
    await setIsLoading(false);
  };

  const handleChange = (e: any) => {
    setQuestion(e.target.value);
  };

  const handleSubmit = (e: any, id: any) => {
    e.preventDefault();
    askQuestion(id, question);
    setUpdateQuestion(true);
    setQuestion("");
    setIsLoading(true);
  };

  useEffect(() => {
    setDidMount(true);
    return () => setDidMount(false);
  }, []);

  useEffect(() => {
    if (featureId) {
      getAllQuestions(featureId);
    }
  }, [didMount, featureId, updateQuestion]);

  return (
    <>
      {isLoading && <Loader />}
      <div className="all-questions">
        <Typography type="p1" text="Questions" />
        {questions &&
          questions.map((question: any) => {
            return (
              <SingleQuestions
                key={question.id}
                question={question}
                getAllQuestions={getAllQuestions}
                featureId={featureId}
                setUpdateReply={setUpdateReply}
                updateReply={updateReply}
                setIsLoading={setIsLoading}
              />
            );
          })}
      </div>
      <div className="new-question">
        <Typography type="p3" text="New question" />
        <div className="question-form">
          <input
            placeholder="Ask a question"
            onChange={handleChange}
            value={question}
          />
          <button
            onClick={(e) => {
              handleSubmit(e, featureId);
            }}
          >
            Submit
          </button>
        </div>
      </div>
    </>
  );
};
