import { useState, useEffect } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { Typography, Loader } from "components";
import "../Proposals/proposal.scss";
import { HeaderEnt } from "../HeaderEnt/HeaderEnt";
import congrats from "images/congrats.png";
import "./contracts.scss";
import { getContracts } from "services";

export const Contracts = () => {
  const [contracts, setContracts] = useState([]);

  const getUserContracts = async () => {
    const result = await getContracts();
    await setContracts(result.contracts);
  };

  useEffect(() => {
    getUserContracts();
  }, []);

  let contractsArr: any;

  if (contracts) {
    contractsArr = contracts.map((item: any) => {
      const startDate = moment(item.start_date);
      const endDate = moment(item.estimated_end_date);
      const duration = endDate.to(startDate, true);

      return (
        <Link
          key={item.id}
          to={`/entrepreneur/${localStorage.getItem("userId")}/proposals/${
            item.id
          }`}
          onClick={() => {
            localStorage.setItem("entProposalId", item.offer_id);
            localStorage.setItem("entProjectId", item.project_id);
          }}
        >
          <div className="offer">
            <div className="img-container">
              {item.user.image ? (
                <img src={item.user.image} width="68" height="68" />
              ) : (
                <img src={"/images/account.png"} width="68" height="68" />
              )}
            </div>

            <div className="developer">
              <Typography type="p2" text={item.user.name} />
            </div>
            <div className="success">
              <img src={congrats} />
            </div>
            <div className="details">
              <Typography
                type="p1"
                text={
                  JSON.stringify(item.hourly_rate * item.total_working_hours) +
                  "$"
                }
              />
              <div className="line" />
              <Typography type="p1" text={duration} />
            </div>
            <div className="message">
              <p>
                Smartup Network ile başarıyla eşleştirildiniz! Ekip ile
                aşağıdaki iletişim bilgileri ile iletişime geçebilirsiniz.
                Bilgileriniz ekip ile de paylaşılmıştır.
              </p>
              <Typography type="p3" text={item.user.email} />
            </div>
          </div>
        </Link>
      );
    });
  }

  return (
    <>
      <div className="offers-page">
        <HeaderEnt />

        <div className="main">
          <div className="titles">
            <Link to={`/entrepreneur/${localStorage.getItem("userId")}`}>
              <span className="settings-title">Contracts</span>
            </Link>
            {/* <Link to="/entrepreneur/proposals" className="selected">
              <Typography type="p1" text="Proposals" />
            </Link> */}
          </div>
          <div>
            {contractsArr.length === 0 ? (
              <div className="no-contracts">
                <Typography type="p3" text="There is no contract yet" />{" "}
              </div>
            ) : (
              contractsArr
            )}
          </div>
        </div>
      </div>
    </>
  );
};
