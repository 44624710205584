import {
  LinkedTab,
  AccountContainer,
  Navigation,
  NavigationItem,
} from "components";
import { Route } from "react-router-dom";
import { Projects } from "./Tabs/Projects/Projects";
import { Requirements } from "./Tabs/Requirements/Requirements";
import { Notes } from "./Tabs/Notes/Notes";
import { Documents } from "./Tabs/Documents/Documents";

export const AppliedProject = () => {
  const offerData = JSON.parse(`${localStorage.getItem("offer")}`);
  const offeredProject = JSON.parse(
    `${localStorage.getItem("offeredProject")}`
  );
  const userData = {
    image: offeredProject?.user?.image || "https://via.placeholder.com/130",
    name: offeredProject?.user?.name,
    country: offeredProject?.user?.country || "",
    application_name: offeredProject?.application_name,
    summary: offeredProject?.summary,
  };

  return (
    <>
      <LinkedTab
        tabs={[
          {
            name: "Offers",
            link: `/developers/${localStorage.getItem("userId")}/offers`,
          },
          { name: `${userData.application_name}` },
        ]}
        underline
      />
      <AccountContainer
        userImg={userData.image}
        user={userData.name}
        country={userData.country}
        title={userData.application_name}
        description={userData.summary}
      />
      <Navigation direction="horizontal">
        <NavigationItem
          label="Project Scope"
          to={`/developers/${localStorage.getItem("userId")}/offers/${
            offerData.id
          }/projects`}
        />
        <NavigationItem
          label="Notes"
          to={`/developers/${localStorage.getItem("userId")}/offers/${
            offerData.id
          }/notes`}
        />
        <NavigationItem
          label="Documents"
          to={`/developers/${localStorage.getItem("userId")}/offers/${
            offerData.id
          }/documents`}
        />
        <NavigationItem
          label="AI Requirement List"
          to={`/developers/${localStorage.getItem("userId")}/offers/${
            offerData.id
          }/requirements`}
        />
      </Navigation>

      <Route
        path={`/developers/${localStorage.getItem("userId")}/offers/${
          offerData.id
        }/projects`}
        element={<Projects />}
      />
      <Route
        path={`/developers/${localStorage.getItem("userId")}/offers/${
          offerData.id
        }/notes`}
        element={<Notes />}
      />
      <Route
        path={`/developers/${localStorage.getItem("userId")}/offers/${
          offerData.id
        }/documents`}
        element={<Documents />}
      />
      <Route
        path={`/developers/${localStorage.getItem("userId")}/offers/${
          offerData.id
        }/requirements`}
        element={<Requirements />}
      />
    </>
  );
};
