import { Link } from "react-router-dom";
import { Button, Typography } from "..";
import "./account.scss";

interface AccountContainerProps {
  user: string;
  userImg: string;
  country: string;
  title: string;
  description: string;
  shadow?: boolean;
  tab?: string;
  tabHref?: string;
  pin?: "success" | "warning" | "emergency";
  tabType?: "outlined" | "primary" | "initial" | "secondary";
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const AccountContainer = ({
  user,
  userImg,
  country,
  title,
  description,
  tab,
  tabHref,
  tabType,
  shadow = false,
  pin,
  onClick,
}: AccountContainerProps) => {
  return (
    <div className={`account-container ${shadow ? "shadow" : ""}`}>
      <div className="top-section">
        <div className="account-image">
          <img src={userImg} />
        </div>
        <div className="account-info">
          <div className="top">
            <div className="user-info">
              <Typography type="p3" text={user} weight="bold" />
              <Typography type="p3" text={country} />
            </div>
            {tab ? (
              <div className="tab">
                <Link to={`${tabHref}`}>
                  <Button onClick={onClick} type={tabType} label={`${tab}`} />
                </Link>
              </div>
            ) : null}
          </div>
          <div className="bottom">
            <div className="title">
              <Typography type="p1" text={title} weight="bold" />
              {pin ? <span className={`pin ${pin}`}></span> : null}
            </div>
            <Typography type="p5" text={description} />
          </div>
        </div>
      </div>
    </div>
  );
};
