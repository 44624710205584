import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { subscribePlan } from "state/actions";
import { clearState } from "state/reducers/user";
import { Typography } from "../Typography/Typography";
import { Loader } from "..";

import "./popup.scss";

const MONTHS = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
];
const YEARS = ["2022", "2023", "2024", "2025", "2026"];
const FREE_PLAN_ID = 1;
const UNLIMITED_PLAN_ID = 2;

export const Popup2 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const doSubscribePlan = subscribePlan as any;

  const userState = useSelector((state: any) => state.user);
  const {
    subscriptionErrors,
    subscriptionIsError,
    subscriptionIsSuccess,
    subscriptionStatus,
    subscriptionMessage,
  } = userState;

  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [showMonth, setShowMonth] = useState(false);
  const [showYear, setShowYear] = useState(false);
  const [loading, setLoading] = useState(false);

  const formRef = useRef() as any;
  const monthRef = useRef() as any;
  const yearRef = useRef() as any;

  const handleShowMonth = (e: any) => {
    e.target.contains(monthRef.current) && monthRef.current.contains(e.target)
      ? setShowMonth(true)
      : setShowMonth(false);
  };

  const handleShowYear = (e: any) => {
    e.target.contains(yearRef.current) && yearRef.current.contains(e.target)
      ? setShowYear(true)
      : setShowYear(false);
  };

  const getMonth = (e: any, m: string) => {
    e.preventDefault();
    setMonth(m);
    setShowMonth(false);
  };

  const getYear = (e: any, y: string) => {
    e.preventDefault();
    setYear(y);
    setShowYear(false);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const data = new FormData(formRef.current);
    const cardNo = data.get("card-no");
    const cardOwner = data.get("card-owner");
    const month = data.get("month");
    const year = data.get("year");
    const cvc = data.get("cvc");
    const cardData = {
      name: cardOwner,
      card: `${cardNo}`.split(" ").join(""),
      expire_month: month,
      expire_year: year,
      cvc: cvc,
    };
    dispatch(doSubscribePlan({ planID: UNLIMITED_PLAN_ID, cardData }));
    setLoading(true);
  };

  const letOnlyNumbers = (e: any) => {
    e.preventDefault();
    e.target.value = e.target.value
      .replace(/[^\dA-Z]/g, "")
      .replace(/(.{4})/g, "$1 ")
      .trim();
  };

  useEffect(() => {
    if (
      subscriptionIsError ||
      !subscriptionIsSuccess ||
      subscriptionErrors.length > 0
    ) {
      setLoading(false);
    }
    return () => {
      clearState();
    };
  }, [
    subscriptionIsError,
    subscriptionIsSuccess,
    setLoading,
    subscriptionErrors,
  ]);

  useEffect(() => {
    if (subscriptionIsSuccess) {
      navigate("/subscribe/success");
      setLoading(false);
    }
    return () => {
      clearState();
    };
  }, [subscriptionIsError, subscriptionIsSuccess]);

  useEffect(() => {
    document.addEventListener("click", handleShowMonth);
    document.addEventListener("click", handleShowYear);
    return () => {
      document.removeEventListener("click", handleShowMonth);
      document.removeEventListener("click", handleShowYear);
    };
  }, [handleShowMonth, handleShowYear]);

  return (
    <form ref={formRef} noValidate key={"popup-2"}>
      {loading && <Loader />}
      <div className="popup popup-2">
        <div className="left">
          <label>
            Card Number
            <input
              name="card-no"
              onInput={letOnlyNumbers}
              type="tel"
              inputMode="numeric"
              pattern="[0-9\s]{13,19}"
              autoComplete="off"
              maxLength={19}
              placeholder="_ _ _ _  _ _ _ _  _ _ _ _  _ _ _ _"
            />
            <span className={`error`}>
              <Typography type="p3" text={subscriptionErrors?.card} />
            </span>
          </label>
          <label>
            Name on Card
            <input type="text" name="card-owner" />
            <span className={`error`}>
              <Typography type="p3" text={subscriptionErrors?.name} />
            </span>
          </label>
          <p className="label" style={{ marginBottom: "10px" }}>
            Expiry Date
          </p>
          <div className="date-datalists">
            <label>
              <input
                ref={monthRef}
                readOnly
                name="month"
                type="text"
                placeholder="00"
                value={month}
              />
              <span className={`error`}>
                <Typography type="p3" text={subscriptionErrors?.expire_month} />
              </span>
              <div
                className="dropdown"
                style={{ display: showMonth ? "flex" : "none" }}
              >
                {MONTHS.map((m) => (
                  <span key={`${m}`} onClick={(e) => getMonth(e, m)}>
                    {m}
                  </span>
                ))}
              </div>
            </label>
            <label>
              <input
                ref={yearRef}
                readOnly
                name="year"
                type="text"
                placeholder="0000"
                value={year}
              />
              <span className={`error`}>
                <Typography type="p3" text={subscriptionErrors?.expire_year} />
              </span>
              <div
                className="dropdown"
                style={{ display: showYear ? "flex" : "none" }}
              >
                {YEARS.map((y) => (
                  <span key={`${y}`} onClick={(e) => getYear(e, y)}>
                    {y}
                  </span>
                ))}
              </div>
            </label>
          </div>
          <label>
            CVC
            <input
              type="text"
              className="security-code"
              name="cvc"
              inputMode="numeric"
              maxLength={3}
              pattern="[0-9\s]{13,19}"
              onInput={letOnlyNumbers}
            />
            <span className={`error`}>
              <Typography type="p3" text={subscriptionErrors?.cvc} />
            </span>
          </label>
        </div>
        <div className="right">
          <Typography
            type="h3"
            text="Unlimited Premium Package"
            weight="bold"
          />
          <Typography
            type="p3"
            text="Get instant access to hundreds of projects and place offers on any one you like!"
          />
          <button onClick={handleSubmit} type="button">
            <Typography type="p1" text="Complete Payment" />
          </button>
          {typeof subscriptionErrors[0] === "string" ||
          subscriptionMessage === "User is already subscribed to the plan" ? (
            <span className={`error`}>
              <Typography type="p3" text={subscriptionMessage} />
              <Typography type="p3" text={subscriptionErrors[0]} />
            </span>
          ) : null}
        </div>
      </div>
    </form>
  );
};
