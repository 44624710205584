import { useEffect, useState } from "react";
import { Typography, Icon } from "components";
import { replyToQuestion } from "services";

export const SingleQuestions = ({
  question,
  getAllQuestions,
  featureId,
  setUpdateReply,
  updateReply,
  setIsLoading,
}: any) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [reply, setReply] = useState("");

  const handleChange = (e: any) => {
    setReply(e.target.value);
  };

  const handleSubmit = (e: any, id: any) => {
    replyToQuestion(id, reply);
    setReply("");
    setUpdateReply(true);
    setIsLoading(true);
  };

  useEffect(() => {
    getAllQuestions(featureId);
  }, [updateReply]);

  return (
    <>
      <div className="main-box">
        <div
          className={`question-info ${isCollapsed ? "collapsed" : "expanded"}`}
        >
          {question.user.image ? (
            <img src={question.user.image} />
          ) : (
            <img src={"/images/account.png"} />
          )}
          <div className="info">
            <Typography type="p4" text={question.user.name} />
            <Typography type="p5" text={question.question} />
            <div>
              <span>{question.sent_date}</span>
              <div
                className="reply-icon"
                onClick={() => setIsCollapsed(!isCollapsed)}
              >
                <Icon icon="chevron-down" />
              </div>
            </div>
          </div>

          {/* <button onClick={() => setIsCollapsed(!isCollapsed)}>View replies</button> */}
        </div>
        <div
          className={`collapse-content ${
            isCollapsed ? "collapsed" : "expanded"
          }`}
        >
          <Typography type="p4" text="Replies" />
          {question.replies.map((reply: any) => {
            return (
              <div
                className={`replies ${
                  reply.user.type === "developer" ? "developer" : "customer"
                }`}
                key={reply.id}
              >
                {reply.user.image ? (
                  <img src={reply.user.image} />
                ) : (
                  <img src={"/images/account.png"} />
                )}
                <div className="reply-info">
                  <Typography type="p4" text={reply.user.name} />
                  <Typography type="p5" text={reply.reply} />
                  <span>{reply.sent_date}</span>
                </div>
              </div>
            );
          })}
          <div>
            <div className="reply-form">
              {question.user.image ? (
                <img src={question.user.image} />
              ) : (
                <img src={"/images/account.png"} />
              )}
              <input
                placeholder="reply"
                onChange={handleChange}
                value={reply}
              />
              <button
                onClick={(e) => {
                  handleSubmit(e, question.id);
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
