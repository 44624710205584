import React from "react";
import { Link } from "react-router-dom";
import { AccountContainer, Icon, LinkedTab } from "components";
import { useSelector, useDispatch } from "react-redux";
import { getProjectById, getProjects } from "state/actions";

export const Projects = () => {
  const dispatch = useDispatch();
  const doProjects = getProjects as any;
  const doGetProjectById = getProjectById as any;
  const offersState = useSelector((state: any) => state.offers);
  const { offers } = offersState;
  const reversedOffers = [].concat(offers).reverse();
  const projectsState = useSelector((state: any) => state.projects);
  const { projects, isSuccess, isError } = projectsState;

  React.useEffect(() => {
    dispatch(doGetProjectById(localStorage.getItem("projectById")));
    dispatch(doProjects());
  }, [isSuccess, isError]);
  return (
    <div className="offer-list">
      <LinkedTab tabs={[{ name: "Offers" }]} />
      {reversedOffers.map((offer: any, index: any) => {
        const offeredProject = projects.find(
          (project: any) => project.id === offer.project_id
        );

        const userData = {
          image:
            offeredProject?.user?.image || "https://via.placeholder.com/130",
          name: offeredProject?.user?.name,
          country: offeredProject?.user?.country || "",
          application_name: offeredProject?.application_name,
          summary: offeredProject?.summary,
        };

        return (
          <Link
            onClick={() => {
              localStorage.setItem(
                "offeredProject",
                JSON.stringify(offeredProject)
              );
              localStorage.setItem("offer", JSON.stringify(offer));
            }}
            to={`/developers/${localStorage.getItem("userId")}/offers/${
              offer.id
            }/projects`}
          >
            <AccountContainer
              userImg={userData.image}
              user={userData.name}
              country={userData.country}
              title={userData.application_name}
              description={userData.summary}
              pin={"warning"}
              shadow
            />
          </Link>
        );
      })}
    </div>
  );
};
