import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button,
  Header,
  Icon,
  ProgressBar,
  Typography,
  DetailsItem,
  AddItem,
} from "components";

export const GiveDetails = (props: any) => {
  const { data, next, back, text } = props;
  const homeUrl = process.env.REACT_APP_DOMAIN;

  const [selectedFeatures, setSelectedFeatures] = useState(
    data.SelectedFeatures
  );
  const [addNewFeatId, setAddNewFeatId] = useState(1000);

  const addNewFeature = (newFeature: Object) => {
    setAddNewFeatId(addNewFeatId + 1);
    setSelectedFeatures([...selectedFeatures, newFeature]);
  };
  useEffect(() => {
    const newArray = selectedFeatures.map(
      ({ recommended_features, image_url, ...selectedFeatures }: any) =>
        selectedFeatures
    );
    data.SelectedFeatures = newArray;
  });

  const userNameState = useSelector((state: any) => state.auth.name);

  const isUserExist =
    userNameState?.length || localStorage.getItem("userId")?.length;

  const entrepreneurLink = `/entrepreneur/${localStorage.getItem(
    "userId"
  )}/feed`;

  return (
    <div className="give-details">
      <Header
        shadow
        logolink={isUserExist ? entrepreneurLink : homeUrl}
      >
        <div className="contentHeaderWrapper">
          <ProgressBar value={54} />
          {isUserExist ? (
            <Link to={entrepreneurLink}>
              <Icon icon="close" />
            </Link>
          ) : (
            <a href={homeUrl}>
              <Icon icon="close" />
            </a>
          )}
        </div>
      </Header>

      <div className="content-container">
        <Typography text="3/5" type="p2" weight="bold" />
        <Typography text={text.header} type="h2" weight="bold" />
        <div className="step3-desc">
          <Typography type="p5" text={data.description} />
        </div>

        <div className={`details-container`}>
          {selectedFeatures.map((element: any, index: number) => (
            <DetailsItem
              key={index}
              data={element}
              selectedFeatures={selectedFeatures}
              setSelectedFeatures={setSelectedFeatures}
            />
          ))}
          <AddItem
            data={data}
            addNewFeature={addNewFeature}
            featId={addNewFeatId}
          />
        </div>
        <div className="content-footer">
          <Button label="Back" type="outlined" onClick={back} />
          <Button
            label="Next"
            rightIcon="chevron-right"
            type="primary"
            onClick={next}
          />
        </div>
      </div>
    </div>
  );
};

export default GiveDetails;
