import { useNavigate } from "react-router-dom";
import { Typography } from "../Typography/Typography";
import "./popup.scss";

export const Popup3 = () => {
  const navigate = useNavigate();
  return (
    <div className="popup popup-3">
      <Typography
        type="h3"
        text="Payment has been successfully performed!"
        weight="bold"
      />
      <img src="/images/congrats.png" />
      <Typography type="p1" text="Your offer has been sent for the project." />
      <button
        onClick={() =>
          navigate(
            `/developers/${localStorage.getItem("userId")}/feed/projects`
          )
        }
      >
        <Typography type="p1" text="Complete" />
      </button>
    </div>
  );
};
