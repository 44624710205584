import "./input.scss";

interface FormProps {
  children: any;
  onSubmit?: (event: React.SyntheticEvent | any) => void;
}
export const Form = ({ children, onSubmit }: FormProps) => {
  return (
    <form className="form" onSubmit={onSubmit}>
      {children}
    </form>
  );
};
