import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Typography, Loader } from "components";
import { HeaderEnt } from "../HeaderEnt/HeaderEnt";
import { ProposalDetails } from "../ProposalDetails/ProposalDetails";
import { CoverLetter, Questions } from "./Components/index";
import "./single-proposal.scss";
import { getProject, getOffer } from "services";
import { useSelector } from "react-redux";

export const SingleProposal = () => {
  const proposalsList = useSelector((state: any) => state.entData.offers);

  let singleProp;
  if (proposalsList) {
    singleProp = proposalsList.find(
      (prop: any) =>
        JSON.stringify(prop.id) === localStorage.getItem("entProposalId")
    );
  }

  const [component, setComponent] = useState("questions");
  const [features, setFeatures] = useState([]);
  const [offer, seOffer] = useState();

  const getProjectInfo = async (projectId: any) => {
    const projectInfo = await getProject(projectId);
    await setFeatures(projectInfo.project.features);
  };

  const getOfferInfo = async (offerId: any) => {
    const offerInfo = await getOffer(offerId);
    await seOffer(offerInfo.offer);
  };

  useEffect(() => {
    getProjectInfo(localStorage.getItem("entProjectId"));
    getOfferInfo(localStorage.getItem("entProposalId"));
  }, []);

  return (
    <>
      {!offer ? (
        <Loader />
      ) : (
        <div className="singleProposal">
          <HeaderEnt />
          <div className="main">
            <div className="titles">
              <Link to={`/entrepreneur/${localStorage.getItem("userId")}/feed`}>
                <Typography type="p1" text="My Products" />
              </Link>
              <div className="selected">
                <Typography type="p1" text="Proposals" />
              </div>
            </div>
            <div className="container">
              {offer && <ProposalDetails proposalsPage={false} item={offer} />}
              <div className="ent-nav">
                <button
                  onClick={() => setComponent("cover-letter")}
                  className={component === "cover-letter" ? "selected" : ""}
                >
                  Cover Letter
                </button>
                <button
                  onClick={() => setComponent("information")}
                  className={component === "information" ? "selected" : ""}
                >
                  Information
                </button>
                <button
                  onClick={() => setComponent("all-works")}
                  className={component === "all-works" ? "selected" : ""}
                >
                  All Works
                </button>
                <button
                  onClick={() => {
                    setComponent("questions");
                  }}
                  className={component === "questions" ? "selected" : ""}
                >
                  Questions
                </button>
              </div>
              <hr />
              <div>
                {component === "questions" && <Questions features={features} />}
                {component === "cover-letter" && <CoverLetter offer={offer} />}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
