import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  LinkedTab,
  AccountContainer,
  Navigation,
  NavigationItem,
  Typography,
} from "components";
import { Projects } from "./Tabs/Projects/Projects";
import { Requirements } from "./Tabs/Requirements/Requirements";
import { getProjectById, getProjects } from "state/actions";
import { Notes } from "./Tabs/Notes/Notes";

export const ProjectToApply = () => {
  const projectsState = useSelector((state: any) => state.projects);
  const dispatch = useDispatch();

  const { projects, isSuccess, isError } = projectsState;
  const doProjects = getProjects as any;

  const doGetProjectById = getProjectById as any;
  const findprojectById = projects.find(
    (p: any) => p.id == localStorage.getItem("projectById")
  );
  const location = useLocation();
  const linkedTab = location.pathname.includes("requirements")
    ? "Requirements List"
    : "";

  React.useEffect(() => {
    dispatch(doGetProjectById(localStorage.getItem("projectById")));
    dispatch(doProjects());
  }, [isSuccess, isError]);

  const documentsDecoyStyling = {
    cursor: "not-allowed",
    marginRight: "4.3rem",
    // whiteSpace: "nowrap",
    color: "var(--unnamed-color-a3a3a3)",
    paddingBottom: "10px",
    font: "normal normal normal 1.3rem Lato",
    fontWeight: "bold",
  };

  return (
    <>
      <LinkedTab
        tabs={[
          {
            name: `${findprojectById && findprojectById.application_name}`,
            link: `/developers/${localStorage.getItem(
              "userId"
            )}/feed/${localStorage.getItem("projectById")}/projects`,
          },
          { name: `${linkedTab}` },
        ]}
        underline={Boolean(linkedTab)}
      />
      <AccountContainer
        userImg={
          findprojectById?.user?.image || "https://via.placeholder.com/130"
        }
        user={`${findprojectById?.user?.name}`}
        country=""
        title={`${findprojectById?.application_name}`}
        description={findprojectById?.summary}
        tab="Get AI Requirement List"
        tabHref={`/developers/${localStorage.getItem(
          "userId"
        )}/feed/${localStorage.getItem("projectById")}/requirements`}
        tabType="primary"
        // pin="warning"
      />
      <Navigation direction="horizontal">
        <NavigationItem
          label="Project Scope"
          to={`/developers/${localStorage.getItem(
            "userId"
          )}/feed/${localStorage.getItem("projectById")}/projects`}
        />
        {findprojectById?.notes?.length ? (
          <NavigationItem
            label="Notes"
            to={`/developers/${localStorage.getItem(
              "userId"
            )}/feed/${localStorage.getItem("projectById")}/notes`}
          />
        ) : (
          <p style={documentsDecoyStyling}>Notes</p>
        )}
        {/* <NavigationItem
          label="Documents"
          to={`/developers/${localStorage.getItem(
            "userId"
          )}/feed/${localStorage.getItem("projectById")}/documents`}
        /> */}
        <p style={documentsDecoyStyling}>Documents</p>
        <NavigationItem
          label="AI Requirement List"
          to={`/developers/${localStorage.getItem(
            "userId"
          )}/feed/${localStorage.getItem("projectById")}/requirements`}
        />
      </Navigation>
      {/* <Redirect
        from={`/developers/${localStorage.getItem(
          "userId"
        )}/feed/${localStorage.getItem("projectById")}/projects`}
        to={`/developers/${localStorage.getItem(
          "userId"
        )}/feed/${localStorage.getItem("projectById")}/projects/General%20Information`}
      /> */}
      <Routes>
        <Route
          path={`/developers/${localStorage.getItem(
            "userId"
          )}/feed/${localStorage.getItem("projectById")}/projects`}
          element={<Projects />}
        />
        <Route
          path={`/developers/${localStorage.getItem(
            "userId"
          )}/feed/${localStorage.getItem("projectById")}/notes`}
          element={<Notes />}
        />
        <Route
          path={`/developers/${localStorage.getItem(
            "userId"
          )}/feed/${localStorage.getItem("projectById")}/requirements`}
          element={<Requirements />}
        />
      </Routes>
    </>
  );
};
