import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProjects } from "state/actions";
import { getProjectToApply } from "state/reducers/projects";
// import { goToUrl } from "helpers/goToUrl";
import {
  Header,
  Button,
  Typography,
  ProjectCard,
  ProjectsContainer,
} from "components";
import "./projects.scss";

export const Projects = () => {
  // const [projects,setProjects]=React.useState()

  const projectsState = useSelector((state: any) => state.projects);
  const authState = useSelector((state: any) => state.auth);
  const { user } = authState;
  const { projects } = projectsState;
  const dispatch = useDispatch();
  const doProjects = getProjects as any;

  const navigate = useNavigate();
  const handleClick: any = (project_id: any) => {
    localStorage.setItem("projectById", project_id);
    dispatch(getProjectToApply({ id: project_id }));
    localStorage.getItem("token")
      ? // && JSON.parse(localStorage.getItem("user") as any).type === "developer"
        navigate(
          `developers/${localStorage.getItem(
            "userId"
          )}/feed/${localStorage.getItem("projectById")}/projects`
        )
      : navigate("/dev-signup");
  };
  React.useEffect(() => {
    dispatch(doProjects());
  }, []);

  return (
    <>
      <Header>
        <div className={`tabs`}>
          <Link to="/dev-login">
            <Button label="Log in" type="outlined" />
          </Link>
          <Link to="/dev-signup">
            <Button label="Sign up" type="primary" />
          </Link>
        </div>
      </Header>
      <Typography type="h3" text="Projects" weight="bold" />
      <ProjectsContainer>
        {projects &&
          projects.map((project: any) => (
            <ProjectCard
              title={project.application_name}
              description={project.description}
              isBlurred={false}
              onClick={() => handleClick(project.id)}
              projectId={project.id}
            />
          ))}
        {Array(6)
          .fill(0)
          .map((el, ind) => (
            <ProjectCard
              title="Dating App"
              description="Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus."
              isBlurred={true}
              onClick={handleClick}
              projectId={`blurred-${ind}`}
            />
          ))}
        {/*  */}
      </ProjectsContainer>
    </>
  );
};
