import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  AccountContainer,
  Button,
  Icon,
  LinkedTab,
  Typography,
} from "components";
import "./congratulations.scss";

export const Congratulations = () => {
  const navigate = useNavigate();

  const projectsState = useSelector((state: any) => state.projects);

  const dispatch = useDispatch();

  const { projects, isSuccess, isError } = projectsState;

  const findprojectById = projects.find(
    (p: any) => p.id == localStorage.getItem("projectById")
  );

  const handleClick = () => {
    localStorage.getItem("token")
      ? navigate(
          `/developers/${localStorage.getItem("userId")}/feed/projects`
        )
      : navigate("/dev-signup");
  };
  return (
    <>
      <LinkedTab
        tabs={[
          { name: `${findprojectById?.application_name}` },
          { name: `${`  `} ` },
        ]}
      />
      <div className="congratulations">
        <AccountContainer
          userImg={`${
            findprojectById?.user?.image || "https://via.placeholder.com/130"
          }`}
          user={`${findprojectById?.user?.name}`}
          country="London"
          title={`${findprojectById?.application_name}`}
          description={findprojectById?.summary}
        />
        <div className="group">
          <Typography type="h3" text="Congratulations!" weight="bold" />
          <Icon icon="ok" />
        </div>
        <Button
          type="primary"
          label="See Other Projects"
          size="2"
          onClick={handleClick}
        />
      </div>
    </>
  );
};
