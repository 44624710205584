import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Typography, Loader } from "components";
import "./allProposal.scss";
import { HeaderEnt } from "../HeaderEnt/HeaderEnt";
import { ProposalDetails } from "../ProposalDetails/ProposalDetails";
import { getOffers } from "services";

export const AllProposals = () => {
  const [offers, setOffers] = useState([]);
  const [didMount, setDidMount] = useState(false);

  const getAllOffers = async () => {
    const result = await getOffers();
    // console.log('result', result)
    await setOffers(result.offers);
  };

  useEffect(() => {
    setDidMount(true);
    return () => setDidMount(false);
  }, []);

  useEffect(() => {
    getAllOffers();
  }, [didMount]);

  return (
    <>
      {!offers ? (
        <Loader />
      ) : (
        <div className="proposals-page">
          <HeaderEnt />

          <div className="main">
            <div className="titles">
              <Link to={`/entrepreneur/${localStorage.getItem("userId")}/feed`}>
                <Typography type="p1" text="My Products" />
              </Link>
              <div className="selected">
                <Typography type="p1" text="Proposals" />
              </div>
            </div>

            {offers &&
              offers.map((prop: any) => (
                <div
                  onClick={() => {
                    localStorage.setItem("entProposalId", prop.id);
                    localStorage.setItem("entProjectId", prop.project_id);
                  }}
                  key={prop.id}
                >
                  <ProposalDetails proposalsPage={true} item={prop} />{" "}
                </div>
              ))}
            {offers && offers?.length === 0 ? (
              <div className="no-proposal">
                <Typography type="p3" text="There are no proposals yet" />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </>
  );
};
