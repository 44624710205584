import { Link } from "react-router-dom";
import { Typography, Button } from "../index";
import "./tabs.scss";

interface TabsProps {}
export const Tabs = ({}: TabsProps) => {
  return (
    <div className={`tabsHomepage`}>
      <Link to={{ pathname: "https://smartup.network/blog" }} target="_blank">
        <Typography type="p3" text="Blog" />
      </Link>
      {/* <Link to="/plans">
        <Typography type="p3" text="Plans" />
      </Link> */}
      <Link
        to={{ pathname: "/ent-login" }}
        target="_parent"
      >
        <Button label="Log in" type="outlined" />
      </Link>
      <Link
        to={{ pathname: "/ent-signup" }}
        target="_parent"
      >
        <Button label="Sign up" type="primary" />
      </Link>
    </div>
  );
};
