import React from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getPlans, subscribePlan } from "state/actions";
import {
  Button,
  Header,
  Tabs,
  Typography,
  Icon,
  Loader,
} from "components";
import { useAxios } from "services/useAxios";
import "./pricing.scss";

export const Pricing = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const plansState = useSelector((state: any) => state.plans);
  const { plans, isSuccess, isError, message } = plansState;
  const freePlan = plans && plans.find((p: any) => p.slug === "free");
  const unlimitedPlan =
    plans && plans.find((p: any) => p.slug === "unlimitted");

  const doPlans = getPlans as any;
  const doSubscribePlan = subscribePlan as any;

  // TODO subcribing plan needs cardData
  const handleSubscribe = (planID: any) => {
    // if (localStorage.getItem("token")) {
    //   return dispatch(doSubscribePlan({ planID }));
    // }
    // return navigate("/dev-login");
  };

  React.useEffect(() => {
    dispatch(doPlans());
  }, [isSuccess, isError]);

  const { error, isLoading, getPageText, response } = useAxios();

  React.useEffect(() => {
    getPageText("plans");
  }, []);

  return (
    <div>
      <Header>
        <Tabs />
      </Header>
      <div className="pricing-page">
        {isLoading && <Loader />}
        {response && (
          <div>
            <div className="group">
              <div className="text">
                <Typography type="h1" text={response.page.blocks[0].header} />
                <Typography type="p2" text={response.page.blocks[0].content} />
              </div>
              <img
                src={response.page.blocks[0].image_url}
                className="mainImg"
              />
            </div>
            <div className="text">
              <Typography type="h1" text={response.page.blocks[1].header} />
              <Typography type="p2" text={response.page.blocks[2].content} />
            </div>{" "}
          </div>
        )}
        <div className="plans-values">
          <div className="plans">
            <table className="plans-table">
              <thead>
                <tr>
                  <td>
                    <Typography type="p1" text="Pick a Plan" weight="bold" />
                  </td>
                  <td>
                    <Typography type="p1" text="Free" />
                    <p className="price">
                      <b>$0 </b>
                      <i> /</i> Mo
                    </p>
                    <Button
                      type="outlined"
                      label="Start with free"
                      onClick={() => handleSubscribe(freePlan.id)}
                    />
                  </td>
                  <td>
                    <Typography type="p1" text="Unlimited" />
                    <p className="price">
                      <b>$0 </b>
                      <i> /</i> Mo
                    </p>
                    <Button
                      type="primary"
                      label="Start with unlimited"
                      onClick={() => handleSubscribe(unlimitedPlan.id)}
                    />
                  </td>
                </tr>
              </thead>
              <tbody>
                {freePlan &&
                  freePlan.features.map((f: any) => (
                    <tr key={f.slug + "f"}>
                      <td>
                        <Typography type="p3" text={f.description} />
                      </td>
                      <td>
                        <Icon icon="tick" />
                      </td>
                      <td>{/* <Icon icon="tick" /> */}</td>
                    </tr>
                  ))}

                {unlimitedPlan &&
                  unlimitedPlan.features.map((f: any) => (
                    <tr key={f.slug + "u"}>
                      <td>
                        <Typography type="p3" text={f.description} />
                      </td>
                      <td>{/* <Icon icon="tick" /> */}</td>
                      <td>
                        <Icon icon="tick" />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          {response && (
            <div className="values">
              <div className="values-item">
                <Icon icon="person" />
                <Typography
                  type="p1"
                  text={response.page.blocks[2].header}
                  weight="bold"
                />
                <Typography type="p3" text={response.page.blocks[2].content} />
              </div>
              <div className="values-item">
                <Icon icon="list-alt" />
                <Typography
                  type="p1"
                  text={response.page.blocks[3].header}
                  weight="bold"
                />
                <Typography type="p3" text={response.page.blocks[3].content} />
              </div>
              <div className="values-item">
                <Icon icon="dashboard" />
                <Typography
                  type="p1"
                  text={response.page.blocks[4].header}
                  weight="bold"
                />
                <Typography type="p3" text={response.page.blocks[4].content} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
