import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import API_PATHS from "services/apiPaths";
import STORAGE_KEYS from "services/storageKeys";

let headers = {
  Authorization: `Bearer ${localStorage.getItem("token")}`,
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const getQuestions = createAsyncThunk(
  "questions/getQuestions",
  async (feature_id, thunkAPI) => {
    try {
      const response = await axios.get(API_PATHS.question.get_questions(), {headers: headers});
      
      return response.data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

//TODO What is searchParams?
export const getQuestionsByFeatureId = createAsyncThunk(
  "questions/getQuestionsByFeatureId",
  async (feature_id: string, thunkAPI) => {
    try {
      const url = new URL("https://v2-services.app-cart.io/api/v2/questions");

      let params: { [key: string]: string } = {
        feature: feature_id,
      };
      
      Object.keys(params).forEach((key) =>
        url.searchParams.append(key, params[key])
      );

      const response = await axios.get(API_PATHS.question.get_questions(), {headers: headers});
      
      return { feature_id: feature_id, data: response.data };
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
interface createQuestionDataTypes {
  feature_id: string;
  question: string;
};

export const createQuestionByFeatureId = createAsyncThunk(
  "questions/createQuestionByFeatureId",
  async ({ feature_id, question }: createQuestionDataTypes, thunkAPI) => {
    try {
      let body = {
        question: question,
      };

      const response = await axios.post(API_PATHS.question.post_create(feature_id),{ data: body, headers: headers });
      
      return response.data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
