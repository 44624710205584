import { useState } from "react";
import { Navigate, Route, useNavigate } from "react-router-dom";

import { Icon, Popup1, Popup2, Popup3 } from "..";
import "./popup.scss";

interface PopupContainerProps {
  hide: boolean;
}

export const PopupContainer = ({ hide }: PopupContainerProps) => {
  const [toggleHide, setToggleHide] = useState(hide);
  const navigate = useNavigate();

  const handleHide = (e: any) => {
    setToggleHide(true);
    navigate(`/developers/${localStorage.getItem("userId")}/feed/projects`);
  };

  return (
    <div className={`popup-container ${toggleHide ? "hide" : ""}`}>
      <div className={`bg-blurred`}>
        <span className="close-icon" onClick={handleHide}>
          <Icon icon="close" />
        </span>
      </div>
      {/* <Redirect from="/subcribe" to={"/subscribe/info"} /> */}
      <Route path={"/subscribe/info"} element={<Popup1/>} />
      <Route path={"/subscribe"} element={<Navigate replace to="subscribe/info" />} />
      <Route path={"/subscribe/payment"} element={<Popup2/>} />
      <Route path={"/subscribe/success"} element={<Popup3/>} />
    </div>
  );
};
