import axios, { InternalAxiosRequestConfig } from "axios";
import STORAGE_KEYS from "./storageKeys";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE;

axios.interceptors.request.use(
  (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {

    // console.log('axios request sent')
    
    const token = localStorage.getItem(STORAGE_KEYS.token);

    const { headers } = config;

    headers.Authorization = `Bearer ${token}`;

    return config;
  }
);