import { Home } from "./Home/Home";
import { Projects } from "./Projects/Projects";
import { DevLogin } from "./Authentication/DevLogin";
import { DevSignup } from "./Authentication/DevSignup";
import { Developer } from "./Developer/Developer";
import { NewProjectReq } from "./NewProjectReq";
import { EntLogin } from "./Authentication/EntLogin";
import { EntSignup } from "./Authentication/EntSignup";
import { Entrepreneur } from "./Entrepreneur/Entrepreneur";
import { Pricing } from "./Pricing/Pricing";
import { Popups } from "./Popups/Popups";
import AiLoader from "./AiLoader";

export {
  Home,
  Projects,
  DevLogin,
  DevSignup,
  Developer,
  NewProjectReq,
  EntLogin,
  EntSignup,
  Entrepreneur,
  Pricing,
  Popups,
  AiLoader
};
