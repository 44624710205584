import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Icon, Typography } from "components";
import { updateUserImage, updateUserInfo } from "state/actions";
import { HeaderEnt } from "../HeaderEnt/HeaderEnt";
import "./entsettings.scss";
import { logoutUser } from "state/actions";
import ChangePhoto from "images/change-photo.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

export const EntSettings = () => {
  const userState = useSelector((state: any) => state.user);
  const { user } = userState;
  const dispatch = useDispatch();
  const doUpdateUserInfo = updateUserInfo as any;
  const doUpdateUserImage = updateUserImage as any;
  const doLogout = logoutUser as any;
  const inputRef = useRef() as any;
  const [userForm, setUserForm] = useState({
    name: "",
    phone: "",
  });
  const [errorPhoneCode, setErrorPhoneCode] = useState(false);
  const [errorName, setErrorName] = useState(false);
  const [phone, setPhone] = useState("");
  const [profileImage, setProfileImage] = useState(user.image);

  useEffect(() => {
    setProfileImage(user.image);
  }, [user]);

  const handleChange = (event: any) => {
    setUserForm({
      ...userForm,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (phone.length) {
      setErrorPhoneCode(false);
      if (userForm.name.length) {
        setErrorName(false);
        dispatch(
          doUpdateUserInfo({
            name: userForm.name || userState.user.name,
            phone: `+${phone}`,
          })
        );
      } else {
        setErrorName(false);
        dispatch(
          doUpdateUserInfo({
            name: userState.user.name,
            phone: `+${phone}`,
          })
        );
      }
    } else {
      dispatch(
        doUpdateUserInfo({
          name: userState.user.name,
          phone: userState.user.phone,
        })
      );
    }
  };

  const handleUploadImage = (e: any) => {
    const file = e.target.files[0];
    const img = new FormData();
    img.append("image", file);
    dispatch(doUpdateUserImage({ image: img }));
    setProfileImage(URL.createObjectURL(e.target.files[0]));
  };

  const handleLogout = () => {
    dispatch(doLogout());
  };

  return (
    <>
      <style>
        {`
            html, body {
                height: 100vh;
                background-color: #f8f9fa;
            }
        `}
      </style>
      <HeaderEnt />
      <div className="settings">
        <span className="settings-title">Settings</span>
        <div className="settings-card-wrapper">
          <div className="settings-card-1">
            <div className="settings-card-inside">
              <span className="settings-card-title">Account</span>
              <div className="avatar">
                <label className="image-section">
                  <div className="no-person">
                    {user.image ? (
                      <img
                        width="106"
                        height="106"
                        src={profileImage}
                        className="user-image"
                      />
                    ) : (
                      <Icon icon="person" />
                    )}
                  </div>
                  <img src={ChangePhoto} alt="" className="plus-sign" />
                  <input
                    onChange={handleUploadImage}
                    type="file"
                    name="image"
                    accept="image/*"
                    ref={inputRef}
                  />
                </label>
              </div>
              <div className="settings-card-1-bottomArea">
                <Typography type="p3" text={`Name: ${userState.user.name}`} />
                <Typography type="p3" text={`Mail: ${userState.user.email}`} />
                {userState.user.phone ? (
                  <Typography
                    type="p3"
                    text={`Phone: ${userState.user.phone}`}
                  />
                ) : null}
              </div>
              <div
                className="settings-logout-inCard"
                onClick={() => handleLogout()}
              >
                <Icon icon={"exit"} />
                <Typography type="p3" text={"Log out"} />
              </div>
            </div>
          </div>

          <div className="settings-card-2">
            <div className="settings-card-inside">
              <span className="settings-card-title">Contact Information</span>
              <div>
                <div className="settings-2-sections">
                  <span className="settings-2-sections-span">Contact Name</span>
                  <input
                    placeholder={userState.user.name}
                    type="text"
                    name="name"
                    value={userForm.name}
                    onChange={(e) => handleChange(e)}
                    required
                    className="settings-2-sections-input-general settings-2-sections-input-name"
                  />
                  {errorName && (
                    <p className="errorPhone">This field is required.</p>
                  )}
                </div>

                <div className="settings-2-sections">
                  <span className="settings-2-sections-span">Contact Mail</span>
                  <input
                    readOnly={true}
                    autoComplete="none"
                    placeholder={userState.user.email}
                    type="text"
                    name="email"
                    onChange={(e) => handleChange(e)}
                    required
                    className="settings-2-sections-input-general settings-2-sections-input-mail"
                  />
                </div>

                <div className="telephone settings-2-sections">
                  <span className="settings-2-sections-span">
                    Contact Phone
                  </span>
                  <div className="customPhoneWrapper">
                    <PhoneInput
                      country={"us"}
                      enableSearch={true}
                      value={phone}
                      onChange={(phone) => setPhone(phone)}
                      containerClass=""
                    />
                  </div>
                  {errorPhoneCode && (
                    <p className="errorPhone">This field is required.</p>
                  )}
                </div>
              </div>
              <Button
                type="primary"
                label="Save"
                onClick={(e) => {
                  handleSubmit(e);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
