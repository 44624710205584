import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import API_PATHS from "services/apiPaths";

export const getFeatures = createAsyncThunk(
  "features/getFeatures",
  async (data, thunkAPI) => {
    
    try {
      let response = await axios.get(API_PATHS.feature.main_features);

      return response.data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
