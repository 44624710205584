import React, { useEffect, useRef } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { clearState } from "state/reducers/auth";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser, getUserInfo } from "state/actions";
import {
  Header,
  Typography,
  AccountDropdown,
  AccountDropdownItem,
} from "components";
import { Feed, Offers, Settings } from "./pages/index";
import "./developer.scss";
import { Projects as DeveloperProjects } from "./pages/Feed/Projects/Projects";
import { Projects as OfferProjects } from "./pages/Offers/Projects/Projects";

export const Developer = () => {
  const ref = useRef() as any;
  const navigate = useNavigate();

  const [hide, setHide] = React.useState(true);
  const dispatch = useDispatch();
  const authState = useSelector((state: any) => state.auth);
  const userState = useSelector((state: any) => state.user);
  const doGetUserInfo = getUserInfo as any;

  const doLogout = logoutUser as any;
  const { isSuccess } = authState;
  const { imageIsError, imageIsSuccess, user } = userState;
  const userFromLS = localStorage.getItem("user") as any;
  const userObj = JSON.parse(userFromLS);
  const logolink = localStorage.getItem("token")
    ? `/developers/${userObj.id}/feed/projects`
    : "/";

  const logoutState = useSelector((state: any) => state.auth);

  useEffect(() => {
    if (logoutState?.message === "User is logged out.") {
      localStorage.clear();
      navigate("/");
    }
    return () => {
      dispatch(clearState());
    };
  }, [logoutState?.message]);

  const handleToggleHide = (e: any) => {
    e.stopPropagation();

    e.preventDefault();
    setHide((preS) => !preS);
  };
  const handleHide = (e: any) => {
    // e.preventDefault();
    setHide(true);
  };

  const handleLogout = () => {
    dispatch(doLogout());
  };
  React.useEffect(() => {
    dispatch(doGetUserInfo());
    return () => {
      dispatch(clearState());
    };
  }, []);
  React.useEffect(() => {
    document.addEventListener("click", handleHide);
    return () => document.removeEventListener("click", handleHide);
  }, [isSuccess, user]);
  return (
    <>
      <Header logolink={logolink}>
        <div ref={ref} className={`tabs`} onClick={handleToggleHide}>
          <Typography type="p3" text={`${localStorage.getItem("userName")}`} />
          <img
            style={{ borderRadius: "50%" }}
            src={user?.image || "/images/account.png"}
            width="68"
            height="68"
          />
        </div>
        <AccountDropdown hide={hide}>
          <AccountDropdownItem
            icon="list-alt"
            text="Feed"
            href={`/developers/${localStorage.getItem("userId")}/feed/projects`}
          />
          <AccountDropdownItem
            icon="dashboard"
            text="Offers"
            href={`/developers/${localStorage.getItem("userId")}/offers/projects`}
          />
          <AccountDropdownItem
            icon="setting"
            text="Settings"
            href={`/developers/${localStorage.getItem("userId")}/settings`}
          />
          <AccountDropdownItem
            icon="exit"
            text="Log out"
            onClick={handleLogout}
            href={`/`}
          />
        </AccountDropdown>
      </Header>
      <div className="background-common">
        <Routes>
          <Route
            path={`${localStorage.getItem("userId")}/feed/projects`}
            element={<DeveloperProjects />}
          />
          <Route
            path={`${localStorage.getItem("userId")}/offers/projects`}
            element={<OfferProjects />}
          />
          <Route
            path={`${localStorage.getItem("userId")}/settings`}
            element={<Settings />}
          />
        </Routes>
      </div>
    </>
  );
};
