import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getProjectById, saveOffer } from "state/actions";
import { clearState } from "state/reducers/offers";
import { Button, Input, Typography } from "components";
import "./requirements.scss";

export const Requirements = () => {
  const [calculatingState, setCalculatingState] = useState({});
  const [workingHoursPerWeek, setWorkingHoursPerWeek] = useState(0);
  const [hourlyRate, setHourlyRate] = useState(0);

  const [inputToFocus, setInputToFocus] = useState(0);

  const formRef = useRef() as any;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const doSaveOffer = saveOffer as any;
  const doGetProjectById = getProjectById as any;

  const offersState = useSelector((state: any) => state.offers);
  const projectsState = useSelector((state: any) => state.projects);

  const { errors, isOfferSavedSuccess, isOfferSavedError } = offersState;
  const { projectById } = projectsState;

  const handleOnInputOnlyNumber = (e: any) => {
    e.preventDefault();
    e.target.value = e.target.value.replace(/[^\dA-Z]/g, "").trim();
  };

  const handleChangeHourlyRate = (e: any) => {
    e.preventDefault();
    setHourlyRate(e.target.value);
  };

  const handleChangeHourPerFeature = (e: any) => {
    setCalculatingState((preS) => ({
      ...preS,
      [e.target.name]: e.target.value,
    }));
  };
  const handleChangeWorkingHoursPerWeek = (e: any) => {
    setWorkingHoursPerWeek(e.target.value);
  };

  const countTotalTime: any = Object.values(calculatingState).reduce(
    (pre: any, curr: any) => parseInt(pre) + parseInt(curr),
    0
  );

  const countBid = countTotalTime * hourlyRate;
  const countHowLongProject = countTotalTime / workingHoursPerWeek;
  const checkedcountHowLongProject =
    countHowLongProject >= 0 && !(countHowLongProject === Infinity)
      ? Math.ceil(countHowLongProject)
      : 0;
  const handleSubmit = (e: any) => {
    e.preventDefault();
    const project_id = localStorage.getItem("projectById");
    const data = new FormData(formRef.current);
    const working_hours_per_week = parseInt(
      data.get("working_hours_per_week") as any
    );
    const total_working_hours = parseInt(
      data.get("total_working_hours") as any
    );
    const hourly_rate = parseInt(data.get("hourly_rate") as any);
    const cover_letter = data.get("cover_letter");

    if (
      Object.values(calculatingState).length !== projectById?.tasks?.length ||
      Object.keys(calculatingState).length !== projectById?.tasks?.length ||
      Object.values(calculatingState).includes("")
    ) {
      window.alert("You should provide estimations for each feature.");
    } else if (Object.values(calculatingState).includes("0")) {
      const confirmBox = window.confirm(
        "There is 0 hours of effort for some tasks, do you want to continue?"
      );
      if (confirmBox === true) {
        dispatch(
          doSaveOffer({
            project_id,
            working_hours_per_week,
            total_working_hours,
            hourly_rate,
            cover_letter,
          })
        );
      }
    } else {
      dispatch(
        doSaveOffer({
          project_id,
          working_hours_per_week,
          total_working_hours,
          hourly_rate,
          cover_letter,
        })
      );
    }
  };

  const handleEnter = (e: any, ind: any) => {
    if (e.key.toLowerCase() === "enter") {
      setInputToFocus(ind + 1);
    }
  };

  useEffect(() => {
    const el = document.getElementById(`hour-per-feature-${inputToFocus}`);
    el?.focus();
  }, [setInputToFocus, inputToFocus]);

  useEffect(() => {
    dispatch(doGetProjectById(localStorage.getItem("projectById")));
    return () => {
      dispatch(clearState());
    };
  }, []);

  useEffect(() => {
    isOfferSavedSuccess
      ? navigate(
          `/developers/${localStorage.getItem(
            "userId"
          )}/feed/${localStorage.getItem("projectById")}-/congratulations`
        )
      : null;
  }, [isOfferSavedSuccess, isOfferSavedError]);

  return (
    <>
      <div className="requirements-list-feed">
        <Typography type="p5" text={projectById?.technical_summary} />
        <table className="requirements-table">
          <thead>
            <tr>
              <td>
                <Typography type="p4" text="Feature" />
              </td>
              <td>
                <Typography type="p4" text="Task" />
              </td>
              <td>
                <Typography type="p4" text="Details" />
              </td>
              <td>
                <Typography type="p4" text="Effort / Hours" />
              </td>
            </tr>
          </thead>
          <tbody>
            {projectById?.tasks?.map((task: any, ind: any) => {
              return (
                <tr key={task.id}>
                  <td>
                    <Typography type="p5" text={task?.feature} />
                  </td>
                  <td className="tasks">
                    <Typography type="p5" text={task?.task} />
                  </td>
                  <td className="details">
                    <Typography type="p5" text={`${task?.sentence || ""}`} />
                  </td>
                  <td className="effort-hour">
                    <input
                      type="number"
                      onInput={handleOnInputOnlyNumber}
                      pattern="[0-9\s]{13,19}"
                      onChange={handleChangeHourPerFeature}
                      name={`hour-per-feature-${task?.id}`}
                      maxLength={5}
                      min={0}
                      id={`hour-per-feature-${ind}`}
                      onKeyDown={(e) => handleEnter(e, ind)}
                    />
                    <Typography type="p5" text="Hr" />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <form ref={formRef}>
        <section className="inputs section-1">
          <Input
            label="Working hours per week"
            placeholder="0"
            name="working_hours_per_week"
            onChange={handleChangeWorkingHoursPerWeek}
            onInput={handleOnInputOnlyNumber}
            value={`${workingHoursPerWeek || ""}`}
          />
          <span className={`error`}>
            <Typography
              type="p3"
              text={errors && errors.working_hours_per_week}
            />
          </span>
          <Input
            label={`Hourly Rate ($/hr)`}
            placeholder="0"
            value={`${hourlyRate || ""}`}
            name="hourly_rate"
            {...{ autoComplete: "off" }}
            onInput={handleOnInputOnlyNumber}
            onChange={handleChangeHourlyRate}
          />
          <span className={`error`}>
            <Typography type="p3" text={errors && errors.hourly_rate} />
          </span>
        </section>
        <section className="section-2">
          <div className="inputs">
            <Input
              label="Bid"
              placeholder="0"
              value={`${countBid}`}
              name="bid"
              iconRight="$"
              {...{ readOnly: true }}
            />

            <Input
              label="App cart Fee"
              placeholder="0"
              value=""
              {...{ readOnly: true }}
              iconRight="$"
            />
            <Input
              label="You will receive "
              placeholder="0"
              value={`${countBid}`}
              {...{ readOnly: true }}
              iconRight="$"
            />
          </div>
          <div className="inputs">
            <Input
              label="Total Time "
              placeholder=""
              name="total_working_hours"
              value={`${countTotalTime || ""} Hr`}
              {...{ readOnly: true }}
            />
            <span className={`error`}>
              <Typography
                type="p3"
                text={errors && errors.total_working_hours}
              />
            </span>
            <Input
              label="How Long this Project take"
              placeholder=""
              value={`${checkedcountHowLongProject} Weeks`}
            />
          </div>
        </section>
        <section className="section-3">
          <Typography type="p2" text="Cover Letter" weight="bold" />
          <textarea rows={14} cols={10} wrap="soft" name="cover_letter" />
          <span className={`error`}>
            <Typography type="p3" text={errors?.cover_letter} />
          </span>
        </section>
        <Button
          type="primary"
          label="Submit Offer"
          onClick={handleSubmit}
          size="2"
        />
      </form>
    </>
  );
};
