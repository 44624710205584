import { NavLink } from "react-router-dom";
import { Typography } from "../Typography/Typography";
import "./navigation.scss";
interface NavigationItemProps {
  label: string;
  to: string;
  id?: any;
  onClick?: any;
}
export const NavigationItem = ({
  label,
  to,
  id,
  onClick,
}: NavigationItemProps) => {
  return (
    <li className="navigation-item" key={id} onClick={onClick}>
      {/* <NavLink to={`${to}`} activeClassName="is-active"> */}
      <NavLink to={`${to}`} className={({ isActive }) => (isActive ? "is-active" : "")} >
        <Typography type="p3" text={label} />
      </NavLink>
    </li>
  );
};
