import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import TellUs from "./Step1";
import SelectFeatures from "./Step2";
import GiveDetails from "./Step3";
import SelectDesign from "./Step4";
import MoreInfo from "./Step5";
import ThankYou from "./Step6";
import { useAxios } from "services/useAxios";
import "./aboutapp.scss";
import { AiLoader } from "..";

export const NewProjectReq = () => {
  const { error, getPageText, isError, isSuccess, response } =
    useAxios();

  useEffect(() => {
    getPageText("create-project");
  }, []);

  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    description: "",
    design: "",
    info: {},
  });
  const isLoading = useSelector((state: any) => state.ai.loading)

  const handleChange = (event: any) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const next = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setCurrentStep(currentStep + 1);
  };
  const back = () => {
    setCurrentStep(currentStep - 1);
  };
  if(isLoading) {
    return <AiLoader />
  } else {
    switch (currentStep) {
      case 1:
        return (
          <TellUs
            data={formData}
            handleChange={handleChange}
            next={next}
            text={isSuccess && response.page.blocks[0]}
          />
        );
      case 2:
        return (
          <SelectFeatures
            data={formData}
            next={next}
            back={back}
            text={isSuccess && response.page.blocks[1]}
          />
        );
      case 3:
        return (
          <GiveDetails
            data={formData}
            next={next}
            back={back}
            text={isSuccess && response.page.blocks[2]}
          />
        );
      case 4:
        return (
          <SelectDesign
            data={formData}
            next={next}
            back={back}
            text={isSuccess && response.page.blocks[3]}
          />
        );
      case 5:
        return (
          <MoreInfo
            data={formData}
            next={next}
            back={back}
            text={isSuccess && response.page.blocks[4]}
          />
        );
      case 6:
        return (
          <ThankYou data={formData} text={isSuccess && response.page.blocks[5]} />
        );
      default:
        return <TellUs data={formData} back={back} />;
    }
  }
  
};
