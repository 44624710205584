import React, { useEffect } from "react";
// import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
import { Button } from "components";
import { useDispatch } from "react-redux";
import { socialRegister, socialLogin } from "state/actions";

const GOOGLE_CLIENT_ID =
  "1008390883674-g0guo7ev22hm9t2cbjur8eooiehk4425.apps.googleusercontent.com";

export const GoogleButton = ({ action }) => {
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   const initClient = () => {
  //     gapi.client.init({
  //       clientId: GOOGLE_CLIENT_ID,
  //       scope: "",
  //     });
  //   };
  //   gapi.load("client:auth2", initClient);
  // });

  // const onSuccess = (res) => {
  //   switch (action) {
  //     case "register":
  //       return dispatch(
  //         socialRegister({
  //           token: res.accessToken,
  //           type: "google",
  //           user_type: window.location.href.includes("ent-signup")
  //             ? "customer"
  //             : "developer",
  //         })
  //       );

  //     case "login":
  //       return dispatch(
  //         socialLogin({ token: res.accessToken, type: "google" })
  //       );
  //     default:
  //       break;
  //   }
  // };

  // const onFailure = (res) => {
  //   return "User cancelled login or did not fully authorize.";
  // };

  return (
    <div></div>
    // <GoogleLogin
    //   clientId={GOOGLE_CLIENT_ID}
    //   onSuccess={onSuccess}
    //   onFailure={onFailure}
    //   cookiePolicy={`single_host_origin`}
    //   render={(renderProps) => (
    //     <Button
    //       onClick={renderProps.onClick}
    //       type="initial"
    //       label="Google"
    //       leftIcon="google"
    //       size="3"
    //     />
    //   )}
    // />
  );
};
