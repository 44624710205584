import { Icon } from "../index";
import "./progressbar.scss";

interface ProgressProps {
  value: number;
}
export const ProgressBar = ({ value }: ProgressProps) => {
  return (
    <div className="progress-bar-container">
      <div id="progress-bar">
        <div id="inner-progress-bar" style={{ width: `${value}%` }}></div>
      </div>{" "}
      <Icon icon="cross" />
    </div>
  );
};
