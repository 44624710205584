import "./typography.scss";
interface TypograhyProps {
  text: string;
  type:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "xl"
    | "p1"
    | "p2"
    | "p3"
    | "p4"
    | "p5";
  weight?: "normal" | "bold" | "bolder";
}

export const Typography = ({
  type,
  text,
  weight = "normal",
}: TypograhyProps) => {
  return (
    <>
      {type === "h1" ? (
        <h1 className={`typography h1 ${weight}`}>{text}</h1>
      ) : null}
      {type === "h2" ? (
        <h2 className={`typography h2  ${weight}`}>{text}</h2>
      ) : null}
      {type === "h3" ? (
        <h3 className={`typography h3  ${weight}`}>{text}</h3>
      ) : null}
      {type === "h4" ? (
        <h4 className={`typography h4  ${weight}`}>{text}</h4>
      ) : null}
      {type === "h5" ? (
        <h5 className={`typography h5  ${weight}`}>{text}</h5>
      ) : null}
      {type === "xl" ? (
        <h5 className={`typography xl  ${weight}`}>{text}</h5>
      ) : null}
      {type === "p1" ||
      type === "p2" ||
      type === "p3" ||
      type === "p4" ||
      type === "p5" ? (
        <p className={`typography ${type}  ${weight}`}>{text}</p>
      ) : null}
    </>
  );
};
