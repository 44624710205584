import React from "react";
import { Route, Routes } from "react-router-dom";
import { Projects } from "./Projects/Projects";
import { ProjectToApply } from "./ProjectToApply/ProjectToApply";
import "./feed.scss";
import { Congratulations } from "./Congratulations/Congratulations";

export const Feed = () => {
  return (
    <div className="feed">
      {/* <Redirect
        from={`/developers/${localStorage.getItem("userId")}/feed`}
        to={`/developers/${localStorage.getItem("userId")}/feed/projects`}
      /> */}
      <Routes>
        <Route
          path={`${localStorage.getItem("userId")}/feed/projects`}
          element={<Projects />}
        />
        <Route
          path={`${localStorage.getItem("userId")}/feed/${localStorage.getItem(
            "projectById"
          )}`}
          element={<ProjectToApply />}
        />
        <Route
          path={`${localStorage.getItem("userId")}/feed/${localStorage.getItem(
            "projectById"
          )}-/congratulations`}
          element={<Congratulations />}
        />
      </Routes>
    </div>
  );
};
