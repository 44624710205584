import React from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Button, Icon } from "components";
import "./projectcard.scss";

interface ProjectCardProps {
  title: string;
  projectId?: any;
  description: string;
  isBlurred?: boolean;
  isApplied?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}
export const ProjectCard = ({
  title,
  projectId,
  description,
  isBlurred = false,
  isApplied = false,
  onClick,
}: ProjectCardProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    localStorage.getItem("token")
      ? navigate("/subscribe/info")
      : navigate("/ent-signup");
  };
  return (
    <div
      className={`project-card ${isBlurred ? "blurred" : ""}`}
      onClick={isBlurred ? handleClick : () => null}
      key={projectId}
    >
      <div className="hat"></div>
      <div className="text-group">
        <div className="top">
          <Typography type="p1" weight="bold" text={title} />
          {isApplied ? <Icon icon="ok" /> : null}
        </div>
        <Typography type="p3" text={description} />
      </div>
      <Button
        type="outlined"
        label="Details"
        onClick={isBlurred ? () => null : onClick}
        rightIcon="chevron-right"
      />
    </div>
  );
};
