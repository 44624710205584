import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import {
  Home,
  DevLogin,
  Projects,
  DevSignup,
  // Developer, // TODO add developer routes
  NewProjectReq,
  EntLogin,
  EntSignup,
  Entrepreneur,
  Pricing,
  Popups,
  Developer,
} from "./pages";
import { PrivateRoute } from "./helpers/PrivateRoute";
import { ProjectToApply } from "pages/Developer/pages/Feed/ProjectToApply/ProjectToApply";
import { Requirements } from "pages/Developer/pages/Feed/ProjectToApply/Tabs/Requirements/Requirements";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/dev-login" element={<DevLogin />} />
        <Route path="/dev-signup" element={<DevSignup />} />
        <Route path="/ent-login" element={<EntLogin />} />
        <Route path="/ent-signup" element={<EntSignup />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/plans" element={<Pricing />} />
        <Route path="/subscribe" element={<Popups />} />
        <Route path="/project-request" element={<NewProjectReq />} />
        <Route
          path="entrepreneur/*"
          element={
            <PrivateRoute>
              <Entrepreneur />
            </PrivateRoute>
          }
        />
        <Route
          path="developers/*"
          element={
            <PrivateRoute>
              <Developer />
              <Routes>
                <Route
                  path=":userId/feed/:projectId/projects/*"
                  element={<ProjectToApply />}
                />
                <Route
                  path=":userId/feed/:projectId/requirements"
                  element={<Requirements />}
                />
              </Routes>
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
