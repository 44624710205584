import { Spinner } from '../../components/index';
import './styles.scss'

const AiLoader = () => {
    return (
    <div className='spinner' >
        <h2 className='header'>Our AI is working on your description</h2>
        <div><Spinner /></div>
    </div>
  )
}

export default AiLoader