import { createSlice } from "@reduxjs/toolkit";
import {
  getUserInfo,
  subscribePlan,
  updateUserInfo,
  updateUserImage,
} from "../actions";

interface UserState {
  status: string;
  message: string;
  user: any[];
  errors: any[];
  subscriptionErrors: any[];
  subscriptionMessage: string;
  subscriptionStatus: boolean;
  subscriptionIsError: boolean;
  subscriptionIsSuccess: boolean;
  subscriptionIsFetching: boolean;
  isError: boolean;
  isSuccess: boolean;
  isFetching: boolean;
  imageIsError: boolean;
  imageIsSuccess: boolean;
  imageIsFetching: boolean;
}

const initialState: UserState = {
  status: "",
  message: "",
  user: [],
  errors: [],
  subscriptionErrors: [],
  subscriptionMessage: "",
  subscriptionStatus: false,
  subscriptionIsError: false,
  subscriptionIsSuccess: false,
  subscriptionIsFetching: false,
  isError: false,
  isSuccess: false,
  isFetching: false,
  imageIsError: false,
  imageIsSuccess: false,
  imageIsFetching: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.errors = [];
      state.subscriptionErrors = [];
      state.subscriptionMessage = "";
      state.subscriptionStatus = false;
      state.subscriptionIsError = false;
      state.subscriptionIsSuccess = false;
      state.subscriptionIsFetching = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserInfo.fulfilled, (state, { payload }) => {
        state.message = payload.message;
        state.status = payload.status;
        state.user = payload.user;
        state.isSuccess = true;
      })
      .addCase(getUserInfo.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getUserInfo.rejected, (state) => {
        state.isError = true;
      })
      .addCase(updateUserInfo.fulfilled, (state, { payload }) => {
        state.message = payload.message;
        state.status = payload.status;
        state.user = payload.user;
        state.isSuccess = true;
      })
      .addCase(updateUserInfo.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(updateUserInfo.rejected, (state, { payload }: any) => {
        state.isError = true;
        state.errors = payload.errors;
      })
      .addCase(subscribePlan.fulfilled, (state, { payload }) => {
        state.subscriptionMessage = payload.message;
        state.subscriptionStatus = payload.success;
        state.subscriptionIsError = false;
        state.subscriptionIsSuccess = true;
      })
      .addCase(subscribePlan.pending, (state) => {
        state.subscriptionIsFetching = false;
      })
      .addCase(subscribePlan.rejected, (state, { payload }: any) => {
        state.subscriptionIsError = true;
        state.subscriptionIsSuccess = false;
        state.subscriptionMessage = payload.message;
        state.subscriptionStatus = payload.success;
        state.subscriptionErrors = payload.errors;
      })
      .addCase(updateUserImage.fulfilled, (state, { payload }) => {
        state.message = payload.message;
        state.imageIsSuccess = true;
      })
      .addCase(updateUserImage.pending, (state) => {
        state.imageIsFetching = true;
      })
      .addCase(updateUserImage.rejected, (state) => {
        state.imageIsError = true;
      });
  },
});

export const { clearState } = userSlice.actions;

export default userSlice.reducer;

// import { createSlice } from "@reduxjs/toolkit";
// import {
//   getUserInfo,
//   subscribePlan,
//   updateUserInfo,
//   updateUserImage,
// } from "../actions";

// const initialState = {
//   status: "",
//   message: "",
//   user: [],
//   errors: [],
//   subscriptionErrors: [],
//   subscriptionMessage: "",
//   subscriptionStatus: false,
//   subscriptionIsError: false,
//   subscriptionIsSuccess: false,
//   subscriptionIsFetching: false,
//   isError: false,
//   isSuccess: false,
//   isFetching: false,
//   imageIsError: false,
//   imageIsSuccess: false,
//   imageIsFetching: false,
// };

// export const userSlice = createSlice({
//   name: "user",
//   initialState,
//   reducers: {
//     clearState: (state) => {
//       state.isError = false;
//       state.isSuccess = false;
//       state.isFetching = false;
//       state.errors = [];
//       state.subscriptionErrors = [];
//       state.subscriptionMessage = "";
//       state.subscriptionStatus = false;
//       state.subscriptionIsError = false;
//       state.subscriptionIsSuccess = false;
//       state.subscriptionIsFetching = false;
//       return state;
//     },
//   },
//   extraReducers: {
//     [getUserInfo.fulfilled]: (state, { payload }) => {
//       state.message = payload.message;
//       state.status = payload.status;
//       state.user = payload.user;
//       state.isSuccess = true;
//     },
//     [getUserInfo.pending]: (state, { payload }) => {
//       state.isFetching = true;
//     },
//     [getUserInfo.rejected]: (state, { payload }) => {
//       state.isError = true;
//     },
//     [updateUserInfo.fulfilled]: (state, { payload }) => {
//       state.message = payload.message;
//       state.status = payload.status;
//       state.user = payload.user;
//       state.isSuccess = true;
//     },
//     [updateUserInfo.pending]: (state, { payload }) => {
//       state.isFetching = true;
//     },
//     [updateUserInfo.rejected]: (state, { payload }) => {
//       state.isError = true;
//       state.errors = payload.errors;
//     },
//     [subscribePlan.fulfilled]: (state, { payload }) => {
//       state.subscriptionMessage = payload.message;
//       state.subscriptionStatus = payload.success;
//       state.subscriptionIsError = false;
//       state.subscriptionIsSuccess = true;
//     },
//     [subscribePlan.pending]: (state, { payload }) => {
//       state.subscriptionIsFetching = false;
//     },
//     [subscribePlan.rejected]: (state, { payload }) => {
//       state.subscriptionIsError = true;
//       state.subscriptionIsSuccess = false;
//       state.subscriptionMessage = payload.message;
//       state.subscriptionStatus = payload.success;
//       state.subscriptionErrors = payload.errors;
//     },
//     [updateUserImage.fulfilled]: (state, { payload }) => {
//       state.message = payload.message;
//       state.imageIsSuccess = true;
//     },
//     [updateUserImage.pending]: (state, { payload }) => {
//       state.imageIsFetching = true;
//     },
//     [updateUserImage.rejected]: (state, { payload }) => {
//       state.imageIsError = true;
//     },
//   },
// });

// export const { clearState } = userSlice.actions;
