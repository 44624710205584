import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, {AxiosResponse, AxiosRequestConfig} from "axios";
import API_PATHS from "services/apiPaths";
import STORAGE_KEYS from "services/storageKeys";

let headers = {
  Authorization: `Bearer ${localStorage.getItem("token")}`,
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const getUserInfo = createAsyncThunk(
  "user/getUserInfo",
  async (thunkAPI: any) => {
    try {
      const url = new URL("https://v2-services.app-cart.io/api/v2/user");
      const response: AxiosResponse = await axios.get(API_PATHS.user.get_user_info, {headers: headers});

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

interface UpdateUserInfoParams {
  name: string;
  email: string;
  phone: string;
}
export const updateUserInfo = createAsyncThunk(
  "user/updateUserInfo",
  async ({ name, email, phone }: UpdateUserInfoParams, thunkAPI) => {
    try {
      let body = {
        name: name,
        email: email,
        phone: phone,
      };

      const response: AxiosResponse = await axios.post(API_PATHS.user.get_user_info, body, { headers: headers });
      localStorage.setItem("userName", response.data.user.name);
      localStorage.setItem("user", JSON.stringify(response.data.user));
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

interface UpdateUserImageParams {
  image: FormData; 
}

export const updateUserImage = createAsyncThunk<
  any, 
  UpdateUserImageParams
>(
  "user/updateUserImage",
  async ({ image }, thunkAPI: any) => {
    try {
      const response: AxiosResponse = await axios.get(API_PATHS.user.post_upload_profile_image, {data: image, headers: headers});
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

interface CardData {
  name: string;
  card: string;
  expire_month: string;
  expire_year: string;
  cvc: string;
}

export const subscribePlan = createAsyncThunk(
  "user/subscribePlan",
  async (
    { planID, cardData }: { planID: string; cardData: CardData },
    thunkAPI: any
  ) => {
    try {
      const body = {
        name: cardData.name,
        card: cardData.card,
        expire_month: cardData.expire_month,
        expire_year: cardData.expire_year,
        cvc: cardData.cvc,
      };

      const response: AxiosResponse = await axios.get(API_PATHS.user.subscribe(planID), { data: body, headers: headers });

      if (response.status === 200) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

