import React, { useEffect, useState } from "react";
import { Button, Typography } from "..";
import "./detailsitem.scss";
import { useSelector } from "react-redux";

export const DetailsItem = (props: any) => {
  const allFeatures = useSelector(
    (state: any) => state.features.features || {}
  );
  const [collapse, setCollapse] = useState(false);
  
  const handleCollapse = (event: any) => {
    event.preventDefault();
    setCollapse((preS) => !preS);
  };

  const { data, selectedFeatures, setSelectedFeatures } = props;
  const item = { ...data };

  const changeDescription = (newDescription: String, featureId: Number) => {
    const updatedItems = selectedFeatures.map((feature: any) => {
      if (feature.id !== featureId) {
        return { ...feature };
      } else {
        return { ...feature, description: newDescription };
      }
    });
    setSelectedFeatures([...updatedItems]);
  };

  const handleChange = (event: any) => {
    event.preventDefault();
    const value = event.target.value;
    item.description = value;
    changeDescription(item.description, data.id);
  };

  const changeSubFeatures = (e: any, featureId: Number) => {
    const subFeature = allFeatures
      .find((feature: any) => feature.id == data.id)
      .subfeatures.find((sub: any) => sub.id === parseInt(e.target.id));
    if (e.target.checked) {
      selectedFeatures.map((feature: any) => {
        if (feature.id === featureId) {
          feature.subfeatures.push(subFeature);
        }
      });
    }

    if (!e.target.checked) {
      const updatedItems = selectedFeatures.map((feature: any) => {
        if (feature.id !== featureId) {
          return { ...feature };
        } else {
          const result = feature.subfeatures.filter(
            (sub: any) => sub.id !== parseInt(e.target.id)
          );

          return { ...feature, subfeatures: result };
        }
      });
      setSelectedFeatures([...updatedItems]);
    }
  };

  // useEffect(() => {
  //   setTimeout(function () {
  //     setCollapse((preS) => !preS);
  //   }, 3000);
  // }, []);

  useEffect(() => {
    const timeoutId = setTimeout(function () {
      setCollapse((preS) => !preS);
    }, 1000);
  
    return () => clearTimeout(timeoutId); 
  }, []);

  useEffect(() => {
    setSelectedFeatures([...selectedFeatures]);
  }, []);

  return (
    <div
      className={`details-item ${collapse ? "collapse-on" : "collapse-off"}`}
    >
      <div className="on-off-switch">
        <Button 
          type="secondary" 
          label={data.name} 
          onClick={handleCollapse} 
        />
      </div>
      <div className="collapse-section">
        <div className="vertical-line"></div>
        <div className="details-text">
          <Typography text="Details (Optional)" type="p4" />​
          {allFeatures.find((feature: any) => feature.id == data.id)
            ? allFeatures
                .find((feature: any) => feature.id == data.id)
                .subfeatures.map((sub: any, index: number) => (
                  <div className="inputs" key={index}>
                    <input
                      type="checkbox"
                      id={sub.id}
                      name={sub.name}
                      value={sub.name}
                      onChange={(e) => changeSubFeatures(e, data.id)}
                      defaultChecked={
                        selectedFeatures
                          .find((feature: any) => feature.id == data.id)
                          .subfeatures.find(
                            (item: any) => item.id === sub.id
                          ) && "checked"
                      }
                    />
                    <label htmlFor={sub.name}>{sub.name}</label>
                  </div>
                ))
            : ""}
          <textarea
            onChange={(e) => handleChange(e)}
            placeholder="Please add your details here"
            id={data.id}
            value={data.description}
          ></textarea>
        </div>
      </div>
    </div>
  );
};
