import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo, updateUserInfo, updateUserImage } from "state/actions";
import { clearState } from "state/reducers/user";

import { Button, Icon, Input, Typography } from "components";
import "./settings.scss";
import COUNTRY_CODES from "./CountryCodes.json";

export const Settings = () => {
  const [getCountryCode, setGetCountryCode] = React.useState({
    code: "",
    toggle: false,
  });
  const [userData, setUserData] = React.useState({
    tel_country_code: "",
    tel_phone: "",
    name: "",
    email: "",
  });
  const dispatch = useDispatch();
  const doGetUserInfo = getUserInfo as any;
  const doUpdateUserInfo = updateUserInfo as any;
  const doUpdateUserImage = updateUserImage as any;
  const userState = useSelector((state: any) => state.user);
  const { isError, isSuccess, user, errors, imageIsSuccess, imageIsError } =
    userState;

  const inputRef = React.useRef() as any;

  const handleUploadImage = (e: any) => {
    const file = e.target.files[0];
    const img = new FormData();
    img.append("image", file);
    dispatch(doUpdateUserImage({ image: img }));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const data = new FormData(inputRef.current);
    const name = data.get("name");
    const email = data.get("email");
    const tel_country_code = data.get("tel_country_code");
    const tel_phone = data.get("tel_phone");
    const phone =
      tel_country_code && tel_phone
        ? `${tel_country_code}${" " + tel_phone}`
        : 0;
    dispatch(doUpdateUserInfo({ name, email, phone }));
  };

  const handleTelephone = (e: any) => {
    e.preventDefault();
    e.target.value = e.target.value.replace(/[^\dA-Z]/g, "").trim();
  };

  const handleClickCountryCode = (e: any, countryCode: any) => {
    e.preventDefault();
    setGetCountryCode((prevS) => ({
      ...prevS,
      code: countryCode,
      toggle: !prevS.toggle,
    }));
  };

  const handleChange = (e: any) => {
    setUserData((prevS) => ({
      ...prevS,
      [e.target.name]: e.target.value,
    }));
  };

  React.useEffect(() => {
    if (isSuccess) {
      dispatch(clearState());
    }
  }, [isSuccess]);

  React.useEffect(() => {
    dispatch(doGetUserInfo());
  }, [dispatch]);

  React.useEffect(() => {
    if (user) {
      setGetCountryCode((prevS) => ({
        ...prevS,
        code: user.phone ? user.phone.split(" ")[0] : "",
      }));
      setUserData((prevS) => ({
        ...prevS,
        ...user,
        tel_country_code: user.phone ? user.phone.split(" ")[0] : "",
        tel_phone: user.phone ? user.phone.split(" ")[1] : "",
      }));
    }
  }, [user, isSuccess, isError, imageIsSuccess, imageIsError]);

  return (
    <div className="settings-page">
      <Typography type="h3" text="Settings" weight="bold" />
      <div className="settings-dev">
        <div className="settings-box">
          <Typography type="p1" text="Account" weight="bold" />
          <div className="avatar">
            {user?.image ? (
              <img width="106" height="106" src={user.image} />
            ) : (
              <div className="no-person">
                <Icon icon="person" />
              </div>
            )}

            <label>
              <span>+</span>
              <input onChange={handleUploadImage} type="file" name="image" />
            </label>
          </div>
          <div className="contacts">
            <Typography type="p3" text={user?.name || ""} />
            <Typography type="p3" text={user?.email || ""} />
            <Typography
              type="p3"
              text={user && user.phone !== "0" ? user.phone : " "}
            />
          </div>
        </div>

        <div className="settings-box">
          <Typography type="p1" text="Contact Information" weight="bold" />
          <div className="contact-inputs">
            <form ref={inputRef}>
              <Input
                label="Contact Name"
                placeholder="Your name"
                value={userData.name || ""}
                type="text"
                name="name"
                onChange={handleChange}
              />
              <span className={`error`}>
                <Typography type="p4" text={errors?.name} />
              </span>
              <Input
                label="Contact Mail"
                placeholder="Your email"
                value={userData.email || ""}
                type="email"
                name="email"
                onChange={handleChange}
              />
              <span className={`error`}>
                <Typography type="p4" text={errors?.email} />
              </span>
              <Typography type="p4" text="Contact Phone" />
              <div className="telephone">
                <label className="generic-label">
                  <input
                    value={getCountryCode.code || ""}
                    name="tel_country_code"
                    className="generic-input"
                    placeholder="+1"
                    onClick={(e) =>
                      handleClickCountryCode(e, getCountryCode.code)
                    }
                    readOnly
                  />
                  <div
                    className="country-dropdown"
                    style={{
                      display: getCountryCode.toggle ? "block" : "none",
                    }}
                  >
                    {[
                      {
                        code: "__",
                        dial_code: "",
                        name: (
                          <b>
                            <u>COUNTRIES</u>
                          </b>
                        ),
                      },
                      ...COUNTRY_CODES,
                    ].map((country: any) => {
                      return (
                        <span
                          className="country-item"
                          key={country.code}
                          onClick={(e) =>
                            handleClickCountryCode(e, country.dial_code)
                          }
                        >
                          <i>{country.dial_code}</i>
                          <Typography type="p4" text={country.name} />
                        </span>
                      );
                    })}
                  </div>
                </label>
                <label className="generic-label">
                  <input
                    onChange={handleChange}
                    onInput={handleTelephone}
                    pattern="[0-9\s]{13,19}"
                    className="generic-input"
                    placeholder="Your phone"
                    type="tel"
                    name="tel_phone"
                    maxLength={10}
                    value={userData.tel_phone || ""}
                  />
                </label>
              </div>
              <Button type="primary" label="Save" onClick={handleSubmit} />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
