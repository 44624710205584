import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import API_PATHS from "services/apiPaths";
import STORAGE_KEYS from "services/storageKeys";

export const sendAIQuery = createAsyncThunk(
    "ai/sendAIQuery",
    async (data: string, thunkAPI) => {
      try {
        const AIData = (await axios.post(API_PATHS.ai.sendQuery, {project: data})).data;
        return AIData;
      } catch (error) {
        console.error(error);
        //TODO: regulate error messages
        return thunkAPI.rejectWithValue(error);
      }
    }
  );
