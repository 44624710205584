import { NavLink } from "react-router-dom";
import { Button } from "../Button/Button";
import { Icon } from "../Icon/Icon";
import { Typography } from "../Typography/Typography";
import "./account.scss";

interface AccountDropdownItemProps {
  icon: string;
  text: string;
  href: string;
  onClick?: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
}
export const AccountDropdownItem = ({
  icon,
  text,
  href,
  onClick,
}: AccountDropdownItemProps) => {
  return (
    <li onClick={onClick ? onClick : () => null}>
      {text !== "Log out" ? (
        // <NavLink to={href} activeClassName="is-active" exact>
        <NavLink to={href} className={({ isActive }) => (isActive ? "is-active" : "")} >
          <Icon icon={icon} />
          <Typography type="p3" text={text} />
        </NavLink>
      ) : (
        <div>
          <Icon icon={icon} />
          <Typography type="p3" text={text} />
        </div>
      )}
    </li>
  );
};
