export const API_PATHS = {
    user: {
        get_user_info: 'user',
        post_update_user_info: 'user',
        post_upload_profile_image: 'user/image',
        get_contracts: 'user/contracts',
        my_projects: 'user/my-projects',
        subscribe: (planID?: string) => `user/subscribe/${planID}`
    },
    auth: {
        post_social_login: 'socialLogin',
        post_social_register: 'socialRegister',
        register: 'register',
        login: 'login',
        logout: 'logout'
    },
    project: {
        get_project_list: 'projects',
        get_project: (id?: string) => `projects/${id}`,
        get_project_tasks: (id?: string) => `projects/${id}/tasks`,
        load_more_projects: (id?: string) => `projects/?start=${id}`
    },
    question: {
        get_questions: (id?: string) => 'questions/' + id,
        post_create: (featureID: string) => `questions/create/${featureID}`,
        post_reply: (questionID: string) => `questions/${questionID}/reply`
    },
    offers: {
        get_offers: 'offers',
        get_view_offer: (id?: string) => 'offers/' + id,
        post_accept_offer: (id: string) => `offers/${id}/accept`,
        project_offers: (id: string) => `projectOffers/${id}`
    },
    app_design: {
        list_designs: 'app-designs',
    },
    feature: {
        main_features: 'main-features'
    },
    plans: {
        getPlans: 'plans'
    },
    ai: {
        sendQuery: 'generate-feature-ai'
    }
}

export default API_PATHS
