import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getQuestionsByFeatureId,
  createQuestionByFeatureId,
  getQuestions,
} from "../actions";

interface QuestionsState {
  status: string;
  message: string;
  questions: any[];
  askedQuestion: any;
  questionsPerFeature: any[];
  isSuccess: boolean;
  isFetching: boolean;
  isError: boolean;
  isQuestionCreatedSuccess: boolean;
  isQuestionCreatedFetching: boolean;
  isQuestionCreatedError: boolean;
}

const initialState: QuestionsState = {
  status: "",
  message: "",
  questions: [],
  askedQuestion: {},
  questionsPerFeature: [],
  isSuccess: false,
  isFetching: false,
  isError: false,
  isQuestionCreatedSuccess: false,
  isQuestionCreatedFetching: false,
  isQuestionCreatedError: false,
};

export const questionsSlice = createSlice({
  name: "questions",
  initialState,
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.isQuestionCreatedError = false;
      state.isQuestionCreatedSuccess = false;
      state.isQuestionCreatedFetching = false;
      state.questionsPerFeature = [];
      state.questions = [];
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getQuestionsByFeatureId.fulfilled, (state, { payload }: any) => {
        state.message = payload.message;
        state.status = payload.status;
        state.questionsPerFeature = payload.data.questions;
        state.isSuccess = true;
        state.isFetching = false;
        state.isError = false;
      })
      .addCase(getQuestionsByFeatureId.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getQuestionsByFeatureId.rejected, (state) => {
        state.isError = true;
      })
      .addCase(createQuestionByFeatureId.fulfilled, (state, { payload }) => {
        state.message = payload.message;
        state.status = payload.status;
        state.askedQuestion = payload.question;
        state.isQuestionCreatedSuccess = true;
        state.isQuestionCreatedFetching = false;
        state.isQuestionCreatedError = false;
      })
      .addCase(createQuestionByFeatureId.pending, (state) => {
        state.isQuestionCreatedFetching = true;
      })
      .addCase(createQuestionByFeatureId.rejected, (state) => {
        state.isQuestionCreatedSuccess = false;
        state.isQuestionCreatedError = true;
      })
      .addCase(getQuestions.fulfilled, (state, { payload }) => {
        state.message = payload.message;
        state.status = payload.status;
        state.questions = payload.questions;
      });
  },
});

export const { clearState } = questionsSlice.actions;

export default questionsSlice.reducer;


// import { createSlice } from "@reduxjs/toolkit";
// import {
//   getQuestionsByFeatureId,
//   createQuestionByFeatureId,
//   getQuestions,
// } from "../actions";

// const initialState = {
//   status: "",
//   message: "",
//   questions: [],
//   askedQuestion: {},
//   questionsPerFeature: [],
//   isSuccess: false,
//   isFetching: false,
//   isError: false,
//   isQuestionCreatedSuccess: false,
//   isQuestionCreatedFetching: false,
//   isQuestionCreatedError: false,
// };

// export const questionsSlice = createSlice({
//   name: "questions",
//   initialState,
//   reducers: {
//     clearState: (state) => {
//       state.isError = false;
//       state.isSuccess = false;
//       state.isFetching = false;
//       state.isQuestionCreatedError = false;
//       state.isQuestionCreatedSuccess = false;
//       state.isQuestionCreatedFetching = false;
//       state.questionsPerFeature = [];
//       state.questions = [];
//       state.message = "";
//       return state;
//     },
//   },
//   extraReducers: {
//     [getQuestionsByFeatureId.fulfilled]: (state, { payload }) => {
//       // console.log('ffQuestions',payload)
//       state.message = payload.message;
//       state.status = payload.status;
//       state.questionsPerFeature = payload.data.questions;
//       state.isSuccess = true;
//       state.isFetching = false;
//       state.isError = false;
//     },
//     [getQuestionsByFeatureId.pending]: (state, { payload }) => {
//       state.isFetching = true;
//     },
//     [getQuestionsByFeatureId.rejected]: (state, { payload }) => {
//       // console.log('rejQues',payload)
//       state.isError = true;
//     },
//     [createQuestionByFeatureId.fulfilled]: (state, { payload }) => {
//       // console.log('QuesReducer',payload)
//       state.message = payload.message;
//       state.status = payload.status;
//       state.askedQuestion = payload.question;
//       state.isQuestionCreatedSuccess = true;
//       state.isQuestionCreatedFetching = false;
//       state.isQuestionCreatedError = false;
//     },
//     [createQuestionByFeatureId.pending]: (state, { payload }) => {
//       state.isQuestionCreatedFetching = true;
//     },
//     [createQuestionByFeatureId.rejected]: (state, { payload }) => {
//       // console.log('rejQues',payload)
//       state.isQuestionCreatedSuccess = false;
//       state.isQuestionCreatedError = true;
//     },
//     [getQuestions.fulfilled]: (state, { payload }) => {
//       // console.log('QuesReducer',payload)
//       state.message = payload.message;
//       state.status = payload.status;
//       state.questions = payload.questions;
//     },
//   },
// });

// export const { clearState } = questionsSlice.actions;
