import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import API_PATHS from "services/apiPaths";
import STORAGE_KEYS from "services/storageKeys";

let headers = {
  Authorization: `Bearer ${localStorage.getItem("token")}`,
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const getProjects = createAsyncThunk(
  "projects/getProjects",
  async (data, thunkAPI) => {
    try {
      const response = await axios.get(API_PATHS.project.get_project_list, {headers: headers});

      if (response.status === 200) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getProjectById = createAsyncThunk(
  "projects/getProjectById",
  async (id: string, thunkApi) => {
    try {
      const response = await axios.get(API_PATHS.project.get_project(id), {headers: headers});
    
      return response.data;      
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const getProjectTasksById = createAsyncThunk(
  "projects/getProjectTasksById",
  async (id: string, thunkApi) => {
    try {
      const response = await axios.get(API_PATHS.project.get_project_tasks(id), {headers: headers});

      return response.data;
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
//TODO There is no endpoint ending with start!!!
export const loadMoreProjects = createAsyncThunk(
  "projects/loadMoreProjects",
  async (id: string | undefined, thunkApi) => {
    try {
      const response = await axios.get(API_PATHS.project.load_more_projects(id), {headers: headers});
      
      return response.data;
      
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
