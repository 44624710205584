import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import API_PATHS from "services/apiPaths";
import STORAGE_KEYS from "services/storageKeys";

let headers = {
  Authorization: `Bearer ${localStorage.getItem("token")}`,
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const getOffers = createAsyncThunk(
  "offers/getOffers",
  async (_, thunkAPI) => {
    try {
      const response = await axios.post(
        API_PATHS.offers.get_offers,
        {}, // Pass an empty object for the data
        { headers: headers }
      );
  
      return response.data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

interface saveOfferDataTypes {
  project_id: string;
  working_hours_per_week: string | number;
  total_working_hours: string | number;
  hourly_rate: string | number;
  cover_letter: any;
};

export const saveOffer = createAsyncThunk(
  "offers/saveOffer",
  async (data: saveOfferDataTypes, thunkAPI) => {
    try {
      const body = {
        project_id: data.project_id,
        working_hours_per_week: data.working_hours_per_week,
        total_working_hours: data.total_working_hours,
        hourly_rate: data.hourly_rate,
        cover_letter: data.cover_letter,
      };

      const response = await axios.post(
        API_PATHS.offers.get_offers,
        body, // The body of the request
        { headers: headers }
      );
      return response.data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
