import React from "react";
import { Typography } from "..";
import { Icon } from "../Icon/Icon";
import "./input.scss";

interface InputProps {
  label?: string;
  placeholder: string;
  value?: string;
  type?: string;
  name?: string;
  iconRight?: string;
  onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
  onInput?: (event: React.FormEvent<HTMLInputElement>) => void;
}
export const Input = ({
  label,
  placeholder,
  value,
  type = "text",
  name,
  iconRight,
  onChange,
  onInput,
  ...rest
}: InputProps) => {
  const inputRef = React.useRef() as any;
  const [icon, setIcon] = React.useState("eye-crossed");

  const handleClickShowPassword = (event: any) => {
    event.preventDefault();
    const input = inputRef.current;
    input["type"] === "text"
      ? ((input["type"] = "password"), setIcon("eye-crossed"))
      : ((input["type"] = "text"), setIcon("eye"));
  };

  return (
    <label
      className={`generic-label ${type === "password" ? "password" : ""} ${
        iconRight ? "icon-right-container" : ""
      }`}
    >
      {label ? <Typography type="p4" text={label} /> : null}

      <input
        onChange={onChange}
        onInput={onInput}
        value={value}
        type={type}
        className={`generic-input`}
        placeholder={placeholder}
        name={name}
        ref={inputRef}
        {...rest}
      />
      {iconRight ? <span className="right-icon">{iconRight}</span> : null}
      {type === "password" ? (
        <span onClick={handleClickShowPassword} className="password-eye">
          <Icon icon={icon} />
        </span>
      ) : null}
    </label>
  );
};
