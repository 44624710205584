import { createSlice } from "@reduxjs/toolkit";
import { getUserProjects, getProjectOffers, getUser } from "../actions";

interface EntState {
  projects: any[] | null;
  message: string;
  offers: any[] | null;
  errors: string[];
  isFetching: boolean;
  isSuccess: boolean;
  isError: boolean;
}

const initialState: EntState = {
  projects: null,
  message: "",
  offers: null,
  errors: [],
  isFetching: false,
  isSuccess: false,
  isError: false,
};

export const entSlice = createSlice({
  name: "ent",
  initialState,
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserProjects.fulfilled, (state, action) => {
        const { projects, message } = action.payload;
        state.projects = projects;
        state.message = message;
        state.isFetching = false;
        state.isSuccess = true;
      })
      .addCase(getUserProjects.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getUserProjects.rejected, (state, action) => {
        const { message } = action.payload as { message: string};
        state.errors = [message];
        state.isFetching = false;
        state.isError = true;
      })
      .addCase(getProjectOffers.fulfilled, (state, action) => {
        const { offers, message } = action.payload.data;
        state.offers = offers;
        state.message = message;
        state.isFetching = false;
        state.isSuccess = true;
      })
      .addCase(getProjectOffers.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getProjectOffers.rejected, (state, action: any) => {
        const { message } = action.payload.data;
        state.errors = message;
        state.isFetching = false;
        state.isError = true;
      })
      .addCase(getUser.fulfilled, (state) => {
        state.isFetching = false;
        state.isSuccess = true;
      })
      .addCase(getUser.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getUser.rejected, (state, action: any) => {
        const { message } = action.payload.data;
        state.errors = message;
        state.isFetching = false;
        state.isError = true;
      });
  },
});

export const { clearState } = entSlice.actions;

export default entSlice.reducer;


// import { createSlice } from "@reduxjs/toolkit";
// import { getUserProjects, getProjectOffers, getUser } from "../actions";

// const initialState = {
//   projects: null,
//   message: "",
//   offers: null,
//   isFetching: false,
//   isSuccess: false,
//   isError: false,
// };

// export const entSlice = createSlice({
//   name: "ent",
//   initialState,
//   reducers: {
//     clearState: (state) => {
//       state.isError = false;
//       state.isSuccess = false;
//       state.isFetching = false;
//       return state;
//     },
//   },
//   extraReducers: {
//     [getUserProjects.fulfilled]: (state, { payload }) => {
//       state.projects = payload.projects;
//       state.message = payload.message;
//       state.isFetching = false;
//       state.isSuccess = true;
//     },
//     [getUserProjects.pending]: (state) => {
//       state.isFetching = true;
//     },
//     [getUserProjects.rejected]: (state, { payload }) => {
//       state.errors = payload.message;
//       state.isFetching = false;
//       state.isError = true;
//     },
//     [getProjectOffers.fulfilled]: (state, { payload }) => {
//       state.offers = payload.data.offers;
//       state.message = payload.data.message;
//       state.isFetching = false;
//       state.isSuccess = true;
//     },
//     [getProjectOffers.pending]: (state) => {
//       state.isFetching = true;
//     },
//     [getProjectOffers.rejected]: (state, { payload }) => {
//       state.errors = payload.data.message;
//       state.isFetching = false;
//       state.isError = true;
//     },

//     [getUser.fulfilled]: (state, { payload }) => {
//       state.isFetching = false;
//       state.isSuccess = true;
//     },
//     [getUser.pending]: (state) => {
//       state.isFetching = true;
//     },
//     [getUser.rejected]: (state, { payload }) => {
//       state.errors = payload.data.message;
//       state.isFetching = false;
//       state.isError = true;
//     },
//   },
// });

// export const { clearState } = entSlice.actions;
