import React, { useEffect } from "react";
import { Routes, Route } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Projects } from "./Projects/Projects";
import { AppliedProject } from "./AppliedProject/AppliedProject";
import { getOffers } from "state/actions";
import { Congratulations } from "./Congratulations/Congratulations";
import "./offers.scss";

export const Offers = () => {
  const dispatch = useDispatch();
  const doOffers = getOffers as any;
  const offerData = JSON.parse(`${localStorage.getItem("offer")}`) && 11;
  const offersState = useSelector((state: any) => state.offers);
  const { isSuccess, offers } = offersState;

  useEffect(() => {
    dispatch(doOffers());
  }, []);
  return (
    <Routes>
      <Route
        path={`${localStorage.getItem("userId")}/offers`}
        element={<Projects />}
      />
      {offers.map((offer: any) => (
        <Routes>
          <Route
            path={`${localStorage.getItem("userId")}/offers/${offer.id}`}
            element={<AppliedProject />}
          />
          <Route
            path={`${localStorage.getItem("userId")}/offers/${
              offer.id
            }-/congratulations`}
            element={<Congratulations />}
          />
        </Routes>
      ))}
    </Routes>
  );
};
