import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import API_PATHS from "services/apiPaths";
import STORAGE_KEYS from "services/storageKeys";

let headers = {
  Authorization: `Bearer ${localStorage.getItem("token")}`,
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const getPlans = createAsyncThunk("plans/getPlans", async (_, thunkAPI) => {
  try {
    const response = await axios.get(API_PATHS.plans.getPlans, {headers: headers});

    if (response.status === 200) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});
